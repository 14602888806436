import React, { Fragment } from "react";
import { Button } from "react-bootstrap";

import "../../../../../public/custom.css";
import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

export function SubmitNewUtensilParty(
    values,
    validationSchema,
    userAuthToken,
    setSubmitting,
    setSubmitAlertVariant,
    setSubmitAlertMessage,
    resetForm,
    setFormResetKey
) {
    console.log("starting submitting new utn party : ", values);

    // When button submits form and form is in the process of submitting, submit button is disabled
    setSubmitting(true);

    try {
        var castValues = validationSchema.cast(values);

        var submittedValues = {
            name: castValues["name"],
            place: castValues["place"],
            state: castValues["state"],
            district: castValues["district"],
            through: castValues["through"],
            contact_person: castValues["contact_person"],
            contact_number: castValues["contact_number"],
        };

        api.Post(
            `/api/v1/gsl/utensil/orders/party/`,
            submittedValues,
            userAuthToken,
            () => {
                setSubmitAlertVariant("success");
                setSubmitAlertMessage(
                    <Fragment>
                        <strong>Successfully Added New Party.</strong>
                        <br></br>
                        <br></br>
                        <Button variant="warning" href="/gsl/utensil/orders/order/new">
                            Add New Order
                        </Button>
                    </Fragment>
                );
                resetForm();
                setFormResetKey(Date.now());
                setSubmitting(false);
            },
            (error) => {
                console.log(`Error in adding new party : `, error);
                setSubmitAlertVariant("danger");
                setSubmitAlertMessage(`Error in adding new party : ${utils.GetErrorMessage(error)}`);
                setSubmitting(false);
            }
        );
    } catch (error) {
        console.log(`Error in processing new party : `, error);
        setSubmitAlertVariant("danger");
        setSubmitAlertMessage(`Error in  processing new party : ${utils.GetErrorMessage(error)}`);
        setSubmitting(false);
    }
}
