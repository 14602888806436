const rules = {
    admin: {
        static: [
            //sizes:navbar:view , lorry:navbar:view - used for dashboard navbar
            "bsil:patta:gsl-patta-sizes:sizes:navbar:view",
            "bsil:patta:gsl-patta-sizes:lorry:navbar:view",

            "bsil:patta:gsl-patta-sizes:sizes:pendingSizes:increment",
            "bsil:patta:gsl-patta-sizes:sizes:status:view",
            "bsil:patta:gsl-patta-sizes:sizes:statusTotal:view",
            "bsil:patta:gsl-patta-sizes:sizes:cutSizes:new",
            "bsil:patta:gsl-patta-sizes:sizes:cutSizes:view",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:new",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:view",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:new",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:view",
        ],
    },
    director: {
        static: [
            "bsil:patta:gsl-patta-sizes:sizes:navbar:view",
            "bsil:patta:gsl-patta-sizes:lorry:navbar:view",
            "bsil:patta:gsl-patta-sizes:sizes:status:view",
            "bsil:patta:gsl-patta-sizes:sizes:statusTotal:view",
            "bsil:patta:gsl-patta-sizes:sizes:cutSizes:view",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:view",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:view",
        ],
    },
    bsil_viewer: {
        static: [
            "bsil:patta:gsl-patta-sizes:sizes:navbar:view",
            "bsil:patta:gsl-patta-sizes:lorry:navbar:view",
            "bsil:patta:gsl-patta-sizes:sizes:status:view",
            "bsil:patta:gsl-patta-sizes:sizes:statusTotal:view",
            "bsil:patta:gsl-patta-sizes:sizes:cutSizes:view",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:view",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:view",
        ],
    },
    bsil_staff: {
        static: [
            "bsil:patta:gsl-patta-sizes:sizes:navbar:view",
            "bsil:patta:gsl-patta-sizes:lorry:navbar:view",

            "bsil:patta:gsl-patta-sizes:sizes:status:view",
            "bsil:patta:gsl-patta-sizes:sizes:statusTotal:view",
            "bsil:patta:gsl-patta-sizes:sizes:cutSizes:new",
            "bsil:patta:gsl-patta-sizes:sizes:cutSizes:view",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:new",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:view",

            "bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:view",
        ],
    },
    gsl_staff: {
        static: [
            "bsil:patta:gsl-patta-sizes:sizes:navbar:view",
            "bsil:patta:gsl-patta-sizes:lorry:navbar:view",

            "bsil:patta:gsl-patta-sizes:sizes:status:view",
            "bsil:patta:gsl-patta-sizes:sizes:statusTotal:view",
            "bsil:patta:gsl-patta-sizes:sizes:cutSizes:view",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:view",
            "bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:view",
        ],
    },
};

export default rules;
