import qs from "qs";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Table, Alert, Button } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import Can from "../../../../../components/can";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";

export default function ViewPattaPendingOrders(props) {
    //this key is to reset SelectDropdown on form submit
    const [isLoading, setIsLoading] = useState(true);
    const [hasLoadingError, setHasLoadingError] = useState(false);
    const [pageLoadErrorMessage, setPageLoadErrorMessage] = useState("");
    const [pendingOrdersList, setPendingOrdersList] = useState([]);
    const [nextListLink, setNextListLink] = useState("");
    const [totalListCount, setTotalListCount] = useState(0);
    const [categoryPendingTotal, setCategoryPendingTotal] = useState({
        mota: 0,
        patla: 0,
        kabja: 0,
        gsl_patta_current_month_dispatched_sizes_total: 0,
        gsl_patta_sizes_in_mill_qty: 0,
    });
    var category = qs.parse(props.location.search, { ignoreQueryPrefix: true }).category;
    if (category == null || category == undefined || category === "") {
        category = "all";
    }
    useEffect(() => {
        fetchPendingOrdersList(`/api/v1/bsil/patta/orders/pending/${category}`);
        fetchPendingOrdersCategoryTotal();
    }, []);

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    const fetchPendingOrdersCategoryTotal = () => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        return api.Get(
            `/api/v1/bsil/patta/orders/categoryTotal/pending`,
            {},
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                setCategoryPendingTotal({
                    mota: utils.ConvertKgToMt(res.data["mota"]),
                    patla: utils.ConvertKgToMt(res.data["patla"]),
                    kabja: utils.ConvertKgToMt(res.data["kabja"]),
                    gsl_patta_sizes_in_mill_qty: utils.ConvertKgToMt(res.data["gsl_patta_sizes_in_mill_qty"]),
                });
            },
            (error) => {
                console.log("Error in fetching Total Category List", error);
                setPageLoadErrorMessage(utils.GetErrorMessage(error));
                setIsLoading(false);
                setHasLoadingError(true);
            }
        );
    };

    const fetchPendingOrdersList = (URL) => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        return api.Get(
            URL,
            {},
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                let orderList = pendingOrdersList.concat(res.data.results);
                setPendingOrdersList(orderList);
                setNextListLink(res.data.next);
                setTotalListCount(res.data.count);
                setIsLoading(false);
                setHasLoadingError(false);
            },
            (error) => {
                console.log("Error in fetching Pending Orders List", error);
                setPageLoadErrorMessage(utils.GetErrorMessage(error));
                setIsLoading(false);
                setHasLoadingError(true);
            }
        );
    };

    const displayPendingOrders = (order, index) => {
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{order?.party?.id + "-" + order?.code}</td>
                <td>{utils.FormatDisplayDate(order?.date)}</td>
                <td>
                    {utils.ToTitleCase(order?.party?.name) +
                        ", " +
                        utils.ToTitleCase(order?.party?.station) +
                        (order?.remark?.length > 0 ? " (" + utils.ToTitleCase(order?.remark) + ")" : "")}
                </td>
                <td>{utils.DisplayDecimal2Places(order?.pending_qty)}</td>
                <td>{utils.DisplayDecimal2Places(order?.qty)}</td>
            </tr>
        );
    };

    const loadMoreEntries = () => {
        let nextListURL = new URL(nextListLink);
        fetchPendingOrdersList(nextListURL.pathname + nextListURL.search);
    };

    var component = utils.PageLoadComponents(hasLoadingError ? "Error in fetching pending patta orders -> " + pageLoadErrorMessage : "", isLoading);
    if (component == null) {
        component = (
            <Can
                role={userDetails[constants.ROLE]}
                perform="bsil:patta:orders:order:pending:view"
                no={() => <UnAuthorizedComponent />}
                yes={() => (
                    <Container>
                        <h2 className="appNameHeaderTitle">
                            <a href="/bsil/patta/orders/order/pending/view" style={{ color: "black" }}>
                                PENDING ORDERS {` - ${utils.ToTitleCase(category)}`}
                            </a>
                            <br />
                        </h2>
                        <Can
                            role={userDetails[constants.ROLE]}
                            perform="bsil:patta:orders:order:pending:categoryTotal:view"
                            yes={() => (
                                <Row>
                                    <Col>
                                        <Table bordered striped hover size="sm" variant="dark">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <a href="/bsil/patta/orders/order/pending/view?category=patla" className="whiteColorLink">
                                                            Patla (MT)
                                                        </a>
                                                    </th>
                                                    <th>
                                                        <a href="/bsil/patta/orders/order/pending/view?category=mota" className="whiteColorLink">
                                                            Mota (MT)
                                                        </a>
                                                    </th>
                                                    <th>
                                                        <a href="/bsil/patta/orders/order/pending/view?category=kabja" className="whiteColorLink">
                                                            Kabja (MT)
                                                        </a>
                                                    </th>
                                                    <th>GSL In Mill (MT)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <a href="/bsil/patta/orders/order/pending/view?category=patla" className="whiteColorLink">
                                                            {categoryPendingTotal["patla"]}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href="/bsil/patta/orders/order/pending/view?category=mota" className="whiteColorLink">
                                                            {categoryPendingTotal["mota"]}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href="/bsil/patta/orders/order/pending/view?category=kabja" className="whiteColorLink">
                                                            {categoryPendingTotal["kabja"]}
                                                        </a>
                                                    </td>
                                                    <td>{categoryPendingTotal["gsl_patta_sizes_in_mill_qty"]}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            )}
                        />
                        {pendingOrdersList.length == 0 ? (
                            <Alert variant="success"> No Pending Orders</Alert>
                        ) : (
                            <Fragment>
                                <Row>
                                    <Col>
                                        <Table responsive bordered hover size="sm" variant="link">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Order ID</th>
                                                    <th>Date</th>
                                                    <th>Party Name</th>
                                                    <th>Pending (kg)</th>
                                                    <th>Total (kg)</th>
                                                </tr>
                                            </thead>
                                            <tbody>{pendingOrdersList.map(displayPendingOrders)}</tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={4}>
                                        Showing {pendingOrdersList.length} of {totalListCount}
                                        <br />
                                        {nextListLink ? (
                                            <Button variant="warning" onClick={loadMoreEntries}>
                                                Load More
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </Col>
                                    <Col sm={4}></Col>
                                </Row>
                            </Fragment>
                        )}
                    </Container>
                )}
            />
        );
    }
    return (
        <Fragment>
            <div className="container align-self-center mt-5">
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={10}>{component}</Col>
                    <Col sm={1}></Col>
                </Row>
            </div>
        </Fragment>
    );
}

ViewPattaPendingOrders.propTypes = {
    location: PropTypes.object,
    search: PropTypes.string,
};
