import Select from "react-select";
import PropTypes from "prop-types";
import React from "react";
import CreatableSelect from "react-select/creatable";

export class SelectDropdown extends React.Component {
    constructor(props) {
        super(props);
    }

    handleChange = (value, { action }) => {
        if (value != null) {
            console.log("this.props.name : ", this.props.name, " value : ", value["value"]);
            // this is going to call setFieldValue and manually update values.topics
            if (this.props.onChangeCallback) {
                this.props.onChangeCallback(value["value"]);
            }
            this.props.onChange(this.props.name, value["value"]);
        }
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topics
        this.props.onBlur(this.props.name, true);
    };

    render() {
        const customStyles = {
            control: (base) => ({
                ...base,
                height: "1.9rem",
                "min-height": "1.9rem",
                paddingBottom: "2.3em",
                fontSize: "0.87em",
            }),
        };
        if (this.props.value == null) {
            return (
                <div>
                    <Select
                        className="selectDropdown"
                        styles={customStyles}
                        options={this.props.options}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.placeholder}
                    />
                    {!!this.props.error && this.props.touched && (
                        <span style={{ color: "#DC3545", marginTop: ".5rem", fontSize: "0.8rem" }}>{this.props.error}</span>
                    )}
                </div>
            );
        } else {
            return (
                <div>
                    <Select
                        className="selectDropdown"
                        styles={customStyles}
                        value={this.props.options.filter((option) => option.value === this.props.value)}
                        options={this.props.options}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.placeholder}
                    />
                    {!!this.props.error && this.props.touched && (
                        <span style={{ color: "#DC3545", marginTop: ".5rem", fontSize: "0.8rem" }}>{this.props.error}</span>
                    )}
                </div>
            );
        }
    }
}

SelectDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    placeholder: PropTypes.string,
    touched: PropTypes.bool,
    error: PropTypes.string,
    onChangeCallback: PropTypes.func,
};

export class CreatableSelectDropdown extends React.Component {
    handleChange = (value) => {
        if (value != null) {
            // this is going to call setFieldValue and manually update values.topics
            this.props.onChange(this.props.name, value["value"]);
            if (this.props.onChangeCallback) {
                this.props.onChangeCallback(value["value"]);
            }
        }
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topics
        this.props.onBlur(this.props.name, true);
    };

    render() {
        const customStyles = {
            control: (base) => ({
                ...base,
                height: "1.9rem",
                "min-height": "1.9rem",
                paddingBottom: "2.3em",
                fontSize: "0.87em",
            }),
        };
        let val = this.props.options.filter((option) => option.value === this.props.value);
        // for create selectable, if option is not obtained from filter or value is empty string
        // a new value was given. Hence don't assign val in that case.
        if (val.length > 0 || this.props.value == "") {
            return (
                <div>
                    <CreatableSelect
                        className="selectDropdown"
                        value={val}
                        styles={customStyles}
                        options={this.props.options}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.placeholder}
                    />
                    {!!this.props.error && this.props.touched && (
                        <span style={{ color: "#DC3545", marginTop: ".5rem", fontSize: "0.8rem" }}>{this.props.error}</span>
                    )}
                </div>
            );
        } else {
            return (
                <div>
                    <CreatableSelect
                        className="selectDropdown"
                        styles={customStyles}
                        options={this.props.options}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.placeholder}
                    />
                    {!!this.props.error && this.props.touched && (
                        <span style={{ color: "#DC3545", marginTop: ".5rem", fontSize: "0.8rem" }}>{this.props.error}</span>
                    )}
                </div>
            );
        }
    }
}

CreatableSelectDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    placeholder: PropTypes.string,
    touched: PropTypes.bool,
    error: PropTypes.string,
    onChangeCallback: PropTypes.func,
};

export class SelectDropdownFieldArray extends React.Component {
    constructor(props) {
        super(props);
    }

    handleChange = (value, { action }) => {
        if (value != null) {
            if (this.props.runBeforeChange) {
                this.props.runBeforeChange();
            }
            // this is going to call setFieldValue and manually update values.topics
            this.props.onChange(`${this.props.fieldArrayName}.${this.props.index}.${this.props.fieldInputName}`, value["value"]);
        }
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topics
        this.props.onBlur(`${this.props.fieldArrayName}.${this.props.index}.${this.props.fieldInputName}`, true);
    };

    render() {
        const customStyles = {
            control: (base) => ({
                ...base,
                height: "1.9rem",
                "min-height": "1.9rem",
                paddingBottom: "2.3em",
                fontSize: "0.87em",
                width: "100%",
            }),
        };

        if (this.props.value == null) {
            return (
                <div>
                    <Select
                        className="selectDropdown"
                        styles={customStyles}
                        options={this.props.options}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.placeholder}
                    />
                    {this.props.touched &&
                        this.props.touched[this.props.fieldArrayName] &&
                        this.props.touched[this.props.fieldArrayName][this.props.index] &&
                        this.props.touched[this.props.fieldArrayName][this.props.index][this.props.fieldInputName] &&
                        this.props.errors &&
                        this.props.errors[this.props.fieldArrayName] &&
                        this.props.errors[this.props.fieldArrayName][this.props.index] &&
                        this.props.errors[this.props.fieldArrayName][this.props.index][this.props.fieldInputName] && (
                            <div className="field-error">{this.props.errors[this.props.fieldArrayName][this.props.index][this.props.fieldInputName]}</div>
                        )}
                </div>
            );
        } else {
            return (
                <div>
                    <Select
                        className="selectDropdown"
                        value={this.props.options.filter((option) => option.value === this.props.value)}
                        styles={customStyles}
                        options={this.props.options}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.placeholder}
                    />
                    {this.props.touched &&
                        this.props.touched[this.props.fieldArrayName] &&
                        this.props.touched[this.props.fieldArrayName][this.props.index] &&
                        this.props.touched[this.props.fieldArrayName][this.props.index][this.props.fieldInputName] &&
                        this.props.errors &&
                        this.props.errors[this.props.fieldArrayName] &&
                        this.props.errors[this.props.fieldArrayName][this.props.index] &&
                        this.props.errors[this.props.fieldArrayName][this.props.index][this.props.fieldInputName] && (
                            <div className="field-error">{this.props.errors[this.props.fieldArrayName][this.props.index][this.props.fieldInputName]}</div>
                        )}
                </div>
            );
        }
    }
}

SelectDropdownFieldArray.propTypes = {
    fieldArrayName: PropTypes.string.isRequired,
    value: PropTypes.string,
    index: PropTypes.number.isRequired,
    fieldInputName: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    runBeforeChange: PropTypes.func,
    onBlur: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    placeholder: PropTypes.string,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};
