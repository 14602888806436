import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";

import "../public/home.css";
import "../public/custom.css";
import Can from "../components/can";
import * as utils from "../utils/utils";
import constants from "../constants/constants";
import LogOutButton from "../components/logOutButton";

export default function HomeDashBoard() {
    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }
    var NavBarList = [
        {
            navbarPerform: "bsil:apps:view",
            navbarHref: "/bsil",
            navbarTitle: "Bajaj Steels & Industries",
        },
        {
            navbarPerform: "gsl-utn:apps:view",
            navbarHref: "/gsl/utensil",
            navbarTitle: "Global Smelter Limited - Utensils",
        },
        {
            navbarPerform: "gsl-tower:apps:view",
            navbarHref: "/gsl/tower",
            navbarTitle: "Global Smelter Limited - Tower",
        },
    ];
    var ButtonsList = [
        {
            perform: "bsil:apps:view",
            buttonVariant: "primary",
            buttonHref: "/bsil",
            buttonMessage: <Fragment>BSIL&nbsp;Apps</Fragment>,
        },
        {
            perform: "gsl-utn:apps:view",
            buttonVariant: "secondary",
            buttonHref: "/gsl/utensil",
            buttonMessage: <Fragment>GSL&nbsp; Utensils</Fragment>,
        },
        {
            perform: "gsl-tower:apps:view",
            buttonVariant: "danger",
            buttonHref: "/gsl/tower",
            buttonMessage: "Global Smelter Limited - Tower",
        },
    ];
    return (
        <Fragment>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
                <Navbar.Brand href="/">
                    <img src="/static/dhanush-logo-white.png" width="55" height="55" className="d-inline-block align-top" alt="Dhanush" />
                    <br />
                    <span className="dhanush-logo-title">DHANUSH</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {NavBarList.map((node, index) => (
                            <Can
                                key={index}
                                role={userDetails[constants.ROLE]}
                                perform={node.navbarPerform}
                                yes={() => (
                                    <Nav.Link className="navbarLinkSpan" href={node.navbarHref}>
                                        {node.navbarTitle}
                                    </Nav.Link>
                                )}
                            />
                        ))}
                    </Nav>
                    <LogOutButton />
                </Navbar.Collapse>
            </Navbar>
            <Fragment className="homeDashboard">
                <img src="/static/dhanush-logo-black-white-background.jpg" className="homeDashboardBackgroundImage" />
                <div className="container align-self-center mt-5">
                    <Row>
                        <Col sm={3}></Col>
                        <Col sm={6}>
                            {ButtonsList.map((node, index) => (
                                <Can
                                    key={index}
                                    role={userDetails[constants.ROLE]}
                                    perform={node.perform}
                                    yes={() => (
                                        <Container>
                                            <Row className="row-m-t">
                                                <Col>
                                                    <AwesomeButton
                                                        type={node.buttonVariant}
                                                        href={node.buttonHref}
                                                        style={{
                                                            width: "100%",
                                                            height: "400%",
                                                            fontSize: "200%",
                                                            fontFamily: "Comic Sans MS",
                                                            "--button-primary-color": "#00b8c4",
                                                            "--button-primary-color-dark": "#d0b400",
                                                            "--button-primary-color-light": "#ffe11d",
                                                            "--button-primary-color-hover": "#00a0ab",
                                                            "--button-primary-border": "1px solid #ffe11d",
                                                            "--button-secondary-color": "#ee3253",
                                                            "--button-secondary-color-dark": "#d0b400",
                                                            "--button-secondary-color-light": "#ffe11d",
                                                            "--button-secondary-color-hover": "#ec1a3ff8",
                                                            "--button-secondary-border": "1px solid #ffe11d",
                                                        }}
                                                    >
                                                        {node.buttonMessage}
                                                    </AwesomeButton>
                                                </Col>
                                            </Row>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </Container>
                                    )}
                                />
                            ))}
                        </Col>
                        <Col sm={3}></Col>
                    </Row>
                </div>
            </Fragment>
        </Fragment>
    );
}
