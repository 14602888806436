export class UtnSizesQtyFieldClass {
    constructor(rowID) {
        // ID is used so that on removing field array, the correct row gets deleted
        // using index as the key results in last or first row getting deleted, not the selected one
        // because using index as key changes the row keys.
        // Hence we need to use non-changing object ID i.e this.ID as the row key
        // https://github.com/formium/formik/issues/1504
        // https://stackoverflow.com/questions/61546280/formik-arrayhelpers-remove-is-not-removing-the-selected-item
        this.id = rowID;
        this.name = "";
        this.brand = "";
        this.size = "";
        this.qty = 0.0;
    }
}