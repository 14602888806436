import React from "react";
import { Spinner } from 'react-bootstrap'

export function BodySpinner() {
    return (
        <div className="container vertical-center">
            <img
                src="/static/dhanush-logo-black.png"
                width="100"
                height="100"
                className="d-inline-block align-top"
                alt="Dhanush"
            />
            <Spinner className="body-page-spinner" animation="border" role="status" />
        </div>
    )
}

export function SubmitButtonSpinner() {
    return (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    )
}