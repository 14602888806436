import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import "../../../../public/custom.css";
import Can from "../../../../components/can";
import * as utils from "../../../../utils/utils";
import constants from "../../../../constants/constants";
import LogOutButton from "../../../../components/logOutButton";

import NewUtensilParty from "./party/newUtensilParty";
import ViewUtensilPartyDetails from "./party/viewPartyDetails";

import NewUtensilOrder from "./order/newUtensilOrder";
import { ViewOrderDetails } from "./order/viewOrderDetails";
import ViewPendingOrders from "./order/viewPendingOrders";

import ViewPattaRequirement from "./order/viewPattaRequirements";
import DispatchNewLorry from "../inventory/lorry/dispatchNewLorry";
import ViewDispatchedLorry from "./lorry/viewDispatchedLorry";
import ViewCumulativeDispatchReport from "./lorry/viewCumulativeDispatch";

export default function GSLUtensilOrdersApps() {
	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	var NavBarList = [
		{
			/* Party Dropdown */
			navbarPerform: "gsl:utensil:orders:party:navbar:view",
			navDropdownTitle: "Party",
			navDropdown: [
				{
					perform: "gsl:utensil:orders:party:new",
					href: "/gsl/utensil/orders/party/new",
					dropDownTitle: "Add New Party",
				},
				{
					perform: "gsl:utensil:orders:party:view",
					href: "/gsl/utensil/orders/party/view",
					dropDownTitle: "View Party Details",
				},
			],
		},
		{
			/* Utensil Order Dropdown */
			navbarPerform: "gsl:utensil:orders:order:navbar:view",
			navDropdownTitle: "Orders",
			navDropdown: [
				{
					perform: "gsl:utensil:orders:order:new",
					href: "/gsl/utensil/orders/order/new",
					dropDownTitle: "Add/Update Order",
				},
				{
					perform: "gsl:utensil:orders:order:pending:view",
					href: "/gsl/utensil/orders/order/pending/view",
					dropDownTitle: "Pending Orders",
				},
				{
					perform: "gsl:utensil:orders:order:patta-requirement:view",
					href: "/gsl/utensil/orders/order/patta-requirement",
					dropDownTitle: "View Patta Requirement",
				},
			],
		},
		{
			/* Lorry Dropdown */
			navbarPerform: "gsl:utensil:orders:lorry:navbar:view",
			navDropdownTitle: "Dispatch",
			navDropdown: [
				{
					perform: "gsl:utensil:orders:lorry:dispatched:view",
					href: "/gsl/utensil/orders/lorry/dispatched/view",
					dropDownTitle: "View Dispatched Orders",
				},
				{
					perform: "gsl:utensil:orders:dispatch:cumulative:view",
					href: "/gsl/utensil/orders/cumulative/dispatched/view",
					dropDownTitle: "View Cumulative Dispatch Report",
				},
			],
		},
	];

	var MenubarList = [
		{
			groupTitle: "Party",
			perform: "gsl:utensil:orders:party:navbar:view",
			buttonList: [
				{
					perform: "gsl:utensil:orders:party:new",
					buttonVariant: "danger",
					fontAwesomeIcon: "user-plus",
					buttonHref: "/gsl/utensil/orders/party/new",
					description: "Add New Party",
				},
				{
					perform: "gsl:utensil:orders:party:view",
					buttonVariant: "light",
					fontAwesomeIcon: "users",
					buttonHref: "/gsl/utensil/orders/party/view",
					description: "View Party Details",
				},
			],
		},

		{
			groupTitle: "Orders",
			perform: "gsl:utensil:orders:order:navbar:view",
			buttonList: [
				{
					perform: "gsl:utensil:orders:order:new",
					buttonVariant: "info",
					fontAwesomeIcon: "plus",
					buttonHref: "/gsl/utensil/orders/order/new",
					description: "Add/Update Order",
				},
				{
					perform: "gsl:utensil:orders:order:pending:view",
					buttonVariant: "warning",
					fontAwesomeIcon: "history",
					buttonHref: "/gsl/utensil/orders/order/pending/view",
					description: "Pending Orders",
				},
				{
					perform: "gsl:utensil:orders:order:patta-requirement:view",
					buttonVariant: "success",
					fontAwesomeIcon: "book",
					buttonHref: "/gsl/utensil/orders/order/patta-requirement",
					description: "View Patta Requirement",
				},
			],
		},

		{
			groupTitle: "Dispatch",
			perform: "gsl:utensil:orders:lorry:navbar:view",
			buttonList: [
				{
					perform: "gsl:utensil:orders:lorry:dispatched:view",
					buttonVariant: "dark",
					fontAwesomeIcon: "truck",
					buttonHref: "/gsl/utensil/orders/lorry/dispatched/view",
					description: "View Dispatched Orders",
				},
				{
					perform: "gsl:utensil:orders:dispatch:cumulative:view",
					buttonVariant: "primary",
					fontAwesomeIcon: "chart-line",
					buttonHref: "/gsl/utensil/orders/cumulative/dispatched/view",
					description: "View Cumulative Dispatch Report",
				},
			],
		},
	];

	return (
		<Fragment>
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
				<Navbar.Brand href="/gsl/utensil">
					<img src="/static/gsl-logo.png" width="60" height="60" className="d-inline-block align-top" alt="GSL" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mr-auto">
						<Nav.Link href="/gsl/utensil/orders" className="navbarLinkTitle app-navbar-links">
							GSL Utensil Orders
						</Nav.Link>
						{NavBarList.map((node, index) => (
							<Can
								key={index}
								role={userDetails[constants.ROLE]}
								perform={node.navbarPerform}
								yes={() => (
									<NavDropdown className="navbarLinkSpan" title={node.navDropdownTitle} id="basic-nav-dropdown">
										{node.navDropdown.map((navdropdown, dropDownIndex) => (
											<Can
												key={dropDownIndex}
												role={userDetails[constants.ROLE]}
												perform={navdropdown.perform}
												yes={() => (
													<NavDropdown.Item className="navbarLinkSpan" href={navdropdown.href}>
														{navdropdown.dropDownTitle}
													</NavDropdown.Item>
												)}
											/>
										))}
									</NavDropdown>
								)}
							/>
						))}
					</Nav>
					<LogOutButton />
				</Navbar.Collapse>
			</Navbar>

			<BrowserRouter>
				<Switch>
					<Route path="/gsl/utensil/orders/party/new" component={NewUtensilParty} />
					<Route path="/gsl/utensil/orders/party/view" component={ViewUtensilPartyDetails} />
					<Route path="/gsl/utensil/orders/order/new" component={NewUtensilOrder} />
					<Route path="/gsl/utensil/orders/order/view" component={ViewOrderDetails} />
					<Route path="/gsl/utensil/orders/order/pending/view" component={ViewPendingOrders} />
					<Route path="/gsl/utensil/orders/lorry/new" component={DispatchNewLorry} />
					<Route path="/gsl/utensil/orders/order/patta-requirement" component={ViewPattaRequirement} />
					<Route path="/gsl/utensil/orders/lorry/dispatched/view" component={ViewDispatchedLorry} />
					<Route path="/gsl/utensil/orders/cumulative/dispatched/view" component={ViewCumulativeDispatchReport} />
					<Route exact path="/gsl/utensil/orders">
						<div className="appHeader">
							<h2 className="appHeaderTitle">GSL UTENSIL ORDERS</h2>
						</div>

						<div className="container align-self-center mt-5">
							<Row>
								<Col sm={3}></Col>
								<Col sm={6}>
									<Container>
										{MenubarList.map((node, index) => (
											<Can
												key={index}
												role={userDetails[constants.ROLE]}
												perform={node.perform}
												yes={() => (
													<Row className="row-m-t">
														<Col>
															<h4 className="menuBarSubTitle">
																{node.groupTitle}
																<br></br>
															</h4>
															<Row className="row-m-t">
																{node.buttonList.map((button, ind) => (
																	<Can
																		key={index + "-" + ind}
																		role={userDetails[constants.ROLE]}
																		perform={button.perform}
																		yes={() => (
																			<Col>
																				<Button
																					size="lg"
																					variant={button.buttonVariant}
																					href={button.buttonHref}
																				>
																					<FontAwesomeIcon icon={button.fontAwesomeIcon} size={"2x"} />
																				</Button>
																				{button.description.trim() !== "" ? (
																					<strong>
																						<br></br> {button.description}
																					</strong>
																				) : (
																					<span></span>
																				)}
																			</Col>
																		)}
																	/>
																))}
															</Row>
														</Col>
													</Row>
												)}
											/>
										))}
									</Container>
								</Col>
								<Col sm={3}></Col>
							</Row>
						</div>
					</Route>
				</Switch>
			</BrowserRouter>
		</Fragment>
	);
}
