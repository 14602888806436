import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Table, Alert, Button } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import AppConstants from "../constants";
import Can from "../../../../../components/can";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";

export default function ViewDispatchSchedule() {
    //this key is to reset SelectDropdown on form submit
    const [isLoading, setIsLoading] = useState(true);
    const [hasLoadingError, setHasLoadingError] = useState(false);
    const [pageLoadErrorMessage, setPageLoadErrorMessage] = useState("");
    /* 
        pendingDispatchScheduleList = [{
            date: "2020-01-09",
            id: "xxxx",
            code: "Jan2021-1",
            remark: "",
            required_sizes: {
                "R8 60 17": 5000,
                "R8 60 19": 2000,
            }
        }]
    */
    const [pendingDispatchScheduleList, setPendingDispatchScheduleList] = useState([]);

    useEffect(() => {
        fetchPendingDispatchScheduleList(`/api/v1/bsil/patta/gsl-utn-patta-sizes/lorry/dispatch/schedule`);
    }, []);

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    const fetchPendingDispatchScheduleList = (URL) => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        return api.Get(
            URL,
            {},
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                console.log("res : ", res);
                setPendingDispatchScheduleList(res.data);
                setIsLoading(false);
                setHasLoadingError(false);
            },
            (error) => {
                console.log("Error in fetching Dispatch Schedule", error);
                setPageLoadErrorMessage(utils.GetErrorMessage(error));
                setIsLoading(false);
                setHasLoadingError(true);
            }
        );
    };

    // convert Jan21-456 to Jan-456
    const displayCode = (code) => {
        return code.substring(0, 3) + "-" + code.substring(8);
    };

    const displayPendingDispatchSchedule = (lorry, index) => {
        var totalQty = 0.0;
        return (
            <Row key={index}>
                <Col sm={2}></Col>
                <Col sm={8}>
                    <Table
                        responsive
                        bordered
                        hover
                        size="sm"
                        variant="primary"
                        style={{
                            border: "1.75px solid black",
                        }}
                    >
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <u>
                                        <i>{displayCode(lorry.code)}</i>
                                    </u>
                                </th>
                                <th>{utils.FormatDisplayDate(lorry.date)}</th>
                                <th>{lorry.remark}</th>
                                <th></th>
                                <th></th>
                                <th colSpan={2}>
                                    {
                                        <Can
                                            role={userDetails[constants.ROLE]}
                                            perform="bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:new"
                                            yes={() => (
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    href={
                                                        "/bsil/patta/gsl-patta-sizes/lorry/dispatchedSizes/new?scheduleLorryID=" +
                                                        lorry.id +
                                                        "&scheduleLorryCode=" +
                                                        displayCode(lorry.code)
                                                    }
                                                >
                                                    Dispatch Lorry
                                                </Button>
                                            )}
                                        />
                                    }
                                </th>
                            </tr>
                            <tr>
                                <th>#</th>
                                <th>Utensil</th>
                                <th>Grade</th>
                                <th>Width</th>
                                <th>Point</th>
                                <th>Qty (kg)</th>
                                <th>Length</th>
                                <th>Section</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(lorry.required_sizes).map((key, i) => {
                                totalQty += lorry.required_sizes[key];
                                return (
                                    <tr key={index + "-" + i}>
                                        <td>{i + 1}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.UTENSIL_INDEX]}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.GRADE_INDEX]}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.WIDTH_INDEX]}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.POINT_INDEX]}</td>
                                        <td>{utils.DisplayDecimal2Places(lorry.required_sizes[key])}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.LENGTH_INDEX]}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.SECTION_INDEX]}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <th colSpan={4}></th>
                                <th>Total</th>
                                <th>{totalQty}</th>
                                <th colSpan={3}></th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        );
    };

    var component = utils.PageLoadComponents(hasLoadingError ? "Error in fetching dispatch schedule -> " + pageLoadErrorMessage : "", isLoading);
    if (component == null) {
        component =
            pendingDispatchScheduleList.length == 0 ? (
                <Alert variant="success"> No Dispatch Schedule</Alert>
            ) : (
                <Can
                    role={userDetails[constants.ROLE]}
                    perform="bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:view"
                    no={() => <UnAuthorizedComponent />}
                    yes={() => (
                        <Container>
                            <h2 className="appNameHeaderTitle">
                                VIEW DISPATCH SCHEDULE
                                <br />
                            </h2>
                            <Row>
                                <Col>{pendingDispatchScheduleList.map(displayPendingDispatchSchedule)}</Col>
                            </Row>
                        </Container>
                    )}
                />
            );
    }
    return (
        <Fragment>
            <div className="container align-self-center mt-5">
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={10}>{component}</Col>
                    <Col sm={1}></Col>
                </Row>
            </div>
        </Fragment>
    );
}
