import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "./App.css";
import store from "./app/store";
import HomeDashBoard from "./pages/home";
import LogOut from "./pages/login/logOut";
import LoginForm from "./pages/login/loginForm";
import SecureRoute from "./app/secureRoute";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faPlus,
    faInfo,
    faPlusSquare,
    faPlusCircle,
    faHistory,
    faTruck,
    faThList,
    faList,
    faUtensils,
    faFile,
    faCircle,
    faPaperPlane,
    faBook,
    faUsers,
    faUserPlus,
    faChartLine,
    faClipboardCheck,
    faCut,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faPlus,
    faInfo,
    faPlusSquare,
    faPlusCircle,
    faHistory,
    faUsers,
    faUserPlus,
    faTruck,
    faThList,
    faList,
    faUtensils,
    faFile,
    faCircle,
    faPaperPlane,
    faBook,
    faChartLine,
    faClipboardCheck,
    faCut
);

// BSIL Dashboard Page
import BSILApps from "./pages/bsil/bsilApps";
// BSIL Apps
import BSILPattaOrdersApps from "./pages/bsil/patta/orders/bsilPatta";
import BSILGSLPattaSizesApps from "./pages/bsil/patta/gsl-patta-sizes/gslPattaSizes";

// GSL Utensil Dashboard Page
import GSLUtensilApps from "./pages/gsl/utensil/gslUtensilApps";
// GSL Utensil Apps
import GSLUtensilInventoryApps from "./pages/gsl/utensil/inventory/gslUtensilInventory";
import GSLUtensilOrdersApps from "./pages/gsl/utensil/orders/gslUtensilOrders";

const styles = {
    container: {
        align: "center",
    },
};

function App() {
    if (process.env.REACT_APP_STAGE != "development") {
        console.log = function no_console() {};
    }

    return (
        <div className="App" style={styles}>
            <Fragment>
                <Provider store={store}>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/login" render={(props) => <LoginForm {...props} />} />
                            <Route path="/logout" component={LogOut} />
                            {/* By default all nested routes are SecureRoutes */}

                            {/* BSIL PATTA APPS */}
                            <SecureRoute path="/bsil/patta/orders" component={BSILPattaOrdersApps} />
                            <SecureRoute path="/bsil/patta/gsl-patta-sizes" component={BSILGSLPattaSizesApps} />

                            {/* GSL UTENSILS APPS */}
                            <SecureRoute path="/gsl/utensil/inventory" component={GSLUtensilInventoryApps} />
                            <SecureRoute path="/gsl/utensil/orders" component={GSLUtensilOrdersApps} />

                            {/* COMPANY APPS */}
                            <SecureRoute path="/bsil" component={BSILApps} />
                            <SecureRoute path="/gsl/utensil" component={GSLUtensilApps} />
                            <SecureRoute path="/" component={HomeDashBoard} />
                        </Switch>
                    </BrowserRouter>
                </Provider>
            </Fragment>
        </div>
    );
}

export default App;
