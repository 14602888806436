import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";

import Can from "../../components/can";
import * as utils from "../../utils/utils";
import constants from "../../constants/constants";
import LogOutButton from "../../components/logOutButton";

export default function BSILApps() {
	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	var NavBarList = [
		{
			navbarPerform: "bsil:patta:orders:app:view",
			navbarHref: "/bsil/patta/orders",
			navbarTitle: "Patta Orders",
		},
		{
			navbarPerform: "bsil:patta:gsl-patta-sizes:app:view",
			navbarHref: "/bsil/patta/gsl-patta-sizes",
			navbarTitle: "GSL Patta Sizes",
		},
	];
	var ButtonsList = [
		{
			perform: "bsil:patta:orders:app:view",
			buttonVariant: "secondary",
			buttonHref: "/bsil/patta/orders",
			buttonMessage: <Fragment>Patta&nbsp;Orders</Fragment>,
		},
		{
			perform: "bsil:patta:gsl-patta-sizes:app:view",
			buttonVariant: "primary",
			buttonHref: "/bsil/patta/gsl-patta-sizes",
			buttonMessage: <Fragment>GSL&nbsp;Patta&nbsp;Sizes</Fragment>,
		},
	];

	return (
		<Fragment>
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
				<Navbar.Brand href="/">
					<img src="/static/bsil-logo.png" width="60" height="60" className="d-inline-block align-top" alt="BSIL" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mr-auto">
						{NavBarList.map((node, index) => (
							<Can
								key={index}
								role={userDetails[constants.ROLE]}
								perform={node.navbarPerform}
								yes={() => (
									<Nav.Link className="navbarLinkSpan" href={node.navbarHref}>
										{node.navbarTitle}
									</Nav.Link>
								)}
							/>
						))}
					</Nav>
					<LogOutButton />
				</Navbar.Collapse>
			</Navbar>
			<div className="container align-self-center mt-5">
				<Row>
					<Col sm={3}></Col>
					<Col sm={6}>
						{ButtonsList.map((node, index) => (
							<Can
								key={index}
								role={userDetails[constants.ROLE]}
								perform={node.perform}
								yes={() => (
									<Container>
										<Row className="row-m-t">
											<Col>
												<AwesomeButton
													type={node.buttonVariant}
													href={node.buttonHref}
													style={{
														width: "100%",
														height: "400%",
														fontSize: "200%",
														fontFamily: "Comic Sans MS",
														"--button-primary-color": "#475472",
														"--button-primary-color-dark": "#2a3143",
														"--button-primary-color-light": "#d4d9e4",
														"--button-primary-color-hover": "#424e6a",
														"--button-primary-border": "none",
														"--button-secondary-color": "#fffc6c",
														"--button-secondary-color-dark": "#b9b500",
														"--button-secondary-color-light": "#6c6a00",
														"--button-secondary-color-hover": "#fffb3e",
														"--button-secondary-border": "none",
													}}
												>
													{node.buttonMessage}
												</AwesomeButton>
											</Col>
										</Row>
										<br />
										<br />
										<br />
										<br />
										<br />
										<br />
									</Container>
								)}
							/>
						))}
					</Col>
					<Col sm={3}></Col>
				</Row>
			</div>
		</Fragment>
	);
}
