import * as utils from "../../../../utils/utils";

/*
utnDetailsDropdown is of the form
{
    '1': {
        "name": "Pawali",
        "sizes": ["12", "12*18", "26*30"],
    }
}
*/

// Function accepts dropdown list from backend and converts it to format
// for react-select [{label:<NAME>, value:<KEY>}]
export function FetchUtnNameDropdown(utnDetailsDropdown) {
    var utnNameSelectDropdown = [];
    Object.keys(utnDetailsDropdown).forEach(function (utnID) {
        utnNameSelectDropdown.push({ label: utils.ToTitleCase(utnDetailsDropdown[utnID]["name"]), value: utnID });
    });
    return utnNameSelectDropdown;
}

// Function to fetch acceptable sizes from utn name
export function FetchUtnSizesDropdown(utnDetailsDropdown, utnID) {
    if (utnID == null || utnID == undefined || utnID === "") {
        return [];
    }

    if (!(utnID in utnDetailsDropdown)) {
        console.log("utn Name not found in list : ", utnID);
        return [];
    }

    // looping over sizes of a utn and returning as list
    var utnSizesSelectDropdown = [];
    utnDetailsDropdown[utnID]["sizes"].forEach(function (sizeVal) {
        utnSizesSelectDropdown.push({ label: utils.ToTitleCase(sizeVal), value: sizeVal });
    });

    return utnSizesSelectDropdown;
}

// Function to fetch acceptable brands from utn name
export function FetchUtnBrandDropdown(utnBrandDropdown) {
    var utnBrandSelectDropdown = [];
    Object.keys(utnBrandDropdown).forEach(function (brandKey) {
        utnBrandSelectDropdown.push({ label: utils.ToTitleCase(utnBrandDropdown[brandKey]), value: brandKey });
    });
    return utnBrandSelectDropdown;
}

/*
    ********  Party Name UTIL FUNCTIONS ********
    utnPartyNameDropdown is of the form
      [{
          partyID: 1,
          name: "Maa Vaishno",
          place: "lucknow"
      }]
*/

// Function accepts dropdown list from backend and converts it to format
// for react-select [{label:<NAME>, value:<KEY>}]
export function FetchPartyNameDropdown(utnPartyNameDropdown, includeAllOption = false) {
    var utnPartyNameSelectDropdown = [];
    if (includeAllOption) {
        utnPartyNameSelectDropdown.push({ label: "-", value: "-" });
    }
    utnPartyNameDropdown.forEach(function (partyObj) {
        utnPartyNameSelectDropdown.push({
            label: utils.ToTitleCase(`${partyObj["name"]}, ${partyObj["place"]}`),
            value: partyObj["id"].toString(),
        });
    });
    return utnPartyNameSelectDropdown;
}

// Function accepts dropdown list from backend and converts it to format
// for react-select [{label:<NAME>, value:<KEY>}]
export function FetchOrderNoDropdown(partyOrdSerialList) {
    if (partyOrdSerialList == null || partyOrdSerialList == undefined) {
        return [];
    }
    var ordSerialList = [];
    partyOrdSerialList.forEach(function (serialObj) {
        if (serialObj["sizes"].length == 0) {
            //add without size label
            ordSerialList.push({
                label: `WS - ${serialObj["serial"]} | ${utils.FormatDisplayDate(serialObj["ord_date"])}`,
                value: `${serialObj["serial"]} | ${utils.FormatDisplayDate(serialObj["ord_date"])}`,
            });
        } else {
            ordSerialList.push({
                label: `${serialObj["serial"]} | ${utils.FormatDisplayDate(serialObj["ord_date"])}`,
                value: `${serialObj["serial"]} | ${utils.FormatDisplayDate(serialObj["ord_date"])}`,
            });
        }
    });
    return ordSerialList;
}

export function FetchMktPersonDropdown(mktPersonDict) {
    var mktPersonSelectDropdown = [];
    Object.keys(mktPersonDict).forEach(function (through) {
        mktPersonSelectDropdown.push({ label: utils.ToTitleCase(mktPersonDict[through]), value: through });
    });
    return mktPersonSelectDropdown;
}

/*
 * Pattern for state = {
 *          UTTAR-PRADESH: [kanpur, allahabad, ...]
 *          ...
 *          }
 *
 */

// Function to fetch acceptable brands from utn name
export function FetchStateDropdown(stateDict) {
    var stateSelectDropdown = [];
    Object.keys(stateDict).forEach(function (state) {
        stateSelectDropdown.push({ label: utils.ToTitleCase(state), value: state });
    });
    return stateSelectDropdown;
}

// Function to fetch acceptable brands from utn name
export function FetchDistrictDropdown(stateDict, state) {
    if (state == null || state == undefined || state === "") {
        return [];
    }
    if (!(state in stateDict)) {
        console.log("State Name not found in list : ", state);
        return [];
    }

    var districtSelectDropdown = [];
    stateDict[state].forEach(function (district) {
        districtSelectDropdown.push({ label: utils.ToTitleCase(district), value: district });
    });
    return districtSelectDropdown;
}
