module.exports = {
	GRADE_INDEX: 0,
	POINT_INDEX: 1,
	WIDTH_INDEX: 2,
	LENGTH_INDEX: 3,
	SECTION_INDEX: 4,
	UTENSIL_INDEX: 5,
	PATTA_SIZES_VARIANTS: {
		// Pawali + Ration Dabba Farma
		"R8 60 15": ["R8", "60", "15", "112+", "1950", "pawali"],
		"R8 60 17": ["R8", "60", "17", "112+", "1950", "pawali"],
		"R8 60 19": ["R8", "60", "19", "112+", "1950", "pawali"],
		"R8 60 21": ["R8", "60", "21", "112+", "1950", "pawali"],
		"R8 60 23": ["R8", "60", "23", "112+", "1950", "pawali"],
		"R8 60 25": ["R8", "60", "25", "112+", "1950", "pawali"],
		"R8 60 26": ["R8", "70", "26", "67", "1350", "pawali"],
		"R8 60 28": ["R8", "70", "28", "72", "1500", "pawali"],
		"R8 60 30": ["R8", "70", "30", "72", "1500", "pawali"],
		
		// Pawali Premium Farma
		"R8 50 17": ["R8", "50", "17", "95+", "1350", "pawali"],
		"R8 50 19": ["R8", "50", "19", "95+", "1350", "pawali"],

		// Pawali Farma + Circle
		"R8 60 11.5": ["R8", "60", "11.5", "112+", "1950", "circle"],
		"R8 60 12.5": ["R8", "60", "12.5", "112+", "1950", "circle"],
		"R8 60 13.5": ["R8", "60", "13.5", "112+", "1950", "circle"],
		"R8 60 16": ["R8", "60", "16", "112+", "1950", "circle"],
		"R8 60 18": ["R8", "60", "18", "112+", "1950", "circle"],
		"R8 60 20": ["R8", "60", "20", "112+", "1950", "circle"],
		"R8 50 11.5": ["R8", "50", "11.5", "112+", "1950", "circle"],
		"R8 50 12.5": ["R8", "50", "12.5", "112+", "1950", "circle"],
		"R8 50 13.5": ["R8", "50", "13.5", "112+", "1950", "circle"],

		// Balti Farma
		"R8 60 11": ["R8", "60", "11", "112+", "1950", "balti"],
		"R8 60 12": ["R8", "60", "12", "112+", "1950", "balti"],
		"R8 60 13": ["R8", "60", "13", "112+", "1950", "balti"],
		"R8 60 14": ["R8", "60", "14", "112+", "1950", "balti"],

		// Premium Balti Farma
		"R8 50 11": ["R8", "50", "11", "95+", "1350", "balti"],
		"R8 50 12": ["R8", "50", "12", "95+", "1350", "balti"],
		"R8 50 13": ["R8", "50", "13", "95+", "1350", "balti"],
		"R8 50 14": ["R8", "50", "14", "95+", "1350", "balti"],
		"R8 50 15": ["R8", "50", "15", "95+", "1350", "balti"],

		// Fatka Balti Taliya
		"R6 50 17": ["R6", "50", "17", "-", "1500", "circle"],
		"R6 50 18": ["R6", "50", "18", "-", "1500", "circle"],
		"R6 50 19": ["R6", "50", "19", "-", "1500", "circle"],
		"R6 50 20": ["R6", "50", "20", "-", "1500", "circle"],

		// Ration Dabba Lid
		"R6 60 12": ["R6", "60", "12", "112", "-", "circle"],
		"R6 60 13": ["R6", "60", "13", "108", "-", "circle"],
		"R6 60 14": ["R6", "60", "14", "102", "-", "circle"],
		"R6 60 16": ["R6", "60", "16", "100", "-", "circle"],

		// Khomcha , Bachad, MP Bidding
		// R7 khomcha 12, 13 is given as double farma
		"R7 60 11": ["R7", "-", "11", "102", "1900", "circle"],
		"R7 60 12": ["R7", "-", "12", "110", "1900", "circle"],
		"R7 60 13": ["R7", "-", "13", "106", "1900", "circle"],
		"R7 60 14": ["R7", "-", "14", "101", "1900", "circle"],
		"R7 60 23": ["R7", "-", "23", "110", "1900", "circle"],
		"R7 60 25": ["R7", "-", "25", "106", "1900", "circle"],

		// Parat
		"R6 wd-15 len-94": ["R6", "-", "15", "94", "1900", "circle"],
		"R6 wd-16 len-98": ["R6", "-", "16", "98", "1900", "circle"],
		"R6 wd-17 len-90": ["R6", "-", "17", "90", "1900", "circle"],
		"R6 wd-18 len-94": ["R6", "-", "18", "94", "1500", "circle"],

		// UP Tasla
		"R5 wd-16 len-100": ["R5", "-", "16", "100", "2400", "circle"],
		"R5 wd-17 len-105": ["R5", "-", "17", "105", "2400", "circle"],
		"R5 wd-18 len-94": ["R5", "-", "18", "94", "2400", "circle"],
		"R5 wd-19 len-100": ["R5", "-", "19", "100", "2400", "circle"],
		"R5 wd-20 len-104": ["R5", "-", "20", "104", "2400", "circle"],

		// Tope
		"R5 wd-12 len-100": ["R5", "-", "12", "100", "2400", "circle"],
		"R5 wd-13 len-94": ["R5", "-", "13", "94", "2400", "circle"],
		"R5 wd-14 len-88": ["R5", "-", "14", "88", "2400", "circle"],
		"R5 wd-15 len-94": ["R5", "-", "15", "94", "2400", "circle"],
	},
};
