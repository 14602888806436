import PropTypes from "prop-types";
import React from "react";
import { Alert, Button } from "react-bootstrap";

import { SubmitButtonSpinner } from "./spinner";

export function SubmitButton(props) {
    return (
        <Button
            variant={props.variant ? props.variant : "outline-primary"}
            block={props.block ? props.block : false}
            type="submit"
            size="lg"
            disabled={props.isLoading || props.isSubmitting}
        >
            {props.isSubmitting && <SubmitButtonSpinner />}
            &nbsp;Submit
        </Button>
    );
}

SubmitButton.propTypes = {
    block: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    onClick: PropTypes.func,
    variant: PropTypes.string,
};

export function SubmitMessage(props) {
    if (typeof props.submitMessage === "string") {
        return (
            props.submitMessage.length > 0 && (
                <Alert className="submitMessage" variant={props.submitVariant}>
                    {" "}
                    {props.submitMessage}
                </Alert>
            )
        );
    } else {
        return (
            <Alert className="submitMessage" variant={props.submitVariant}>
                {" "}
                {props.submitMessage}
            </Alert>
        );
    }
}
SubmitMessage.propTypes = {
    submitVariant: PropTypes.string,
    submitMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
