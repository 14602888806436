import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState } from "react";
import { Container, Row, Col, Form, Alert, Table } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import Can from "../../../../../components/can";
import yup from "../../../../../components/yup";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";
import { SubmitButton, SubmitMessage } from "../../../../../components/submitComponents";
import { FormTextInput } from "../../../../../components/inputFieldComponents";

export default function ViewProductionReport() {
	const [submitAlertVariant, setSubmitAlertVariant] = useState("success");
	const [submitAlertMessage, setSubmitAlertMessage] = useState("");
	const [dayProductionList, setDayProductionList] = useState([]);

	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	const validationSchema = yup
		.object()
		.nullable()
		.required()
		.shape({
			start_date: yup.string().required("Starting Date is Required."),
			end_date: yup.string().required("Ending Date is Required."),
		});

	const displayDayProduction = (currentStock) => {
		let utnData = {};
		let utnGroup = [];

		console.log("currentStock : ", currentStock);
		//converting array of obj into dictionary
		// <utn-group>:
		//      <utn-display-name>: [{
		//                               utn_id__size:
		//                               utn_id__brand:
		//                               utn_id__uom:
		//                               kalwat_cumulative:
		//                               polish_cumulative:
		//                          }]

		//utnGroup to contain bifurcate based on groups
		try {
			for (var i in currentStock) {
				let obj = currentStock[i];
				if (obj.utn_id__group in utnData) {
					if (obj.utensil_display in utnData[obj.utn_id__group]) {
						utnData[obj.utn_id__group][obj.utensil_display].push(obj);
					} else {
						// initializing if name key does not exist
						utnData[obj.utn_id__group][obj.utensil_display] = [obj];
					}
				} else {
					// initializing if group key does not exist
					utnData[obj.utn_id__group] = {};
					utnData[obj.utn_id__group][obj.utensil_display] = [obj];
					utnGroup.push(obj.utn_id__group);
				}
			}
		} catch (err) {
			console.log("Error in displaying production data : ", err.message);
			utnGroup = []; // resetting to zero length so that nothing is displayed
		}

		// function creates individual tables for different utensils
		const displayIndividualEntityStock = (utnGroup, utnMap) => {
			console.log("creating table for ", utnGroup);
			return (
				<Table responsive bordered size="sm" variant="link">
					<thead>
						<tr
							className="stockNameTitle"
							style={{
								color: "#c3073f",
								background: "#e3e2df",
							}}
						>
							<th colSpan={6}>{utnGroup.toUpperCase()}</th>
						</tr>
					</thead>
					{Object.keys(utnMap[utnGroup]).map((utensil_display, index) => {
						let kalwat_pcs_total = 0,
							kalwat_kg_total = 0,
							polish_pcs_total = 0,
							polish_kg_total = 0;
						return (
							<tbody key={index}>
								<tr className="stockNameTitle">
									<th colSpan={6}>{utensil_display.toUpperCase()}</th>
								</tr>
								<tr>
									<th>#</th>
									<th>Size</th>
									<th colSpan={2}>Kalwat</th>
									<th colSpan={2}>Polish</th>
								</tr>
								<tr>
									<th></th>
									<th></th>
									<th>pcs</th>
									<th>kg</th>
									<th>pcs</th>
									<th>kg</th>
								</tr>

								{utnMap[utnGroup][utensil_display].map((obj, utnIndex) => {
									kalwat_pcs_total += obj.kalwat_cumulative[0];
									kalwat_kg_total += obj.kalwat_cumulative[1];
									polish_pcs_total += obj.polish_cumulative[0];
									polish_kg_total += obj.polish_cumulative[1];

									return (
										<tr key={utnIndex}>
											<td>{utnIndex + 1}</td>
											<td>{obj.utn_id__size}</td>
											<td>{utils.DisplayDecimal2Places(obj.kalwat_cumulative[0])}</td>
											<td>{utils.DisplayDecimal2Places(obj.kalwat_cumulative[1])}</td>
											<td>{utils.DisplayDecimal2Places(obj.polish_cumulative[0])}</td>
											<td>{utils.DisplayDecimal2Places(obj.polish_cumulative[1])}</td>
										</tr>
									);
								})}
								<tr>
									<th></th>
									<th>Total</th>
									<th>{utils.DisplayDecimal2Places(kalwat_pcs_total)} pcs</th>
									<th>{utils.DisplayDecimal2Places(utils.ConvertKgToMt(kalwat_kg_total))} MT</th>
									<th>{utils.DisplayDecimal2Places(polish_pcs_total)} pcs</th>
									<th>{utils.DisplayDecimal2Places(utils.ConvertKgToMt(polish_kg_total))} MT</th>
								</tr>
							</tbody>
						);
					})}
				</Table>
			);
		};

		let rows = [];
		for (let i = 0; i < utnGroup.length; i += 2) {
			rows.push(
				<Row>
					<Col sm={6}>{displayIndividualEntityStock(utnGroup[i], utnData)}</Col>
					<Col sm={6}>
						{/* Not displaying second column if number of elements is odd */}
						{i + 1 < utnGroup.length ? displayIndividualEntityStock(utnGroup[i + 1], utnData) : ""}
					</Col>
				</Row>
			);
		}
		return <Container>{rows}</Container>;
	};

	const submitForm = (values, { setSubmitting }) => {
		setSubmitting(true);
		try {
			// When button submits form and form is in the process of submitting, submit button is disabled
			var castValues = validationSchema.cast(values);
			if (Date.parse(castValues["start"]) > Date.parse(castValues["end"])) {
				throw new Error(`End date should be greater than start date.`);
			}
		} catch (err) {
			console.log("Error in parsing input data : ", err.message);
			setSubmitAlertVariant("danger");
			setSubmitAlertMessage(err.message);
			setSubmitting(false);
			return;
		}

		api.Get(
			`/api/v1/gsl/utensil/inventory/utensil/production/cumulative`,
			castValues,
			userDetails[constants.JWT_TOKEN_KEY],
			(res) => {
				console.log("Received cumulative production : ", res);
				setSubmitAlertVariant("success");
				setDayProductionList(res.data);
				setSubmitting(false);
			},
			(error) => {
				console.log("Error in fetching day production report", error);
				setSubmitAlertVariant("danger");
				setSubmitAlertMessage("Error in fetching day production report -> " + utils.GetErrorMessage(error));
				setSubmitting(false);
			}
		);
	};

	var component = (
		<Can
			role={userDetails[constants.ROLE]}
			perform="gsl:utensil:inventory:production:cumulative:view"
			no={() => <UnAuthorizedComponent />}
			yes={() => (
				<Container>
					<h2 className="appNameHeaderTitle">
						VIEW CUMULATIVE UTENSIL PRODUCTION
						<br />
						<br />
					</h2>
					<Formik
						validationSchema={validationSchema}
						initialValues={{
							start_date: utils.GetTodayDate(),
							end_date: utils.GetTodayDate(),
						}}
						onSubmit={submitForm}
					>
						{({ handleSubmit, handleChange, values, touched, isSubmitting, errors }) => (
							<Form
								noValidate
								onSubmit={handleSubmit}
								onChange={(e) => {
									handleChange(e);
									setSubmitAlertMessage("");
								}}
							>
								<Form.Group as={Row} controlId="utnStock">
									<Col sm={6}>
										<Row>
											<Form.Label column sm={4} className="inputFormLabel">
												Start-Date&nbsp;:
											</Form.Label>
											<Col sm={6}>
												<FormTextInput
													type="date"
													name="start_date"
													values={values}
													handleChange={handleChange}
													touched={touched}
													errors={errors}
												/>
											</Col>
										</Row>
									</Col>
									<Col sm={6}>
										<Row>
											<Form.Label column sm={4} className="inputFormLabel">
												End-Date&nbsp;:
											</Form.Label>
											<Col sm={6}>
												<FormTextInput
													type="date"
													name="end_date"
													values={values}
													handleChange={handleChange}
													touched={touched}
													errors={errors}
												/>
											</Col>
										</Row>
									</Col>
								</Form.Group>
								<Container>
									<Row>
										<Col>
											<SubmitButton isSubmitting={isSubmitting} />
										</Col>
									</Row>
									<Row>
										<Col>&nbsp;</Col>
									</Row>
									<Row>
										<Col>
											<SubmitMessage submitMessage={submitAlertMessage} submitVariant={submitAlertVariant} />
										</Col>
									</Row>
								</Container>
							</Form>
						)}
					</Formik>
					{dayProductionList.length > 0 ? (
						displayDayProduction(dayProductionList)
					) : (
						<Alert variant="success">No Production Report found.</Alert>
					)}
				</Container>
			)}
		/>
	);

	return (
		<Fragment>
			<div className="container align-self-center mt-5">
				<Row>
					<Col sm={1}></Col>
					<Col sm={10}>{component}</Col>
					<Col sm={1}></Col>
				</Row>
			</div>
		</Fragment>
	);
}
