module.exports = {
    UNIT_KEY: "uom",
    UTENSILS_GROUPS_DROPDOWN: [
        "Pawali",
        "Balti",
        "Khomcha",
        "UP Tasla",
        "Parat",
        "Tope",
        "Spoon",
        "Circle",
    ],
    UTENSILS_SIZES_VARIANTS: {
        // Pawali + Ration Dabba Farma
        'Pawali': {
            "uom": "pcs",
            "12": {
                "dhanlaxmi": 1,
                "payal": 2,
                "payal+mohar": 3,
                "mrchef": 4
            },
            "14": {
                "dhanlaxmi": 5,
                "payal": 6,
            }
        },
        'Fatka Balti': {
            "uom": "pcs",
            "16": {
                "mrchef": 9
            },
            "18": {
                "mrchef": 10
            }
        },
        'Khomcha': {
            "uom": "kg",
            "12": {
                "dhanlaxmi": 9
            },
            "13": {
                "dhanlaxmi": 10
            }
        },
    },
    GRADE_INDEX: 0,
    POINT_INDEX: 1,
    WIDTH_INDEX: 2,
    UTN_PARTY_DROPDOWN: [
        {
            name: "Maa Vaishno",
            place: "lucknow"
        }
    ]
}