import qs from "qs";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Alert, Table } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import Can from "../../../../../components/can";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";

function FetchOrderDetails(orderIDString, setOrdersList, userToken, setPageLoadErrorMessage, setHasLoadingError, setIsPageLoading) {
	try {
		setIsPageLoading(true);

		if (orderIDString == null || orderIDString === "") {
			throw new Error("Order ID is missing.");
		}

		let ordID = orderIDString.split(",");
		if (ordID.length == 0) {
			throw new Error("Order ID is missing.");
		}
		console.log("sending ord ID : ", ordID);
		let params = ordID.toString();

		return api.Get(
			`/api/v1/gsl/utensil/orders/order/details?ids=[${params}]`,
			{},
			userToken,
			(res) => {
				console.log("received orders details : ", res);
				let ordDetails = [];
				if (res.data == null) {
					throw new Error("Order Details not found.");
				} else {
					ordDetails = res.data;
				}
				// converting list of sizes into
				// <brand>:
				//      <sizes>: obj
				//      ...
				ordDetails.forEach(function (ord, index) {
					let utnSizes = {};
					ord.sizes.forEach(function (utn) {
						if (utn["brand_display"] in utnSizes) {
							if (utn["utensil_display"] in utnSizes[utn["brand_display"]]) {
								utnSizes[utn["brand_display"]][utn["utensil_display"]].push(utn);
							} else {
								utnSizes[utn["brand_display"]][utn["utensil_display"]] = [utn];
							}
						} else {
							utnSizes[utn["brand_display"]] = {};
							utnSizes[utn["brand_display"]][utn["utensil_display"]] = [utn];
						}
					});
					ordDetails[index].sizes = utnSizes;
				});
				setOrdersList(ordDetails);
				setPageLoadErrorMessage("");
				setHasLoadingError(false);
				setIsPageLoading(false);
			},
			(error) => {
				console.log("Error in fetching order details", error);
				setPageLoadErrorMessage(utils.GetErrorMessage(error));
				setHasLoadingError(true);
				setIsPageLoading(false);
			}
		);
	} catch (error) {
		console.log("Error in fetching order details", error);
		setPageLoadErrorMessage(utils.GetErrorMessage(error));
		setHasLoadingError(true);
		setIsPageLoading(false);
	}
}

export function DisplayOrdersDetails(orders) {
	const displayBrandSizes = (utn, isLastBrand) => {
		let nameRow = [];
		let sizesRow = [];
		let qtyRow = [];
		let totQtyRow = [];
		Object.keys(utn).forEach(function (utnName, outerIndex) {
			let numRows = utn[utnName].length;
			nameRow.push(
				<td key={`0-${outerIndex}`} colSpan={numRows} style={{ textAlign: "center" }}>
					<strong>{utils.ToTitleCase(utnName)}</strong>
				</td>
			);
			let totQty = 0.0;
			utn[utnName].forEach(function (obj, index) {
				sizesRow.push(<td key={`1-${outerIndex}-${index}`}>{utils.ToTitleCase(obj.size)}</td>);
				qtyRow.push(<td key={`2-${outerIndex}-${index}`}>{obj.qty} KG</td>);
				totQty += obj.qty;
			});
			totQtyRow.push(
				<td key={`3-${outerIndex}`} colSpan={numRows}>
					<strong>{totQty} KG</strong>
				</td>
			);
		});

		return (
			<Table bordered size="sm" className="orderTable" variant="link">
				<thead>
					<tr
						style={{
							borderLeft: "2px solid black",
							borderRight: "2px solid black",
						}}
					>
						{nameRow}
					</tr>
					<tr
						style={{
							borderLeft: "2px solid black",
							borderRight: "2px solid black",
						}}
					>
						{sizesRow}
					</tr>
					<tr
						style={{
							borderLeft: "2px solid black",
							borderRight: "2px solid black",
						}}
					>
						{qtyRow}
					</tr>
					<tr
						style={{
							borderLeft: "2px solid black",
							borderRight: "2px solid black",
							borderBottom: isLastBrand == true ? "2px solid black" : "0px solid black",
						}}
					>
						{totQtyRow}
					</tr>
				</thead>
			</Table>
		);
	};

	let rows = [];
	orders.forEach(function (order, outerIndex) {
		let sizesRows = [];
		let objLength = Object.keys(order.sizes).length;
		Object.keys(order.sizes).forEach(function (brand, index) {
			console.log("brand : ", brand);
			let bgColor = brand == "Mr. Chef" ? "#EE9922" : brand == "Payal" ? "#F8E01A" : brand == "Dhanlakshmi" ? "#7AC602" : "white";

			sizesRows.push(
				<Fragment key={`${outerIndex}-${index}`}>
					{/* <img src="/static/dhanlaxmi-logo.jpg" className="utnLogoBackgroundImage" /> */}

					<Table bordered size="sm" className="orderTable" variant="link">
						<thead>
							<tr
								style={{
									borderLeft: "2px solid black",
									borderRight: "2px solid black",
								}}
							>
								<td colSpan={2} style={{ backgroundColor: bgColor, color: "black" }}>
									<strong>{utils.ToTitleCase(brand)}</strong>
								</td>
							</tr>
						</thead>
					</Table>
					{displayBrandSizes(order.sizes[brand], index == objLength - 1)}
				</Fragment>
			);
		});
		rows.push(
			<Container key={`${outerIndex}`}>
				<br />
				<Table bordered size="sm" className="orderTable" variant="link">
					<thead>
						<tr
							style={{
								color: "#f13c20",
								background: "#c5cbe3",
							}}
						>
							<td
								style={{
									border: "2px solid black",
								}}
							>
								<h4>
									Order #{order.serial}{" "}
									{objLength == 0 ? (
										"(Without Size)"
									) : order.dsp_qty > 0.0 ? (
										<span
											style={{
												color: order.dsp_qty > 0.0 ? "green" : "",
											}}
										>
											(Dispatched on {utils.FormatDisplayDate(order.dsp_date)})
										</span>
									) : (
										""
									)}
									<br />
								</h4>
							</td>
						</tr>
					</thead>
				</Table>
				<Table bordered size="sm" className="orderTable" variant="link">
					<thead>
						<tr
							style={{
								borderLeft: "2px solid black",
								borderRight: "2px solid black",
							}}
						>
							<td style={{ width: "50%" }}>Order Date : {utils.FormatDisplayDate(order.ord_date)}</td>
							<td style={{ width: "50%" }}>
								{order.dsp_qty > 0.0 ? `Remark: ${order.remark}` : `Due Date : ${utils.FormatDisplayDate(order.due_date)}`}
							</td>
						</tr>
					</thead>
				</Table>
				<Table bordered size="sm" className="orderTable" variant="link">
					<thead>
						<tr
							style={{
								borderLeft: "2px solid black",
								borderRight: "2px solid black",
							}}
						>
							<td style={{ width: "50%" }}>
								<strong style={{ fontSize: "125%" }}>{utils.ToTitleCase(`${order.party["name"]}, ${order.party["place"]}`)}</strong>
							</td>
							<td style={{ width: "50%" }}>Th: {utils.ToTitleCase(order.party["through_display"])}</td>
						</tr>
					</thead>
				</Table>
				<Table bordered size="sm" className="orderTable" variant="link">
					<thead>
						<tr
							style={{
								borderLeft: "2px solid black",
								borderRight: "2px solid black",
								borderBottom: objLength == 0 ? "2px solid black" : "0px solid black",
							}}
						>
							<td style={{ width: "50%" }}>
								Total Order Qty: <strong>{objLength == 0 ? order.without_size_qty : order.actual_qty} KG</strong>
							</td>
							<td style={{ width: "50%" }}>
								{order.dsp_qty > 0.0 ? (
									<span>
										Total Dispatched Qty: <strong>{order.dsp_qty} KG</strong>
									</span>
								) : (
									`Remark: ${order.remark}`
								)}
							</td>
						</tr>
					</thead>
				</Table>
				<Table bordered size="sm" className="orderTable" variant="link">
					<thead>
						<tr>
							<td
								style={{
									border: "2px solid black",
								}}
							>
								<span>Party Order Sizes</span>
							</td>
						</tr>
					</thead>
				</Table>
				{sizesRows}
				{Object.keys(order.dsp_sizes).length > 0 && DisplayDispatchSizes(order.dsp_sizes)}
				<br />
				<div className="divider py-1 bg-dark"></div>
				<br />
			</Container>
		);
	});
	return rows;
}

function DisplayDispatchSizes(dispatchSizes) {
	let utnNameColumns = [];
	let utnSizeColumns = [];
	let utnQtyColumns = [];

	// function creates individual tables for different utensils
	const displayUtnSizeQty = (index, utnName, utnObj) => {
		console.log("creating table for ", utnName);

		let sizesLength = utnObj["detail"].length;
		utnObj["detail"].map((sizeQtyObj, innerIndex) => {
			utnSizeColumns.push(
				<th
					key={`0-${index}-${innerIndex}`}
					style={{
						borderLeft: innerIndex == 0 ? "2px solid black" : "",
						borderRight: innerIndex == sizesLength - 1 ? "2px solid black" : "",
						fontWeight: 600,
					}}
				>
					{sizeQtyObj["size"]}
				</th>
			);
			utnQtyColumns.push(
				<th
					key={`0-${index}-${innerIndex}`}
					style={{
						borderLeft: innerIndex == 0 ? "2px solid black" : "",
						borderRight: innerIndex == sizesLength - 1 ? "2px solid black" : "",
						borderBottom: "2px solid black",
						fontWeight: "normal",
					}}
				>
					{sizeQtyObj["dsp_qty"]} {utnObj["uom"]}
				</th>
			);
		});
		utnNameColumns.push(
			<th
				key={index}
				colSpan={sizesLength}
				style={{
					color: "#05386b",
					background: "#5cdb95",
					border: "2px solid black",
				}}
			>
				{utils.ToTitleCase(utnName)}
			</th>
		);
	};

	dispatchSizes &&
		Object.keys(dispatchSizes).map((key, index) => {
			displayUtnSizeQty(index, key, dispatchSizes[key]);
		});

	return [
		<Table bordered size="sm" className="orderTable" variant="link" key="0">
			<thead>
				<tr>
					<td
						style={{
							borderLeft: "2px solid black",
							borderRight: "2px solid black",
						}}
					>
						<span>Dispatched Sizes</span>
					</td>
				</tr>
			</thead>
		</Table>,
		<Table responsive bordered size="sm" variant="link" key="1">
			<thead>
				<tr>{utnNameColumns}</tr>
			</thead>
			<tbody>
				<tr>{utnSizeColumns}</tr>
				<tr>{utnQtyColumns}</tr>
			</tbody>
		</Table>,
	];
}

export function ViewOrderDetails(props) {
	const [isPageLoading, setIsPageLoading] = useState(true);
	const [hasLoadingError, setHasLoadingError] = useState(false);
	const [pageLoadErrorMessage, setPageLoadErrorMessage] = useState("");

	const [ordersList, setOrdersList] = useState([]);

	useEffect(() => {
		const orderIds = qs.parse(props.location.search, { ignoreQueryPrefix: true }).ids;
		FetchOrderDetails(
			orderIds,
			setOrdersList,
			userDetails[constants.JWT_TOKEN_KEY],
			setPageLoadErrorMessage,
			setHasLoadingError,
			setIsPageLoading
		);
	}, []);

	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	var component = utils.PageLoadComponents(hasLoadingError ? "Error in fetching order details -> " + pageLoadErrorMessage : "", isPageLoading);
	if (component == null) {
		component = (
			<Can
				role={userDetails[constants.ROLE]}
				perform="gsl:utensil:orders:order:individual:view"
				no={() => <UnAuthorizedComponent />}
				yes={() => (
					<Container>
						<h2 className="appNameHeaderTitle">
							VIEW ORDER DETAILS
							<br />
							<br />
						</h2>
						{ordersList.length > 0 ? DisplayOrdersDetails(ordersList) : <Alert variant="success">No Order Details Found.</Alert>}
					</Container>
				)}
			/>
		);
	}
	return (
		<Fragment>
			<div className="container-fluid align-self-center mt-5">
				<Row>
					<Col sm={12}>{component}</Col>
				</Row>
			</div>
		</Fragment>
	);
}

ViewOrderDetails.propTypes = {
	location: PropTypes.object,
	search: PropTypes.string,
};
