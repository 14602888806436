import React, { Fragment } from "react";
import { Button } from "react-bootstrap";

import "../../../../../public/custom.css";
import * as api from "../../../../../utils/api";
``;
import * as utils from "../../../../../utils/utils";

// Function performs network API call to fetch order list for party
export function APIFetchPendingOrderListFromPartyName(party, userAuthToken, setPartyOrderNoList, setSubmitting, setSubmitAlertVariant, setSubmitAlertMessage) {
    console.log(`API fetching pending order list for '${party}'.`);
    if (party == null && party == "") {
        return [];
    }
    setSubmitting(true);

    return api.Get(
        `/api/v1/gsl/utensil/orders/${party}/orders/pending/serial`,
        {},
        userAuthToken,
        (res) => {
            console.log("Received res : ", res);
            if ("data" in res) {
                if (Object.prototype.toString.call(res.data) === "[object Array]") {
                    setPartyOrderNoList(res.data);
                } else if ("status" in res.data && res.data.status == "no order found") {
                    setPartyOrderNoList([]);
                } else {
                    throw new Error("Received invalid order list from server for party.");
                }
            } else {
                throw new Error("Received invalid order list from server for party.");
            }

            setSubmitAlertVariant("success");
            setSubmitAlertMessage("");
            setSubmitting(false);
        },
        (error) => {
            console.log(`Error in fetching order list for party '${party}' : `, error);
            setPartyOrderNoList([]);
            setSubmitAlertVariant("danger");
            setSubmitAlertMessage(`Error in fetching order list for ${party} : ${utils.GetErrorMessage(error)}`);
            setSubmitting(false);
        }
    );
}

function resetDataFields(selectedOrderID, party, values, childRef, setOrderEntity) {
    setOrderEntity({
        id: null,
        ord_date: values["ord_date"],
        due_date: values["due_date"],
        party: party,
        serial: selectedOrderID,
        without_size: false,
        without_size_qty: "",
        remark: "",
        sizes: [],
    });
    // manually resetting without_size to false
    if (childRef.current.checked) {
        childRef.current.click();
    }
}
/* values : 
        date: "",
        due_date: "",
        partyID: "",
        orderID: "",
        without_size: false,
        new_order_no: "",
        ws_qty: "",
        remark: "",
        sizes: [],
 */
export function FetchOrderDetails(selectedOrderID, partyOrderNoList, values, childRef, setOrderEntity, setFieldArrayRowID) {
    console.log(`checking if order ${selectedOrderID} is valid for party ${values["party"]} : `, values);

    let party = values["party"];
    if (party == null || selectedOrderID == null || party == "" || selectedOrderID == "") {
        resetDataFields(selectedOrderID, party, values, childRef, setOrderEntity);
        return;
    }

    let listLen = partyOrderNoList.length;

    for (let i = 0; i < listLen; i++) {
        let order = partyOrderNoList[i];
        if (`${order["serial"]} | ${utils.FormatDisplayDate(order["ord_date"])}` == selectedOrderID) {
            let without_size = order["sizes"].length == 0 ? true : false;
            // manually triggering checkbox event
            if (childRef.current.checked != without_size) {
                console.log("manually triggering without size check box");
                childRef.current.click();
            }

            let maxID = -1;

            order["sizes"].forEach((sz) => {
                sz["name"] = sz["utensil"].toString();
                maxID = Math.max(maxID, sz["id"]);
            });

            let orderEntity = {
                id: order["id"],
                ord_date: order["ord_date"],
                due_date: order["due_date"],
                party: order["party"]["id"],
                serial: order["serial"],
                without_size: without_size,
                without_size_qty: order["without_size_qty"] == 0 || order["without_size_qty"] == null ? "" : order["without_size_qty"],
                remark: order["remark"],
                sizes: order["sizes"],
            };
            setOrderEntity(orderEntity);
            setFieldArrayRowID(maxID + 1);
            return;
        }
    }

    // resetting all input fields to default if new order.
    resetDataFields(selectedOrderID, party, values, childRef, setOrderEntity);
}

//TODO handle case of same utensil qty entered twice
export function SubmitNewUtnOrder(
    values,
    partyOrderNoList,
    validationSchema,
    userAuthToken,
    setSubmitting,
    setSubmitAlertVariant,
    setSubmitAlertMessage,
    setOrderEntity,
    resetForm,
    setFormResetKey
) {
    // When button submits form and form is in the process of submitting, submit button is disabled
    setSubmitting(true);
    console.log("received utn order for submission : ", values);
    try {
        var castValues = validationSchema.cast(values);
        // validation logic for without size order
        if (castValues.without_size == true) {
            let ws_qty = +castValues["without_size_qty"]; //converting string to number
            if (castValues["without_size_qty"] == null || castValues["without_size_qty"] == "" || isNaN(ws_qty) || ws_qty <= 0) {
                throw new Error("Without Size Quantity should be a Valid Positive Number.");
            }
            if (castValues["sizes"].length > 0) {
                throw new Error("You have selected without-size but have also entered some utensil sizes. Please change either one.");
            }
        } else {
            // validation logic for with size order
            if (castValues["sizes"].length == 0) {
                throw new Error("You have selected with-size but have not entered any utensil sizes.");
            }
        }

        // validation for due date > order date
        if (castValues["due_date"] != null && castValues["due_date"] != "" && castValues["due_date"] < castValues.ord_date) {
            throw new Error("Due Date cannot be less than order date.");
        }

        // react-select input gives us 70 | 01/06/21
        // extracting serial number to send in backend
        const fetchSerialFromSelectedSerial = (input) => {
            let listLen = partyOrderNoList.length;
            for (let i = 0; i < listLen; i++) {
                let order = partyOrderNoList[i];
                if (input == `${order["serial"]} | ${utils.FormatDisplayDate(order["ord_date"])}`) {
                    return order["serial"];
                }
            }
            return input;
        };

        var submittedValues = {
            id: castValues["id"],
            ord_date: castValues["ord_date"],
            due_date: castValues["due_date"] == null || castValues["due_date"] == "" ? null : castValues["due_date"],
            party: castValues["party"],
            serial: fetchSerialFromSelectedSerial(castValues["serial"]),
            without_size_qty: castValues["without_size_qty"] == null || castValues["without_size_qty"] == "" ? null : castValues["without_size_qty"],
            remark: castValues["remark"],
            sizes: castValues["sizes"],
        };

        let combinedUtnSizesWithQty = [];
        let combinedSizesKeyDict = {}; // This saves a dictionary of <utn_name-brand-size> : index of utn in combinedUtnSizesWithQty

        submittedValues["sizes"].forEach((sz) => {
            let key = `${sz["name"]}-${sz["brand"]}-${sz["size"]}`;
            sz["utensil"] = sz["name"];
            if (key in combinedSizesKeyDict) {
                // same utensil is encountered -> add the quantities
                combinedUtnSizesWithQty[combinedSizesKeyDict[key]].qty += sz["qty"];
            } else {
                // new distinct utensil is encountered
                combinedUtnSizesWithQty.push(sz);
                combinedSizesKeyDict[key] = combinedUtnSizesWithQty.length - 1;
            }
        });
        submittedValues["sizes"] = combinedUtnSizesWithQty;

        console.log("final submitting utn order : ", submittedValues);

        const resetEntities = () => {
            resetForm();
            setFormResetKey(Date.now());
            setOrderEntity({
                id: null,
                ord_date: "",
                due_date: "",
                party: "",
                serial: "",
                without_size: false,
                without_size_qty: "",
                remark: "",
                sizes: [],
            });
            setSubmitting(false);
        };

        // logic for new order
        if (castValues["id"] == null || castValues["id"] == "") {
            return api.Post(
                `/api/v1/gsl/utensil/orders/order/`,
                submittedValues,
                userAuthToken,
                () => {
                    setSubmitAlertVariant("success");
                    setSubmitAlertMessage(
                        <Fragment>
                            <strong>Successfully Added New Order.</strong>
                            <br></br>
                            <br></br>
                            <Button variant="warning" href="/gsl/utensil/orders/order/pending/view">
                                View Pending Orders.
                            </Button>
                        </Fragment>
                    );
                    resetEntities();
                },
                (error) => {
                    console.log(`Error in adding new order : `, error);
                    setSubmitAlertVariant("danger");
                    setSubmitAlertMessage(`Error in adding new order : ${utils.GetErrorMessage(error)}`);
                    setSubmitting(false);
                }
            );
        } else {
            return api.Put(
                `/api/v1/gsl/utensil/orders/order/${castValues["id"]}/`,
                submittedValues,
                userAuthToken,
                () => {
                    setSubmitAlertVariant("success");
                    setSubmitAlertMessage(
                        <Fragment>
                            <strong>Successfully Updated Existing Order.</strong>
                            <br></br>
                            <br></br>
                            <Button variant="warning" href="/gsl/utensil/orders/order/pending/view">
                                View Pending Orders.
                            </Button>
                        </Fragment>
                    );
                    resetEntities();
                },
                (error) => {
                    console.log(`Error in updating existing order : `, error);
                    setSubmitAlertVariant("danger");
                    setSubmitAlertMessage(`Error in updating existing order : ${utils.GetErrorMessage(error)}`);
                    setSubmitting(false);
                }
            );
        }
    } catch (error) {
        console.log(`Error in processing new/update order : `, error);
        setSubmitAlertVariant("danger");
        setSubmitAlertMessage(`Error in  processing new/update order : ${utils.GetErrorMessage(error)}`);
        setSubmitting(false);
    }
}
