import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Table, Alert, Button } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import AppConstants from "../constants";
import Can from "../../../../../components/can";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";

export default function ViewDispatchedLorrySizes() {
    //this key is to reset SelectDropdown on form submit
    const [isLoading, setIsLoading] = useState(true);
    const [hasLoadingError, setHasLoadingError] = useState(false);
    const [pageLoadErrorMessage, setPageLoadErrorMessage] = useState("");
    const [nextListLink, setNextListLink] = useState("");
    const [totalListCount, setTotalListCount] = useState(0);

    /* 
        dispatchedLorrySizesList = [
        {
            "id": 1,
            "sizes": {
                "R8 60 17": 200
            },
            "date": "2021-01-08"
        }
    ]
    */
    const [dispatchedLorrySizesList, setDispatchedLorrySizesList] = useState([]);

    useEffect(() => {
        fetchDispatchedLorrySizesList(`/api/v1/bsil/patta/gsl-utn-patta-sizes/lorry/dispatch/sizes`);
    }, []);

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    const fetchDispatchedLorrySizesList = (URL) => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        return api.Get(
            URL,
            {},
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                console.log("res : ", res);
                setDispatchedLorrySizesList(dispatchedLorrySizesList.concat(res.data.results));
                setNextListLink(res.data.next);
                setTotalListCount(res.data.count);
                setIsLoading(false);
                setHasLoadingError(false);
            },
            (error) => {
                console.log("Error in fetching Dispatched Lorry List", error);
                setPageLoadErrorMessage(utils.GetErrorMessage(error));
                setIsLoading(false);
                setHasLoadingError(true);
            }
        );
    };

    const displayDispatchedLorrySizesList = (lorry, index) => {
        var totalQty = 0.0;
        return (
            <Row key={index}>
                <Col sm={2}></Col>
                <Col sm={8}>
                    <Table
                        responsive
                        bordered
                        hover
                        size="sm"
                        variant="warning"
                        style={{
                            border: "1.75px solid black",
                        }}
                    >
                        <thead>
                            <tr>
                                <th></th>
                                <th>{utils.FormatDisplayDate(lorry.date)}</th>
                                <th colSpan={5}></th>
                            </tr>
                            <tr>
                                <th>#</th>
                                <th>Grade</th>
                                <th>Width</th>
                                <th>Point</th>
                                <th>Qty (kg)</th>
                                <th>Length</th>
                                <th>Section</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(lorry.sizes).map((key, i) => {
                                totalQty += lorry.sizes[key];
                                return (
                                    <tr key={index + "-" + i}>
                                        <td>{i + 1}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.GRADE_INDEX]}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.WIDTH_INDEX]}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.POINT_INDEX]}</td>
                                        <td>{utils.DisplayDecimal2Places(lorry.sizes[key])}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.LENGTH_INDEX]}</td>
                                        <td>{AppConstants.PATTA_SIZES_VARIANTS[key][AppConstants.SECTION_INDEX]}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <th colSpan={3}></th>
                                <th>Total</th>
                                <th>{totalQty}</th>
                                <th colSpan={2}></th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        );
    };

    const loadMoreEntries = () => {
        let nextListURL = new URL(nextListLink);
        fetchDispatchedLorrySizesList(nextListURL.pathname + nextListURL.search);
    };

    var component = utils.PageLoadComponents(hasLoadingError ? "Error in fetching dispatched lorry details -> " + pageLoadErrorMessage : "", isLoading);
    if (component == null) {
        component =
            dispatchedLorrySizesList.length == 0 ? (
                <Alert variant="success"> NO DISPATCHED LORRIES</Alert>
            ) : (
                <Can
                    role={userDetails[constants.ROLE]}
                    perform="bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:view"
                    no={() => <UnAuthorizedComponent />}
                    yes={() => (
                        <Container>
                            <h2 className="appNameHeaderTitle">
                                VIEW DISPATCHED LORRIES
                                <br />
                            </h2>
                            <Row>
                                <Col>{dispatchedLorrySizesList.map(displayDispatchedLorrySizesList)}</Col>
                            </Row>
                            <Row>
                                <Col sm={4}></Col>
                                <Col sm={4}>
                                    Showing {dispatchedLorrySizesList.length} of {totalListCount}
                                    <br />
                                    {nextListLink ? (
                                        <Button variant="warning" onClick={loadMoreEntries}>
                                            Load More
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </Col>
                                <Col sm={4}></Col>
                            </Row>
                        </Container>
                    )}
                />
            );
    }
    return (
        <Fragment>
            <div className="container align-self-center mt-5">
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={10}>{component}</Col>
                    <Col sm={1}></Col>
                </Row>
            </div>
        </Fragment>
    );
}
