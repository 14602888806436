import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Form, Container } from "react-bootstrap";

import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import * as inventoryUtils from "../utils";
import Can from "../../../../../components/can";
import yup from "../../../../../components/yup";
import constants from "../../../../../constants/constants";
import UnAuthorizedComponent from "../../../../../components/403";
import NewUtnOrderSizes from "./newUtnOrderSizes";
import * as SubmitNewUtnOrder from "./submitNewUtnOrder";
import { SelectDropdown, CreatableSelectDropdown } from "../../../../../components/selectDropdown";

import { SubmitButton, SubmitMessage } from "../../../../../components/submitComponents";
import { FormTextInput, FormCheckboxRef } from "../../../../../components/inputFieldComponents";

export default function NewUtensilOrder() {
    //this key is to reset SelectDropdown on form submit
    const [fieldArrayRowID, setFieldArrayRowID] = useState(0);
    const [formResetKey, setFormResetKey] = useState("");
    const [submitAlertVariant, setSubmitAlertVariant] = useState("success");
    const [submitAlertMessage, setSubmitAlertMessage] = useState("");

    const withoutSizeRef = useRef();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [loadingErrorDetails, setLoadingErrorDetails] = useState([]);

    const [selectedOrderEntity, setOrderEntity] = useState({
        id: null,
        ord_date: "",
        due_date: "",
        party: "",
        serial: "",
        without_size: false,
        without_size_qty: "",
        remark: "",
        sizes: [],
    });
    const [utnPartyDropdown, setUtnPartyDropdown] = useState([]);
    const [partyOrderNoList, setPartyOrderNoList] = useState([]);
    const [utnDetailsDropdown, setUtnDetailsDropdown] = useState([]);
    const [utnBrandDropdown, setUtnBrandDropdown] = useState([]);

    //fetching the list of utensil variant dropdown
    useEffect(() => {
        const partyList = utils.FetchDropdownList(
            "/api/v1/gsl/utensil/orders/party/dropdown",
            userDetails[constants.JWT_TOKEN_KEY],
            {},
            [],
            setUtnPartyDropdown,
            setLoadingErrorDetails
        );
        const utensilList = utils.FetchDropdownList(
            "/api/v1/gsl/utensil/orders/utensil/dropdown",
            userDetails[constants.JWT_TOKEN_KEY],
            {},
            [],
            setUtnDetailsDropdown,
            setLoadingErrorDetails
        );
        const brandList = utils.FetchDropdownList(
            "/api/v1/gsl/utensil/orders/utensil/brands/dropdown",
            userDetails[constants.JWT_TOKEN_KEY],
            {},
            [],
            setUtnBrandDropdown,
            setLoadingErrorDetails
        );
        Promise.all([partyList, utensilList, brandList]).then(() => {
            setIsPageLoading(false);
        });
    }, []);

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    // this function is called by child component to increment field array row ID state
    const getAndIncrementFieldArrayRowID = () => {
        setFieldArrayRowID(fieldArrayRowID + 1);
        return fieldArrayRowID + 1;
    };

    const validationSchema = yup
        .object()
        .nullable()
        .required()
        .shape({
            // id: yup.string(),

            party: yup.string().nullable().required("Party Name is required."),

            // serial: yup.string(),

            ord_date: yup.string().required("Order Date is Required."),

            //   due_date: yup.string().nullable(),

            // remark: yup.string().nullable(),

            // without_size_qty: yup.number().nullable(),

            // without_size: yup.bool(),

            sizes: yup.array().of(
                yup
                    .object()
                    .required()
                    .shape({
                        name: yup.string().nullable().required("Utensil Name is required."),
                        brand: yup.string().nullable().required("Utensil Brand is required."),
                        size: yup.string().nullable().required("Utensil Size is required."),
                        qty: yup
                            .number()
                            .typeError("Quantity must be a number.")
                            .required("Quantity is required.")
                            .positive("Quantity must be greater than zero."),
                    })
            ),
        });

    const submitForm = (values, { setSubmitting, resetForm }) => {
        SubmitNewUtnOrder.SubmitNewUtnOrder(
            values,
            partyOrderNoList,
            validationSchema,
            userDetails[constants.JWT_TOKEN_KEY],
            setSubmitting,
            setSubmitAlertVariant,
            setSubmitAlertMessage,
            setOrderEntity,
            resetForm,
            setFormResetKey
        );
    };

    const [hasLoadingError, pageLoadErrorMessage] = utils.checkPageLoadErrorArray(loadingErrorDetails);

    var component = utils.PageLoadComponents(hasLoadingError ? "Error in fetching dropdown list -> " + pageLoadErrorMessage : "", isPageLoading);
    if (component == null) {
        component = (
            <Can
                role={userDetails[constants.ROLE]}
                perform="gsl:utensil:orders:party:new"
                no={() => <UnAuthorizedComponent />}
                yes={() => (
                    <Formik
                        enableReinitialize
                        validationSchema={validationSchema}
                        initialValues={{
                            id: selectedOrderEntity["id"],
                            ord_date: selectedOrderEntity["ord_date"],
                            due_date: selectedOrderEntity["due_date"],
                            party: selectedOrderEntity["party"],
                            serial: selectedOrderEntity["serial"],
                            without_size: selectedOrderEntity["without_size"],
                            without_size_qty: selectedOrderEntity["without_size_qty"],
                            remark: selectedOrderEntity["remark"],
                            sizes: selectedOrderEntity["sizes"],
                        }}
                        onSubmit={submitForm}
                    >
                        {({ handleSubmit, handleChange, setFieldValue, setFieldTouched, values, touched, isSubmitting, setSubmitting, errors }) => (
                            <Form
                                noValidate
                                //this key is to reset SelectDropdown on form submit
                                key={formResetKey}
                                onSubmit={handleSubmit}
                                onKeyDown={(keyEvent) => {
                                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                        keyEvent.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    handleChange(e);
                                    setSubmitAlertMessage("");
                                }}
                            >
                                <h2 className="appNameHeaderTitle">
                                    ADD/UPDATE ORDER
                                    <br />
                                    <br />
                                </h2>

                                <Form.Group as={Row} controlId="party">
                                    <Col sm={2}></Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Form.Label column sm={3} className="inputFormLabel">
                                                Party&nbsp;:
                                            </Form.Label>
                                            <Col sm={8}>
                                                <SelectDropdown
                                                    name="party"
                                                    value={values["party"]}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    placeholder="Party Name..."
                                                    options={inventoryUtils.FetchPartyNameDropdown(utnPartyDropdown)}
                                                    onChangeCallback={(ptyID) => {
                                                        SubmitNewUtnOrder.APIFetchPendingOrderListFromPartyName(
                                                            ptyID,
                                                            userDetails[constants.JWT_TOKEN_KEY],
                                                            setPartyOrderNoList,
                                                            setSubmitting,
                                                            setSubmitAlertVariant,
                                                            setSubmitAlertMessage
                                                        );
                                                        //on selecting different party name , reseting all other values
                                                        setOrderEntity({
                                                            id: null,
                                                            ord_date: values["ord_date"],
                                                            due_date: values["due_date"],
                                                            party: ptyID,
                                                            serial: "",
                                                            without_size: false,
                                                            without_size_qty: "",
                                                            remark: "",
                                                            sizes: [],
                                                        });
                                                        // manually resetting without_size to false
                                                        if (withoutSizeRef.current.checked) {
                                                            withoutSizeRef.current.click();
                                                        }
                                                    }}
                                                    error={errors["party"]}
                                                    touched={touched["party"]}
                                                />
                                            </Col>
                                            <Col sm={1}>
                                                <a href="/gsl/utensil/orders/party/new" target="_blank">
                                                    <FontAwesomeIcon icon="plus-circle" size={"2x"} />
                                                </a>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={3}></Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="serial">
                                    <Col sm={2}></Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Form.Label column sm={3} className="inputFormLabel">
                                                Order&nbsp;No.&nbsp;:
                                            </Form.Label>
                                            <Col sm={5}>
                                                <CreatableSelectDropdown
                                                    key={`order_no_${values["party"]}`}
                                                    name="serial"
                                                    value={values["serial"]}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    placeholder="Order No. ..."
                                                    options={inventoryUtils.FetchOrderNoDropdown(partyOrderNoList)}
                                                    onChangeCallback={(id) => {
                                                        SubmitNewUtnOrder.FetchOrderDetails(
                                                            id,
                                                            partyOrderNoList,
                                                            values,
                                                            withoutSizeRef,
                                                            setOrderEntity,
                                                            setFieldArrayRowID
                                                        );
                                                    }}
                                                    touched={touched["serial"]}
                                                    errors={errors["serial"]}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <FormCheckboxRef
                                                    ref={withoutSizeRef}
                                                    className="without-size-checkbox"
                                                    initialValue={values["without_size"]}
                                                    name="without_size"
                                                    label="Without&nbsp;Size&nbsp;:&nbsp;"
                                                    onChange={setFieldValue}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>

                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={4}>
                                        <Form.Group as={Row} controlId="ord_date">
                                            <Form.Label column sm={4} className="inputFormLabel">
                                                Order Date&nbsp;:
                                            </Form.Label>
                                            <Col sm={8}>
                                                <FormTextInput
                                                    type="date"
                                                    name="ord_date"
                                                    values={values}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group as={Row} controlId="due_date">
                                            <Form.Label column sm={4} className="inputFormLabel">
                                                Due&nbsp;Date&nbsp;:
                                            </Form.Label>
                                            <Col sm={8}>
                                                <FormTextInput
                                                    type="date"
                                                    name="due_date"
                                                    values={values}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={4}>
                                        <Form.Group as={Row} controlId="remark">
                                            <Form.Label column sm={4} className="inputFormLabel">
                                                Remark&nbsp;:
                                            </Form.Label>
                                            <Col sm={8}>
                                                <FormTextInput
                                                    type="input"
                                                    name="remark"
                                                    placeholder="Remark (if any) ..."
                                                    values={values}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        {values["without_size"] == true && (
                                            <Form.Group controlId="without_size_qty">
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Label className="inputFormLabel">Without Size Qty :</Form.Label>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormTextInput
                                                            type="number"
                                                            name="without_size_qty"
                                                            placeholder="Without Size Qty ..."
                                                            values={values}
                                                            handleChange={handleChange}
                                                            touched={touched}
                                                            errors={errors}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        )}
                                    </Col>
                                </Row>

                                {/* Only display table if without size is not selected */}
                                {values["without_size"] == false && (
                                    <NewUtnOrderSizes
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                        utnDetailsDropdown={utnDetailsDropdown}
                                        utnBrandDropdown={utnBrandDropdown}
                                        getAndIncrementFieldArrayRowID={getAndIncrementFieldArrayRowID}
                                    />
                                )}
                                <Container>
                                    <Row>
                                        <Col>{submitAlertMessage.length == 0 && <SubmitButton isSubmitting={isSubmitting} />}</Col>
                                    </Row>
                                    <Row>
                                        <Col>&nbsp;</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SubmitMessage submitMessage={submitAlertMessage} submitVariant={submitAlertVariant} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        )}
                    </Formik>
                )}
            />
        );
    }
    return (
        <Fragment>
            <div className="container-fluid align-self-center mt-5">
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={10}>{component}</Col>
                    <Col sm={1}></Col>
                </Row>
            </div>
        </Fragment>
    );
}
