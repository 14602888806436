import * as utils from "../../../../utils/utils";

// Function accepts dropdown list from backend and converts it to format
// for react-select [{label:<NAME>, value:<KEY>}]
export function FetchPattaPartyDropdown(pattaParties) {
    var pattaPartiesSelectDropdown = [];
    pattaParties.forEach(function (element) {
        pattaPartiesSelectDropdown.push({ label: utils.ToTitleCase(element.name), value: element.id });
    });
    return pattaPartiesSelectDropdown;
}
