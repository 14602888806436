import { Formik } from "formik";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Form, Alert, Table, Button } from "react-bootstrap";

import * as inventoryUtils from "../utils";
import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import Can from "../../../../../components/can";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";
import { SelectDropdown } from "../../../../../components/selectDropdown";
import { SubmitMessage } from "../../../../../components/submitComponents";
import { FormCheckbox, FormCheckboxRef } from "../../../../../components/inputFieldComponents";

function DisplayPendingOrders(userDetails, pendingOrders, with_size, checkedRows, setCheckedRows) {
	console.log(`Displaying with_size ${with_size}`);
	let component = (
		<Table bordered size="sm" variant="link">
			<thead>
				<tr
					style={{
						color: "#05386b",
						background: "#c5c6c7",
					}}
				>
					<th>
						{
							<FormCheckbox
								name="select-all"
								label=""
								initialValue={false}
								onChangeCallback={(val) => {
									const newRows = { ...checkedRows };
									// looping over all the rows check boxes
									// and only manually clicking that checkbox which needs to be changed
									var list = document.getElementsByClassName("row-check-box");
									for (var item of list) {
										newRows[item.name] = val;
										if (checkedRows[item.name] != val) {
											item.click();
										}
									}
									setCheckedRows(newRows);
								}}
							/>
						}
					</th>
					<th>Order No.</th>
					<th>Order Date</th>
					<th>Due Date</th>
					<th>Party</th>
					<th>Th</th>
					<th>{with_size ? "Order Qty (kg)" : "Without Size Qty (kg)"}</th>
					<th>Dispatch</th>
				</tr>
			</thead>
			<tbody>
				{pendingOrders.map((order, index) => {
					return (
						<tr key={index}>
							<td>
								{
									<FormCheckbox
										className="row-check-box"
										name={order["id"]}
										label=""
										initialValue={false}
										onChangeCallback={(val, name) => {
											const newRows = { ...checkedRows };
											newRows[name] = val;
											setCheckedRows(newRows);
										}}
									/>
								}
							</td>
							<td>{order["serial"]}</td>
							<td>{utils.FormatDisplayDate(order["ord_date"])}</td>
							<td>{with_size ? utils.FormatDisplayDate(order["due_date"]) : "WITHOUT SIZE"}</td>
							<td>
								{utils.ToTitleCase(
									`${order["party"]["name"]}, ${order["party"]["place"]}` + (order["remark"] == "" ? `` : ` (${order["remark"]})`)
								)}
							</td>
							<td>{order["party"]["through"].toUpperCase()}</td>
							<td>
								{with_size
									? utils.DisplayDecimal2Places(order["actual_qty"])
									: utils.DisplayDecimal2Places(order["without_size_qty"])}
							</td>
							<td>
								{with_size ? (
									<Can
										role={userDetails[constants.ROLE]}
										perform="gsl:utensil:orders:lorry:new"
										yes={() => (
											<Link to={`/gsl/utensil/orders/lorry/new?id=${order["id"]}`}>
												<Button variant="outline-warning" size="sm" block>
													<FontAwesomeIcon icon="paper-plane" size={"2x"} />
												</Button>
											</Link>
										)}
									/>
								) : (
									""
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
	return component;
}

function fetchPendingOrdersList(
	url,
	partyID,
	with_size,
	userToken,
	pendingOrdersList,
	setPendingOrdersList,
	setCheckedRows,
	setSubmitAlertMessage,
	setNextListLink,
	setTotalListCount,
	setIsLoading
) {
	setIsLoading(true);
	console.log(`fetching pending utensils orders for party : ${partyID} : with-size ${with_size}`);
	// When button submits form and form is in the process of submitting, submit button is disabled
	let getURL = url;
	if (url.length == 0) {
		// if it is a new request, formulate URL path
		getURL = `/api/v1/gsl/utensil/orders/order/pending/`;
		getURL += with_size ? "with-size" : "without-size";
	}
	let params = partyID == null || partyID == "" || partyID == "-" ? {} : { party: partyID };

	return api.Get(
		getURL,
		params,
		userToken,
		(res) => {
			console.log("received pending order data : ", res);
			if (res.data.results == null) {
				throw new Error("Pending Order List not found.");
			}

			let ordersList = [];
			if (url.length == 0) {
				// if it is a new request, reset with new data points
				ordersList = res.data.results;
			} else {
				// if user has clicked on load more, concatenate the results
				ordersList = pendingOrdersList.concat(res.data.results);
			}
			setPendingOrdersList(ordersList);
			// this is used for checkbox logic
			let rowID = {};
			ordersList.forEach(function (ord) {
				rowID[ord["id"]] = false;
			});
			setNextListLink(res.data.next);
			setTotalListCount(res.data.count);
			setCheckedRows(rowID);
			setSubmitAlertMessage("");
			setIsLoading(false);
		},
		(error) => {
			console.log("Error in fetching pending utensil order list", error);
			setSubmitAlertMessage(utils.GetErrorMessage(error));
			setIsLoading(false);
		}
	);
}

function FetchTotalPendingQty(userToken, setTotalPendingQty, setLoadingErrorDetails) {
	// When button submits form and form is in the process of submitting, submit button is disabled
	return api.Get(
		"/api/v1/gsl/utensil/orders/order/total/current-pending",
		{},
		userToken,
		(res) => {
			console.log("Fetched Total Pending Qty : ", res);
			"data" in res && setTotalPendingQty(res.data);
		},
		(error) => {
			console.log("Error in fetching Total Pending Qty : ", error);
			setLoadingErrorDetails((err) => [
				...err,
				{
					hadLoadingError: true,
					errorMessage: utils.GetErrorMessage(error),
				},
			]);
		}
	);
}

export default function ViewPendingOrders(props) {
	// state for party and without size is required because in their onchangecallback
	// react re-render occurs which does not give time to set values[""].
	// Hence we manually set state variable
	const [partyNameInput, setPartyNameInput] = useState("-");
	const [withSizeInput, setWithSizeInput] = useState(true);
	const [checkedRows, setCheckedRows] = useState({});
	const withSizeRef = useRef();

	const [nextListLink, setNextListLink] = useState("");
	const [totalListCount, setTotalListCount] = useState(0);

	const [isPageLoading, setIsPageLoading] = useState(true);
	const [isPendingOrderLoading, setIsPendingOrderLoading] = useState(true);
	const [loadingErrorDetails, setLoadingErrorDetails] = useState([]);
	const [submitAlertMessage, setSubmitAlertMessage] = useState("");

	const [utnPartyDropdown, setUtnPartyDropdown] = useState([]);
	const [pendingOrdersList, setPendingOrdersList] = useState([]);
	const [totalPendingQty, setTotalPendingQty] = useState({});

	const loadMoreEntries = () => {
		let nextListURL = new URL(nextListLink);
		const pendingOrdersList = fetchPendingOrdersList(
			nextListURL.pathname + nextListURL.search,
			partyNameInput,
			withSizeInput,
			userDetails[constants.JWT_TOKEN_KEY],
			pendingOrdersList,
			setPendingOrdersList,
			setCheckedRows,
			setSubmitAlertMessage,
			setNextListLink,
			setTotalListCount,
			setIsPendingOrderLoading
		);
	};

	useEffect(() => {
		const partyList = utils.FetchDropdownList(
			"/api/v1/gsl/utensil/orders/party/dropdown",
			userDetails[constants.JWT_TOKEN_KEY],
			{},
			[],
			setUtnPartyDropdown,
			setLoadingErrorDetails
		);
		const pendingOrderList = fetchPendingOrdersList(
			"",
			"",
			true,
			userDetails[constants.JWT_TOKEN_KEY],
			pendingOrdersList,
			setPendingOrdersList,
			setCheckedRows,
			setSubmitAlertMessage,
			setNextListLink,
			setTotalListCount,
			setIsPendingOrderLoading
		);

		const totalPendingQtyData = FetchTotalPendingQty(userDetails[constants.JWT_TOKEN_KEY], setTotalPendingQty, setLoadingErrorDetails);

		Promise.all([partyList, pendingOrderList, totalPendingQtyData]).then(() => {
			setIsPageLoading(false);
		});
	}, []);

	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	const fetchOrders = (party, with_size) => {
		fetchPendingOrdersList(
			"",
			party,
			with_size,
			userDetails[constants.JWT_TOKEN_KEY],
			pendingOrdersList,
			setPendingOrdersList,
			setCheckedRows,
			setSubmitAlertMessage,
			setNextListLink,
			setTotalListCount,
			setIsPendingOrderLoading
		);
	};

	const [hasLoadingError, pageLoadErrorMessage] = utils.checkPageLoadErrorArray(loadingErrorDetails);

	var component = utils.PageLoadComponents(
		hasLoadingError ? "Error in fetching utensil group list -> " + pageLoadErrorMessage : "",
		isPendingOrderLoading || isPageLoading
	);
	if (component == null) {
		component = (
			<Can
				role={userDetails[constants.ROLE]}
				perform="gsl:utensil:orders:order:pending:view"
				no={() => <UnAuthorizedComponent />}
				yes={() => (
					<Container>
						<h2 className="appNameHeaderTitle">
							PENDING ORDERS
							<br />
							<br />
						</h2>
						<Formik
							initialValues={{
								party: "",
								with_size: true,
							}}
						>
							{({ setFieldValue, handleChange, setFieldTouched, touched, errors }) => (
								<Form
									noValidate
									onChange={(e) => {
										handleChange(e);
										setSubmitAlertMessage("");
									}}
								>
									<Form.Group as={Row} controlId="partyName">
										<Col sm={3}></Col>
										<Col sm={5}>
											<Row>
												<Col sm={4}>
													<Form.Label className="inputFormLabel">Search Party :</Form.Label>
												</Col>
												<Col sm={8}>
													<SelectDropdown
														name="party"
														value={partyNameInput}
														onChange={setFieldValue}
														onBlur={setFieldTouched}
														placeholder="Party Name..."
														options={inventoryUtils.FetchPartyNameDropdown(utnPartyDropdown, true)}
														onChangeCallback={(party) => {
															setPartyNameInput(party);
															fetchOrders(party, withSizeInput);
														}}
														error={errors["party"]}
														touched={touched["party"]}
													/>
												</Col>
											</Row>
										</Col>
										<Col sm={4}>
											<FormCheckboxRef
												ref={withSizeRef}
												className="without-size-checkbox"
												initialValue={withSizeInput}
												name="with_size"
												label="With&nbsp;Size&nbsp;:&nbsp;"
												onChange={setFieldValue}
												onChangeCallback={(with_size) => {
													setWithSizeInput(with_size);
													fetchOrders(partyNameInput, with_size);
												}}
											/>
										</Col>
									</Form.Group>
								</Form>
							)}
						</Formik>
						<Can
							role={userDetails[constants.ROLE]}
							perform="gsl:utensil:orders:order:total-qty:view"
							yes={() => (
								<Row>
									<Col sm={8}></Col>
									<Col
										sm={4}
										style={{
											fontWeight: "bold",
											fontSize: "18px",
											color: "#cc0000",
										}}
									>
										With Size Qty :&nbsp;
										<span
											style={{
												fontWeight: "normal",
											}}
										>
											{totalPendingQty.total_pending_with_size_qty ? totalPendingQty.total_pending_with_size_qty : "0"} MT
										</span>
										<br />
										Without Size Qty :&nbsp;
										<span
											style={{
												fontWeight: "normal",
											}}
										>
											{totalPendingQty.total_pending_without_size_qty ? totalPendingQty.total_pending_without_size_qty : "0"} MT
										</span>
									</Col>
								</Row>
							)}
						/>
						<br />
						{pendingOrdersList.length > 0 ? (
							DisplayPendingOrders(userDetails, pendingOrdersList, withSizeInput, checkedRows, setCheckedRows)
						) : (
							<Alert variant="success">No Pending Orders Found.</Alert>
						)}
						<Row>
							<Col sm={withSizeInput ? 3 : 4}></Col>
							<Col sm={withSizeInput ? 3 : 4}>
								<Can
									role={userDetails[constants.ROLE]}
									perform="gsl:utensil:orders:order:individual:view"
									yes={() => (
										<Button
											variant="outline-success"
											size="lg"
											block
											onClick={() => {
												let data = [];
												Object.keys(checkedRows).forEach(function (ord_id) {
													if (checkedRows[ord_id]) {
														data.push(ord_id);
													}
												});
												console.log("data : ", data);
												if (data.length > 50) {
													setSubmitAlertMessage("You can view details of maximum 50 orders.");
												} else if (data.length == 0) {
													setSubmitAlertMessage("Please select at least one order to view details.");
												} else {
													let URL = "/gsl/utensil/orders/order/view?ids=" + encodeURIComponent(data.toString());
													props.history.push(URL);
												}
											}}
										>
											View Details
										</Button>
									)}
								/>
							</Col>

							{withSizeInput ? (
								<Col sm={3}>
									<Can
										role={userDetails[constants.ROLE]}
										perform="gsl:utensil:orders:order:patta-requirement:view"
										yes={() => (
											<Button
												variant="outline-danger"
												size="lg"
												block
												onClick={() => {
													let data = [];
													Object.keys(checkedRows).forEach(function (ord_id) {
														if (checkedRows[ord_id]) {
															data.push(ord_id);
														}
													});
													console.log("data : ", data);
													if (data.length > 50) {
														setSubmitAlertMessage("You can view patta requirements of maximum 50 orders.");
													} else if (data.length == 0) {
														setSubmitAlertMessage("Please select at least one order to view production planning.");
													} else {
														let URL =
															"/gsl/utensil/orders/order/patta-requirement?ids=" + encodeURIComponent(data.toString());
														props.history.push(URL);
													}
												}}
											>
												Production-Planning
											</Button>
										)}
									/>
								</Col>
							) : (
								""
							)}
						</Row>
						<br></br>
						<Row>
							<Col>
								<SubmitMessage submitMessage={submitAlertMessage} submitVariant="danger" />
							</Col>
						</Row>
						<Row>
							<Col sm={4}></Col>
							<Col sm={4}>
								Showing {pendingOrdersList.length} of {totalListCount}
								<br />
								{nextListLink ? (
									<Button variant="warning" onClick={loadMoreEntries}>
										Load More
									</Button>
								) : (
									""
								)}
							</Col>
							<Col sm={4}></Col>
						</Row>
					</Container>
				)}
			/>
		);
	}
	return (
		<Fragment>
			<div className="container-fluid align-self-center mt-5">
				<Row>
					<Col sm={1}></Col>
					<Col sm={10}>{component}</Col>
					<Col sm={1}></Col>
				</Row>
			</div>
		</Fragment>
	);
}

ViewPendingOrders.propTypes = {
	history: PropTypes.object,
};
