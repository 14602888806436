import qs from "qs";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Alert, Table } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import Can from "../../../../../components/can";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";

function DisplayPattaRequirement(currentPattaRequirement) {
    let nonPressKeys = [];
    let pressKeys = [];

    currentPattaRequirement?.pattaReq?.nonpress &&
        Object.keys(currentPattaRequirement?.pattaReq?.nonpress).map((key) => {
            nonPressKeys.push(key);
        });

    currentPattaRequirement?.pattaReq?.press &&
        Object.keys(currentPattaRequirement?.pattaReq?.press).map((key) => {
            pressKeys.push(key);
        });

    // function creates individual tables for different utensils
    const displayIndividualPattaRequirement = (type, utnGroup, pattaReqObj) => {
        console.log("creating table for ", utnGroup);
        return (
            <Table responsive bordered size="sm" variant="link">
                <thead>
                    <tr
                        className="stockNameTitle"
                        style={{
                            color: type == "pressing" ? "#05386b" : "#6e6658",
                            background: type == "pressing" ? "#5cdb95" : "#f5e6cc",
                        }}
                    >
                        <th colSpan={6}>{utnGroup}</th>
                    </tr>
                    <tr>
                        <th>#</th>
                        <th>Width</th>
                        <th>Grade</th>
                        <th>Point</th>
                        <th>Length</th>
                        <th>Qty (in MT)</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(pattaReqObj[utnGroup]).map((key, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{currentPattaRequirement?.PATTA_IDS_DETAIL[key][0]}</td>
                                <td>{currentPattaRequirement?.PATTA_IDS_DETAIL[key][1]}</td>
                                <td>{currentPattaRequirement?.PATTA_IDS_DETAIL[key][2]}</td>
                                <td>{currentPattaRequirement?.PATTA_IDS_DETAIL[key][3]}</td>
                                <td>{utils.DisplayDecimal2Places(pattaReqObj[utnGroup][key])}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };

    let loopLimit = Math.max(nonPressKeys.length, pressKeys.length);
    let nonPressTables = [];
    let pressTables = [];

    for (let i = 0; i < loopLimit; i += 1) {
        if (i < nonPressKeys.length) {
            nonPressTables.push(
                <Row key={i}>
                    <Col sm={12}>{displayIndividualPattaRequirement("kalwat", nonPressKeys[i], currentPattaRequirement?.pattaReq?.nonpress)}</Col>
                </Row>
            );
        }
        if (i < pressKeys.length) {
            pressTables.push(
                <Row key={i}>
                    <Col sm={12}>{displayIndividualPattaRequirement("pressing", pressKeys[i], currentPattaRequirement?.pattaReq?.press)}</Col>
                </Row>
            );
        }
    }
    return (
        <Container>
            <Row>
                <Col sm={6}>
                    <h3>
                        KALWAT
                        <br />
                    </h3>
                    {nonPressTables.length > 0 ? nonPressTables : <Alert variant="warning">No Patta for Kalwat.</Alert>}
                </Col>
                <Col sm={6}>
                    <h3>
                        PRESSING
                        <br />
                    </h3>
                    {pressTables.length > 0 ? pressTables : <Alert variant="warning">No Patta for Pressing.</Alert>}
                </Col>
            </Row>
        </Container>
    );
}

function DisplaySizeWisePendingQty(currentPattaRequirement) {
    let utnSizesTables = [];
    console.log("+++ currentPattaRequirement for ", currentPattaRequirement);

    // function creates individual tables for different utensils
    const displayUtnSizeQty = (index, utnCode, utnObj, utnUnitWeightObj) => {
        console.log("+++ creating table for ", utnCode, " - utnObj  : ", utnObj);

        let utnNameColumns = [];
        let utnSizeColumns = [];
        let utnQtyColumns = [];

        let keysLength = Object.keys(utnObj).length;
        let totalQty = 0;
        Object.keys(utnObj).map((key, innerIndex) => {
            totalQty += utnObj[key];
            console.log("+++ utnObj : ", utnObj, " - key : ", key , " - utnUnitWeightObj : ",utnUnitWeightObj);
            let utn_unit_wt_temp = 0;
            if(utnUnitWeightObj && key in utnUnitWeightObj) {
                utn_unit_wt_temp = utnUnitWeightObj[key];
                if(utn_unit_wt_temp === undefined || utn_unit_wt_temp === null) {
                    utn_unit_wt_temp = 0;
                }
            }
            utnSizeColumns.push(
                <th
                    key={`0-${index}-${innerIndex}`}
                    style={{
                        borderLeft: innerIndex == 0 ? "2px solid black" : "",
                        borderRight: innerIndex == keysLength - 1 ? "2px solid black" : "",
                        fontWeight: 600,
                    }}
                >
                    {key}
                </th>
            );
            utnQtyColumns.push(
                <th
                    key={`0-${index}-${innerIndex}`}
                    style={{
                        borderLeft: innerIndex == 0 ? "2px solid black" : "",
                        borderRight: innerIndex == keysLength - 1 ? "2px solid black" : "",
                        borderBottom: "2px solid black",
                        fontWeight: "normal",
                    }}
                >
                    <span >{utnObj[key]} KG</span> {utn_unit_wt_temp > 0 ? <span style={{fontStyle: "italic"}}><br />({Math.round(utnObj[key]/utn_unit_wt_temp)} pcs)</span> : ""}
                </th>
            );
        });
        utnNameColumns.push(
            <th
                key={index}
                colSpan={keysLength}
                style={{
                    color: "#9a1750",
                    background: "#e3e2df",
                    border: "2px solid black",
                }}
            >
                {currentPattaRequirement.UTENSIL_DISPLAYS[utnCode].toUpperCase()}  - {totalQty} KG
            </th>
        );
        utnSizesTables.push(
            <Table responsive bordered size="sm" variant="link">
            <thead>
                <tr>{utnNameColumns}</tr>
            </thead>
            <tbody>
                <tr>{utnSizeColumns}</tr>
                <tr>{utnQtyColumns}</tr>
            </tbody>
        </Table>
        );
    };

    currentPattaRequirement?.sizewise_pending_qty && currentPattaRequirement?.sortedUtensilKeys_sizewise_pending_qty &&
        currentPattaRequirement?.sortedUtensilKeys_sizewise_pending_qty.map((key, index) => {
            displayUtnSizeQty(index, key, currentPattaRequirement.sizewise_pending_qty[key], currentPattaRequirement.UTENSIL_UNIT_WEIGHTS[key]);
        });

    return (
        <Container>
            {utnSizesTables}
        </Container>
    );
}

function FetchPattaRequirementList(orderIDs, userToken, setCurrentPattaRequirement, setOrderDetailsJSX, setIsPattaRequirementLoading, setHasLoadingError, setLoadingErrorDetails) {
    setIsPattaRequirementLoading(true);
    // When button submits form and form is in the process of submitting, submit button is disabled

    let URL = "";
    let params = "";
    if (orderIDs == null || orderIDs == undefined || orderIDs === "") {
        URL = `/api/v1/gsl/utensil/orders/order/patta-requirement`;
    } else {
        let ordID = orderIDs.split(",");
        if (ordID.length > 0) {
            params = ordID.toString();
            URL = `/api/v1/gsl/utensil/orders/order/patta-requirement?ids=[${params}]`;
        } else {
            URL = `/api/v1/gsl/utensil/orders/order/patta-requirement`;
        }
    }

    return api.Get(
        URL,
        {},
        userToken,
        (res) => {
            console.log(`received current patta requirement : `, res);
            if ("data" in res) {
                setCurrentPattaRequirement(res.data);

                // Fetching list of order details to find party names list
                api.Get(
                    `/api/v1/gsl/utensil/orders/order/details?ids=[${params}]`,
                    {},
                    userToken,
                    (order_details_res) => {
                        console.log("received orders details : ", order_details_res);
                        let ordDetails = [];
                        if (order_details_res.data !== null) {
                            ordDetails = order_details_res.data;
                            let order_details_jsx = [];
                            let total_order_qty_in_kg = 0;
                            ordDetails.forEach(function (ord, index) {
                                let objLength = Object.keys(ord.sizes).length;

                                total_order_qty_in_kg += objLength == 0 ? ord.without_size_qty : ord.actual_qty;

                                let tmp_order_jsx = `Order #${ord["serial"]} : ${objLength == 0 ? ord.without_size_qty : ord.actual_qty} KG - ${utils.ToTitleCase(ord["party"]["name"]+", "+ord["party"]["place"])} (${utils.FormatDisplayDate(ord["ord_date"])} ${ord["remark"]})`;
                                order_details_jsx.push(
                                    <span>
                                        {tmp_order_jsx}
                                        <br />
                                    </span>
                                );
                            });
                            order_details_jsx.push(
                                <span style={{ fontWeight: 700, color: "#9a1750" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TOTAL : {(total_order_qty_in_kg/1000).toFixed(2)} MT
                                    <br />
                                </span>
                            );
                            setOrderDetailsJSX(order_details_jsx);
                        } 
                    },
                    (error) => {
                        console.log("Error in fetching order details", error);
                    }
                );
                setIsPattaRequirementLoading(false);
            } else {
                throw new Error("Received Invalid Data from server");
            }
        },
        (error) => {
            console.log("Error in fetching Patta Requirement", error);
            setCurrentPattaRequirement({});
            setHasLoadingError(true);
            setLoadingErrorDetails(utils.GetErrorMessage(error));
            setIsPattaRequirementLoading(false);
        }
    );
}

export default function ViewPattaRequirement(props) {
    //this key is to reset SelectDropdown on form submit

    const [orderDetailsJSX, setOrderDetailsJSX] = useState([]);
    const [currentPattaRequirement, setCurrentPattaRequirement] = useState({});
    const [isPattaRequirementLoading, setIsPattaRequirementLoading] = useState(true);
    const [loadingErrorDetails, setLoadingErrorDetails] = useState([]);
    const [hasLoadingError, setHasLoadingError] = useState(false);

    useEffect(() => {
        const orderIds = qs.parse(props.location.search, { ignoreQueryPrefix: true }).ids;
        FetchPattaRequirementList(
            orderIds,
            userDetails[constants.JWT_TOKEN_KEY],
            setCurrentPattaRequirement,
            setOrderDetailsJSX,
            setIsPattaRequirementLoading,
            setHasLoadingError,
            setLoadingErrorDetails
        );
    }, [userDetails]);

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    var component = utils.PageLoadComponents(
        hasLoadingError ? "Error in fetching patta Requirement list -> " + loadingErrorDetails : "",
        isPattaRequirementLoading
    );
    if (component == null) {
        component = (
            <Can
                role={userDetails[constants.ROLE]}
                perform="gsl:utensil:orders:order:patta-requirement:view"
                no={() => <UnAuthorizedComponent />}
                yes={() => (
                    <Container>
                        <h2 className="appNameHeaderTitle">
                            PRODUCTION PLANNING
                            <br />
                        </h2>
                        <p  style={{textAlign:"left", fontWeight: 550}}>
                            <i>
                                For Orders
                            </i>
                        </p>
                        <p style={{textAlign:"left", fontWeight: 550}}>
                            {orderDetailsJSX}
                        </p>
                        {DisplaySizeWisePendingQty(currentPattaRequirement)}
                        {/* Due to non-requirement & bugs, hiding the patta requirement tables*/}
                        {/* {DisplayPattaRequirement(currentPattaRequirement)} */}
                        <br />
                        <br />
                        <br />
                    </Container>
                )}
            />
        );
    }
    return (
        <Fragment>
            <div className="container align-self-center mt-5">
                <Row>
                    <Col sm={12}>{component}</Col>
                </Row>
            </div>
        </Fragment>
    );
}

ViewPattaRequirement.propTypes = {
    location: PropTypes.object,
    search: PropTypes.string,
};
