import React, { Fragment } from "react";
import { Button, Row, Col } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";
import * as inventoryUtils from "../utils";

import "../../../../../public/custom.css";

export function SubmitDayProduction(
	values,
	validationSchema,
	userAuthToken,
	utensilVariantDropdown,
	setSubmitting,
	setSubmitAlertVariant,
	setSubmitAlertMessage,
	resetForm,
	setFormResetKey
) {
	console.log("starting submitting data for new day production : ", values);

	try {
		// When button submits form and form is in the process of submitting, submit button is disabled
		setSubmitting(true);
		var castValues = validationSchema.cast(values);
		var parsedValues = {
			date: castValues["date"],
			utensilProdn: {},
		};

		// checking for not null entries
		if (castValues["kalwatUtnSpecQty"].length == 0 && castValues["polishUtnSpecQty"].length == 0) {
			throw new Error("Both Kalwat & Polish production cannot be zero at the same time.");
		}

		const populateInventorySpec = (utensilSpec, type) => {
			console.log(`parsing input for ${type} : `, utensilSpec);
			try {
				let qty = parseFloat(utensilSpec.qty);
				if (isNaN(qty) || (qty <= 0.0 && qty > 100000.0)) {
					throw new Error(`Invalid Quantity '${utensilSpec.qty}'.`);
				}
				switch (type) {
					//running same logic for both kalwat/polish
					case "kalwat":
					case "polish":
						if (utensilSpec.name in utensilVariantDropdown) {
							if (utensilSpec.size in utensilVariantDropdown[utensilSpec.name]) {
								if (utensilSpec.brand in utensilVariantDropdown[utensilSpec.name][utensilSpec.size]) {
									let utnPrimaryKey = utensilVariantDropdown[utensilSpec.name][utensilSpec.size][utensilSpec.brand];
									// if (inventoryUtils.IsUtensilQtyWithinRange(utensilSpec.name, utensilSpec.size, qty)) {
									if (utnPrimaryKey in parsedValues["utensilProdn"]) {
										parsedValues["utensilProdn"][utnPrimaryKey][type] += qty;
									} else {
										parsedValues["utensilProdn"][utnPrimaryKey] = {
											kalwat: 0,
											polish: 0,
										};
										parsedValues["utensilProdn"][utnPrimaryKey][type] = qty;
									}
									// } else {
									// 	throw new Error(
									// 		`Quantity of ${utensilSpec.name} with size ${
									// 			utensilSpec.size
									// 		} should be less than ${inventoryUtils.GetMaximumAllowedQty(utensilSpec.name, utensilSpec.size)}.`
									// 	);
									// }
								} else {
									throw new Error(`Brand ${utensilSpec.brand} does not exist in Utensil ${utensilSpec.name}.`);
								}
							} else {
								throw new Error(`Size ${utensilSpec.size} does not exist in Utensil ${utensilSpec.name}.`);
							}
						} else {
							throw new Error(`Utensil ${utensilSpec.name} does not exist.`);
						}
						break;
				}
			} catch (err) {
				console.log("Error in parsing individual row input data : ", err.message);
				return err.message;
			}
			return "";
		};

		castValues["kalwatUtnSpecQty"].forEach(function (utensilSpec) {
			let response = populateInventorySpec(utensilSpec, "kalwat");
			if (response.length > 0) {
				throw new Error(response);
			}
		});
		castValues["polishUtnSpecQty"].forEach(function (utensilSpec) {
			let response = populateInventorySpec(utensilSpec, "polish");
			if (response.length > 0) {
				throw new Error(response);
			}
		});

		// converting parsed values to submitted - dict to array
		var submittedValues = {
			date: parsedValues["date"],
			utensilProdn: [],
		};

		Object.keys(parsedValues["utensilProdn"]).forEach(function (primaryKey) {
			submittedValues["utensilProdn"].push({
				utn_id: primaryKey,
				kalwat: parsedValues["utensilProdn"][primaryKey]["kalwat"],
				polish: parsedValues["utensilProdn"][primaryKey]["polish"],
			});
		});

		console.log("FINAL submitting utensil production sizes value : ", submittedValues);
		api.Post(
			`/api/v1/gsl/utensil/inventory/production`,
			submittedValues,
			userAuthToken,
			() => {
				setSubmitAlertVariant("success");
				setSubmitAlertMessage(
					<Fragment>
						<strong>Successfully Added Day Production.</strong>
						<br></br>
						<br></br>
						<Row>
							<Col sm={3}></Col>
							<Col sm={3}>
								<Button variant="warning" href="/gsl/utensil/inventory/production/view">
									View Daily Production
								</Button>
							</Col>
							<Col sm={3}>
								<Button variant="danger" href="/gsl/utensil/inventory/stock/utensil">
									View Utensil Stock
								</Button>
							</Col>
							<Col sm={3}></Col>
						</Row>
					</Fragment>
				);
				resetForm();
				setFormResetKey(Date.now());
				setSubmitting(false);
			},
			(error) => {
				console.log("Error in processing Add New Day Production request ", error);
				setSubmitAlertVariant("danger");
				setSubmitAlertMessage("Error in Adding Day Production Sizes -> " + utils.GetErrorMessage(error));
				setSubmitting(false);
			}
		);
	} catch (error) {
		setSubmitAlertVariant("danger");
		setSubmitAlertMessage(utils.GetErrorMessage(error));
		setSubmitting(false);
		return;
	}
}
