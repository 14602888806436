import _ from "lodash";
import { Formik, FieldArray } from "formik";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState } from "react";
import { Col, Row, Form, Container, Table, Button } from "react-bootstrap";

import * as api from "../../../../../utils/api";
``;
import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import Can from "../../../../../components/can";
import yup from "../../../../../components/yup";
import FetchPattaSizesVariantDropdown from "../utils";
import constants from "../../../../../constants/constants";
import UnAuthorizedComponent from "../../../../../components/403";
import { PattaSpecQtyFieldClass } from "../PattaSpecQtyFieldClass";
import { SelectDropdownFieldArray } from "../../../../../components/selectDropdown";
import { SubmitButton, SubmitMessage } from "../../../../../components/submitComponents";
import { FormTextInput, FormTextInputFieldArray } from "../../../../../components/inputFieldComponents";

export default function NewDispatchScheduleOrderForm() {
    var fieldArrayRowID = 0;
    //this key is to reset SelectDropdown on form submit
    const [formResetKey, setFormResetKey] = useState("");
    const [submitAlertVariant, setSubmitAlertVariant] = useState("success");
    const [submitAlertMessage, setSubmitAlertMessage] = useState("");

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    const validationSchema = yup.object().shape({
        date: yup.string().required("Date is Required."),
        pattaSpecQty: yup
            .array()
            .of(
                yup.object().shape({
                    spec: yup.string().required("Patta Specification is required"),
                    qty: yup.number().typeError("Quantity must be a number").required("Quantity is required.").positive("Quantity must be greater than zero"),
                })
            )
            .min(1, "Need at least 1 Patta Size"),
    });

    const submitForm = (values, { setSubmitting, resetForm }) => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        setSubmitting(true);
        var castValues = validationSchema.cast(values);
        var submittedValues = {
            date: castValues["date"],
            required_sizes: {},
        };
        let validQty = true;
        castValues["pattaSpecQty"].forEach(function (classObj) {
            let qty = parseFloat(classObj.qty);
            if (isNaN(qty) || (qty <= 0.0 && qty > 100000.0)) {
                setSubmitAlertVariant("danger");
                setSubmitAlertMessage(`Invalid Quantity '${classObj.qty}'.`);
                setSubmitting(false);
                validQty = false;
            } else {
                if (classObj.spec in submittedValues["required_sizes"]) {
                    submittedValues["required_sizes"][classObj.spec] += qty;
                } else {
                    submittedValues["required_sizes"][classObj.spec] = qty;
                }
            }
        });
        // If invalid qty entered by user, show error
        if (validQty == false) {
            return;
        }
        console.log("submitting patta sizes value : ", submittedValues);
        api.Post(
            `/api/v1/bsil/patta/gsl-utn-patta-sizes/lorry/dispatch/schedule`,
            submittedValues,
            userDetails[constants.JWT_TOKEN_KEY],
            () => {
                setSubmitAlertVariant("success");

                setSubmitAlertMessage(
                    <Fragment>
                        <strong>Successfully Added Dispatch Lorry Schedule.</strong>
                        <br></br>
                        <br></br>
                        <Button variant="warning" href="/bsil/patta/gsl-patta-sizes/lorry/dispatchSchedule/view">
                            View Dispatch Schedule
                        </Button>
                    </Fragment>
                );

                setSubmitting(false);
                resetForm();
                setFormResetKey(Date.now());
            },
            (error) => {
                console.log("Error in processing Add Dispatch Schedule request ", error);
                setSubmitAlertVariant("danger");
                setSubmitAlertMessage("Error in Adding Dispatch Lorry Schedule -> " + utils.GetErrorMessage(error));
                setSubmitting(false);
            }
        );
    };

    var totalRunningQty = 0.0;

    var component = (
        <Can
            role={userDetails[constants.ROLE]}
            perform="bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:new"
            no={() => <UnAuthorizedComponent />}
            yes={() => (
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        date: utils.GetTodayDate(),
                        pattaSpecQty: [new PattaSpecQtyFieldClass(fieldArrayRowID)],
                    }}
                    onSubmit={submitForm}
                >
                    {({ handleSubmit, handleChange, setFieldValue, setFieldTouched, values, touched, isSubmitting, errors }) => (
                        <Form
                            noValidate
                            //this key is to reset SelectDropdown on form submit
                            key={formResetKey}
                            onSubmit={handleSubmit}
                            onChange={(e) => {
                                handleChange(e);
                                setSubmitAlertMessage("");
                            }}
                        >
                            <h2 className="appNameHeaderTitle">
                                ADD DISPATCH SCHEDULE SIZES
                                <br />
                                <br />
                            </h2>
                            <Form.Group as={Row} controlId="date">
                                <Form.Label column sm={3}>
                                    Date :
                                </Form.Label>
                                <Col sm={4}>
                                    <FormTextInput
                                        type="date"
                                        name="date"
                                        placeholder="Enter Date ..."
                                        values={values}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                    />
                                </Col>
                            </Form.Group>
                            <span
                                style={{
                                    display: "inline-block",
                                    float: "right",
                                    fontSize: "125%",
                                }}
                            >
                                Total: {totalRunningQty} kg
                            </span>
                            <Table bordered striped size="sm" variant="success">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Patta Specification</th>
                                        <th>Quantity (in kg)</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <FieldArray
                                    name="pattaSpecQty"
                                    render={({ remove, push }) => (
                                        <tbody>
                                            {values.pattaSpecQty.length > 0 &&
                                                !(totalRunningQty = 0.0) &&
                                                values.pattaSpecQty.map((pattaSpec, index) => {
                                                    if (!(pattaSpec.qty == null || pattaSpec.qty == undefined || pattaSpec.qty == "")) {
                                                        totalRunningQty += pattaSpec.qty;
                                                    }
                                                    return (
                                                        <tr key={pattaSpec.ID}>
                                                            <th
                                                                style={{
                                                                    width: "5%",
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: "40%",
                                                                }}
                                                            >
                                                                <Form.Group as={Row} controlId="pattaSize">
                                                                    <Col sm={12}>
                                                                        <SelectDropdownFieldArray
                                                                            fieldArrayName="pattaSpecQty"
                                                                            index={index}
                                                                            fieldInputName="spec"
                                                                            onChange={setFieldValue}
                                                                            onBlur={setFieldTouched}
                                                                            placeholder="Patta Specification"
                                                                            options={FetchPattaSizesVariantDropdown()}
                                                                            touched={touched}
                                                                            errors={errors}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: "15%",
                                                                }}
                                                            >
                                                                <Form.Group as={Row} controlId="orderQty">
                                                                    <Col sm={12}>
                                                                        <FormTextInputFieldArray
                                                                            type="number"
                                                                            fieldArrayName="pattaSpecQty"
                                                                            index={index}
                                                                            fieldInputName="qty"
                                                                            placeholder="Quantity.."
                                                                            handleChange={handleChange}
                                                                            touched={touched}
                                                                            errors={errors}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: "5%",
                                                                }}
                                                            >
                                                                <Button variant="outline-danger" onClick={() => remove(index)}>
                                                                    <i className="fa fa-close fa-10x" aria-hidden="true"></i>
                                                                </Button>
                                                            </th>
                                                        </tr>
                                                    );
                                                })}
                                            <tr>
                                                <th colSpan={4}>
                                                    <Button
                                                        variant="warning"
                                                        onClick={() => {
                                                            fieldArrayRowID += 1;
                                                            push(new PattaSpecQtyFieldClass(fieldArrayRowID));
                                                        }}
                                                    >
                                                        Add
                                                    </Button>
                                                </th>
                                            </tr>
                                        </tbody>
                                    )}
                                />
                            </Table>
                            <Container>
                                <Row>
                                    <Col>
                                        <SubmitButton isSubmitting={isSubmitting} />
                                        {errors && _.isString(errors["pattaSpecQty"]) && touched && _.isArray(touched["pattaSpecQty"]) && (
                                            <div className="field-error">
                                                <br />
                                                {errors["pattaSpecQty"]}
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <SubmitMessage submitMessage={submitAlertMessage} submitVariant={submitAlertVariant} />
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            )}
        />
    );
    return (
        <Fragment>
            <div className="container align-self-center mt-5">
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={8}>{component}</Col>
                    <Col sm={2}></Col>
                </Row>
            </div>
        </Fragment>
    );
}
