import qs from "qs";
import _ from "lodash";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Form, Container, Alert } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import Can from "../../../../../components/can";
import yup from "../../../../../components/yup";

import { DisplayOrdersDetails } from "../../orders/order/viewOrderDetails";
import DispatchLorrySizes from "./dispatchLorrySizes";
import constants from "../../../../../constants/constants";
import UnAuthorizedComponent from "../../../../../components/403";
import { ProductionUtnSpecFieldClass } from "../ProductionUtnSpecFieldClass";
import { SubmitMessage, SubmitButton } from "../../../../../components/submitComponents";
import { FormTextInput } from "../../../../../components/inputFieldComponents";
import { SubmitNewDispatchLorry } from "./submitNewDispatchLorry";

function FetchOrderDetails(orderIDString, setOrdersList, userToken, setOrderID, setLoadingErrorDetails) {
    try {
        if (orderIDString == null || orderIDString === "") {
            throw new Error("Order ID is missing.");
        }
        let ordID = orderIDString.split(",");
        if (ordID.length == 0) {
            throw new Error("Order ID is missing.");
        }
        console.log("Order ID : ", ordID);
        if (ordID.length > 1) {
            throw new Error("Multiple Order IDs found. You can dispatch only one order at a time.");
        }
        let params = ordID.toString();

        return api.Get(
            `/api/v1/gsl/utensil/orders/order/details?ids=[${params}]`,
            {},
            userToken,
            (res) => {
                let ordDetails = {};
                if (res.data == null) {
                    throw new Error("Order details not found");
                } else {
                    ordDetails = res.data;
                }

                if (ordDetails.length == 0) {
                    throw new Error("Order details not found.");
                }
                if (ordDetails.length > 1) {
                    throw new Error("Multiple Order IDs found. You can dispatch only one order at a time.");
                }
                if (!ordDetails[0]["is_pending"]) {
                    throw new Error("Cannot dispatch an already dispatched/cancelled order.");
                }
                if (ordDetails[0]["sizes"].length == 0) {
                    throw new Error("Cannot dispatch a without size order.");
                }
                // converting sizes into
                // <brand>:
                //      <sizes>: obj
                //      ...
                let utnSizes = {};
                ordDetails[0]["sizes"].forEach(function (utn) {
                    if (utn["brand_display"] in utnSizes) {
                        if (utn["utensil_display"] in utnSizes[utn["brand_display"]]) {
                            utnSizes[utn["brand_display"]][utn["utensil_display"]].push(utn);
                        } else {
                            utnSizes[utn["brand_display"]][utn["utensil_display"]] = [utn];
                        }
                    } else {
                        utnSizes[utn["brand_display"]] = {};
                        utnSizes[utn["brand_display"]][utn["utensil_display"]] = [utn];
                    }
                });
                ordDetails[0]["sizes"] = utnSizes;
                setOrdersList(ordDetails);
                setOrderID(ordID[0]);
                setLoadingErrorDetails((err) => [
                    ...err,
                    {
                        hadLoadingError: false,
                        errorMessage: "",
                    },
                ]);
            },
            (error) => {
                console.log("Error in fetching order details", error);
                setLoadingErrorDetails((err) => [
                    ...err,
                    {
                        hadLoadingError: true,
                        errorMessage: utils.GetErrorMessage(error),
                    },
                ]);
            }
        );
    } catch (error) {
        console.log("Error in fetching order details", error);
        setLoadingErrorDetails((err) => [
            ...err,
            {
                hadLoadingError: true,
                errorMessage: utils.GetErrorMessage(error),
            },
        ]);
    }
}

export default function DispatchNewLorry(props) {
    const [orderID, setOrderID] = useState("");
    const [specFieldArrayRowID, setSpecFieldArrayRowID] = useState(0);

    const [isPageLoading, setIsPageLoading] = useState(true);
    const [loadingErrorDetails, setLoadingErrorDetails] = useState([]);

    //this key is to reset SelectDropdown on form submit
    const [formResetKey, setFormResetKey] = useState("");
    const [submitAlertVariant, setSubmitAlertVariant] = useState("success");
    const [submitAlertMessage, setSubmitAlertMessage] = useState("");

    const [utensilVariantDropdown, setUtensilVariantDropdown] = useState({});
    const [ordersList, setOrdersList] = useState([]);

    //fetching the list of utensil variant dropdown
    useEffect(() => {
        const utnDropdown = utils.FetchDropdownList(
            "/api/v1/gsl/utensil/inventory/utensil/dropdown",
            userDetails[constants.JWT_TOKEN_KEY],
            {},
            [],
            setUtensilVariantDropdown,
            setLoadingErrorDetails
        );

        const orderId = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
        const orderDetails = FetchOrderDetails(orderId, setOrdersList, userDetails[constants.JWT_TOKEN_KEY], setOrderID, setLoadingErrorDetails);

        Promise.all([utnDropdown, orderDetails]).then(() => {
            setIsPageLoading(false);
        });
    }, []);

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    // this function is called by child component to increment field array row ID state
    const getAndIncrementFieldArrayRowID = () => {
        setSpecFieldArrayRowID(specFieldArrayRowID + 1);
        return specFieldArrayRowID + 1;
    };

    const validationSchema = yup
        .object()
        .nullable()
        .required()
        .shape({
            date: yup.string().required("Date is Required."),

            dsp_qty: yup.number().typeError("Quantity must be a number").required("Quantity is required.").positive("Quantity must be greater than zero."),

            spec: yup.array().of(
                yup
                    .object()
                    .required()
                    .shape({
                        name: yup.string().nullable().required("Utensil Name is required."),
                        size: yup.string().nullable().required("Utensil Size is required."),
                        brand: yup.string().nullable().required("Utensil Brand is required."),
                        qty: yup
                            .number()
                            .typeError("Quantity must be a number")
                            .required("Quantity is required.")
                            .positive("Quantity must be greater than zero."),
                    })
            ),
        });

    const submitForm = (values, { setSubmitting, resetForm }) => {
        SubmitNewDispatchLorry(
            "complete",
            values,
            orderID,
            validationSchema,
            userDetails[constants.JWT_TOKEN_KEY],
            utensilVariantDropdown,
            setSubmitting,
            setSubmitAlertVariant,
            setSubmitAlertMessage,
            resetForm,
            setFormResetKey
        );
    };

    const [hasLoadingError, pageLoadErrorMessage] = utils.checkPageLoadErrorArray(loadingErrorDetails);
    var component = utils.PageLoadComponents(hasLoadingError ? "Error in fetching fetching order details -> " + pageLoadErrorMessage : "", isPageLoading);
    if (component == null) {
        component = (
            <Can
                role={userDetails[constants.ROLE]}
                perform="gsl:utensil:inventory:production:new"
                no={() => <UnAuthorizedComponent />}
                yes={() => (
                    <Fragment>
                        <h2 className="appNameHeaderTitle">
                            GSL UTENSILS : DISPATCH NEW LORRY
                            <br />
                            <br />
                        </h2>
                        <h4 className="appNameHeaderTitle">Current Order Size</h4>
                        {_.isEmpty(ordersList) ? <Alert variant="success">No Order Details Found.</Alert> : DisplayOrdersDetails(ordersList)}
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={{
                                date: utils.GetTodayDate(),
                                dsp_qty: "",
                                spec: [new ProductionUtnSpecFieldClass(specFieldArrayRowID)],
                            }}
                            onSubmit={submitForm}
                        >
                            {({ handleSubmit, handleChange, setFieldValue, setFieldTouched, values, touched, isSubmitting, errors }) => {
                                return (
                                    <Form
                                        noValidate
                                        //this key is to reset SelectDropdown on form submit
                                        key={formResetKey}
                                        onSubmit={handleSubmit}
                                        onKeyDown={(keyEvent) => {
                                            if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                                keyEvent.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setSubmitAlertMessage("");
                                        }}
                                    >
                                        <br />
                                        <br />
                                        <br />
                                        <Row>
                                            <Col sm={2}></Col>
                                            <Col sm={4}>
                                                <Form.Group as={Row} controlId="date">
                                                    <Form.Label column sm={5} className="inputFormLabel">
                                                        Dispatch Date&nbsp;:
                                                    </Form.Label>
                                                    <Col sm={7}>
                                                        <FormTextInput
                                                            type="date"
                                                            name="date"
                                                            placeholder="Enter Date..."
                                                            values={values}
                                                            handleChange={handleChange}
                                                            touched={touched}
                                                            errors={errors}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Group as={Row} controlId="dsp_qty">
                                                    <Form.Label column sm={6} className="inputFormLabel">
                                                        Dispatched Qty(in kg)&nbsp;:
                                                    </Form.Label>
                                                    <Col sm={6}>
                                                        <FormTextInput
                                                            type="number"
                                                            name="dsp_qty"
                                                            placeholder="Enter Dispatched Qty..."
                                                            values={values}
                                                            handleChange={handleChange}
                                                            touched={touched}
                                                            errors={errors}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <DispatchLorrySizes
                                            inputName="spec"
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                            handleChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                            utensilVariantDropdown={utensilVariantDropdown}
                                            tableVariant="info"
                                            addButtonVariant="success"
                                            getAndIncrementFieldArrayRowID={getAndIncrementFieldArrayRowID}
                                        />

                                        <Container>
                                            <Row>
                                                <Col sm={4}></Col>
                                                <Col sm={4}>
                                                    <SubmitButton block={true} variant="warning" isSubmitting={isPageLoading || isSubmitting} />
                                                    {errors && _.isString(errors["spec"]) && touched && _.isArray(touched["spec"]) && (
                                                        <div className="field-error">
                                                            <br />
                                                            {errors["spec"]}
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col sm={4}></Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <br />
                                                    <SubmitMessage submitMessage={submitAlertMessage} submitVariant={submitAlertVariant} />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Fragment>
                )}
            />
        );
    }
    return (
        <Fragment>
            <div className="container-fluid align-self-center mt-5">
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={10}>{component}</Col>
                    <Col sm={1}></Col>
                </Row>
            </div>
            <br />
            <br />
            <br />
            <br />
        </Fragment>
    );
}

DispatchNewLorry.propTypes = {
    location: PropTypes.object,
    search: PropTypes.string,
};
