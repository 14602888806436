import AppConstants from "./constants";
import * as utils from "../../../../utils/utils";

/*
utnVariantDropdown is of the form
'Pawali': {
            "uom": "pcs",
            "12": {
                "dhanlaxmi": 1,
                "mrchef": 4
            },
        },
        'Fatka Balti': {
            "uom": "pcs",
            "18": {
                "mrchef": 10
            }
        }...
*/

// Function accepts dropdown list from backend and converts it to format
// for react-select [{label:<NAME>, value:<KEY>}]
export function FetchUtensilNameDropdown(utnVariantDropdown) {
	var utnNameSelectDropdown = [];
	try {
		Object.keys(utnVariantDropdown).forEach(function (name) {
			utnNameSelectDropdown.push({ label: utils.ToTitleCase(name), value: name });
		});
	} catch (err) {
		console.log("Error in fetching utensil name from dropdown : ", err.message);
		utnNameSelectDropdown = [];
	}
	return utnNameSelectDropdown;
}

// Function to fetch acceptable sizes from utensil name
export function FetchUtensilSizesDropdown(type, utnVariantDropdown, utnName) {
	if (utnName == null || utnName == undefined || utnName === "") {
		return [];
	}

	if (!(utnName in utnVariantDropdown)) {
		console.log("Utensil Name not found in list : ", utnName);
		return [];
	}

	// for pawali lid kalwat production, instead of showing sizes pawali wise
	// show size as circle size for ease of data entry
	const transformPawaliLidListPawaliSzToCircleSz = (sz) => {
		const mapping = {
			12: 12.5,
			14: 13.5,
			16: 14,
			18: 15,
			20: 16,
			22: 17,
			24: 18,
			26: 19,
			28: 20,
			30: 21,
		};
		if (type == "kalwat" && utnName == "pawali lid") {
			return mapping[sz];
		}
		return sz;
	};

	// looping over sizes of a utensil and returning as list
	var utnSizesSelectDropdown = [];
	try {
		Object.keys(utnVariantDropdown[utnName]).forEach(function (utnSize) {
			// utensil variant dropdown received unit key also from backend
			// not including that in size dropdown
			if (utnSize != AppConstants.UNIT_KEY) {
				utnSizesSelectDropdown.push({ label: transformPawaliLidListPawaliSzToCircleSz(utnSize), value: utnSize });
			}
		});
	} catch (err) {
		console.log(`Error in fetching utensil size from name ${utnName} : `, err.message);
		utnSizesSelectDropdown = [];
	}

	return utnSizesSelectDropdown;
}

// Function to fetch acceptable brands from utensil name and size
export function FetchUtensilBrandDropdown(utnVariantDropdown, utnName, utnSize) {
	if (utnName == null || utnName == undefined || utnName === "") {
		return [];
	}

	if (!(utnName in utnVariantDropdown)) {
		console.log("Utensil Name not found in list : ", utnName);
		return [];
	}

	if (utnSize == null || utnSize == undefined || utnSize === "") {
		return [];
	}

	if (!(utnSize in utnVariantDropdown[utnName])) {
		console.log("Utensil Size not found in list of ", utnName);
		return [];
	}

	var utnBrandSelectDropdown = [];
	try {
		Object.keys(utnVariantDropdown[utnName][utnSize]).forEach(function (brandKey) {
			utnBrandSelectDropdown.push({ label: utils.ToTitleCase(brandKey), value: brandKey });
		});
	} catch (err) {
		console.log(`Error in fetching utensil brand from name ${utnName}-${utnSize} : `, err.message);
		utnBrandSelectDropdown = [];
	}

	return utnBrandSelectDropdown;
}

// Function to fetch utensil unit of measurement
export function FetchUtensilUnitOfMeasurement(utnVariantDropdown, utnName) {
	if (utnName == null || utnName == undefined || utnName === "") {
		return "";
	}
	if (!(utnName in utnVariantDropdown) || !(AppConstants.UNIT_KEY in utnVariantDropdown[utnName])) {
		console.log("Utensil Name or UOM not found in list : ", utnName, utnVariantDropdown);
		return "";
	}

	return utnVariantDropdown[utnName][AppConstants.UNIT_KEY];
}
// Function to fetch utensil type
export function FetchUtensilTypeDropdown() {
	return [
		{ label: "kalwat", value: "kalwat" },
		{ label: "polish", value: "polish" },
	];
}

/*
    ********  Party Name UTIL FUNCTIONS ********
    utnPartyNameDropdown is of the form
      [{
          name: "Maa Vaishno",
          place: "lucknow"
      }]
*/

// Function accepts dropdown list from backend and converts it to format
// for react-select [{label:<NAME>, value:<KEY>}]
export function FetchPartyNameDropdown(utnPartyNameDropdown) {
	var utnPartyNameSelectDropdown = [];
	try {
		utnPartyNameDropdown.forEach(function (partyObj) {
			utnPartyNameSelectDropdown.push({
				label: `${partyObj["name"]}, ${partyObj["place"]}`,
				value: `${partyObj["name"]}, ${partyObj["place"]}`,
			});
		});
	} catch (err) {
		console.log("Error in fetching party name from dropdown : ", err.message);
		utnPartyNameSelectDropdown = [];
	}
	return utnPartyNameSelectDropdown;
}

// Function accepts dropdown list from backend and converts it to format
// for react-select [{label:<NAME>, value:<KEY>}]
export function FetchUtensilGroupDropdown(utnGroupDropdown) {
	var utnGroupSelectDropdown = [];
	try {
		Object.keys(utnGroupDropdown).forEach(function (group) {
			utnGroupSelectDropdown.push({ label: utils.ToTitleCase(utnGroupDropdown[group]), value: group });
		});
	} catch (err) {
		console.log("Error in fetching utensil group from dropdown : ", err.message);
		utnGroupSelectDropdown = [];
	}
	return utnGroupSelectDropdown;
}

const maxAllowedQuantity = {
	pawali: {
		// all pawali values in pcs
		12: 1500,
		14: 2500,
		16: 2500,
		18: 2500,
		20: 2500,
		22: 2500,
		24: 1500,
		26: 1000,
		28: 1000,
		30: 1000,
	},
	"ration dabba": { 12: 2500, 14: 2500, 16: 2500, 18: 2500, 20: 2500, 22: 2500, 24: 1500 }, // all ration dabba values in pcs
	"premium pawali": {
		// all pawali values in pcs
		12: 1500,
		14: 2500,
		16: 2500,
		18: 2500,
	},
	"fatka balti": 2500, //maximum 2500 pieces
	"punching balti": 2500, //maximum 2500 pieces
	"premium balti": 2500, //maximum 2500 pieces
	// all below pressing and spoon values in kg
	tub: 1000,
	khomcha: 2500,
	bachad: 2500,
	"mp bidding": 2500,
	parat: 2500,
	"up tasla": 2500,
	tope: 2500,
	spoon: 2000,
	"pawali taliya": 2500,
	"fatka balti taliya": 2500,
	"pawali lid": 2500,
	"ration dabba lid": 2500,
};

const utensilUnit = {
	pawali: "pcs",
	"premium pawali": "pcs",
	"ration dabba": "pcs",
	"fatka balti": "pcs",
	"punching balti": "pcs",
	"premium balti": "pcs",
	tub: "pcs",
	khomcha: "kg",
	bachad: "kg",
	"mp bidding": "kg",
	parat: "kg",
	"up tasla": "kg",
	tope: "kg",
	spoon: "kg",
	"pawali taliya": "kg",
	"fatka balti taliya": "kg",
	"pawali lid": "kg",
	"ration dabba lid": "kg",
};

// Function checks if the entered utensil production/dispatch qty is less than the maximum allowed value
export function GetMaximumAllowedQty(utnName, utnSize) {
	if (utnName == "pawali" || utnName == "ration dabba" || utnName == "premium pawali") {
		return maxAllowedQuantity[utnName][utnSize] + " " + utensilUnit[utnName];
	}
	return maxAllowedQuantity[utnName] + " " + utensilUnit[utnName];
}

// Function checks if the entered utensil production/dispatch qty is less than the maximum allowed value
export function IsUtensilQtyWithinRange(utnName, utnSize, qty) {
	if (utnName == "pawali" || utnName == "ration dabba" || utnName == "premium pawali") {
		if (qty <= maxAllowedQuantity[utnName][utnSize]) {
			return true;
		} else {
			return false;
		}
	}
	return qty <= maxAllowedQuantity[utnName];
}
