const rules = {
    //rules to view app buttons and navbar items for BSIL apps
    admin: {
        static: ["bsil:patta:orders:app:view", "bsil:patta:gsl-patta-sizes:app:view"],
    },
    director: {
        static: ["bsil:patta:orders:app:view", "bsil:patta:gsl-patta-sizes:app:view"],
    },
    performance_reviewer: {
        static: ["bsil:patta:orders:app:view"],
    },
    bsil_viewer: {
        static: ["bsil:patta:orders:app:view", "bsil:patta:gsl-patta-sizes:app:view"],
    },
    off_orders: {
        static: ["bsil:patta:orders:app:view"],
    },
    bsil_staff: {
        static: ["bsil:patta:orders:app:view", "bsil:patta:gsl-patta-sizes:app:view"],
    },
    gsl_staff: {
        static: ["bsil:patta:gsl-patta-sizes:app:view"],
    },
};

export default rules;
