import { Redirect } from "react-router-dom";
import { Container, Row, Col, Table, Alert, Button } from "react-bootstrap";
import React, { Fragment, useState, useEffect } from "react";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import Can from "../../../../../components/can";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";

export default function ViewDispatchedLorry() {
    //this key is to reset SelectDropdown on form submit
    const [isLoading, setIsLoading] = useState(true);
    const [hasLoadingError, setHasLoadingError] = useState(false);
    const [dispatchedLorriesList, setDispatchedLorriesList] = useState([]);
    const [nextListLink, setNextListLink] = useState("");
    const [pageLoadErrorMessage, setPageLoadErrorMessage] = useState("");
    const [totalListCount, setTotalListCount] = useState(0);
    const [categoryDispatchedTotal, setCategoryDispatchedTotal] = useState({ mota: 0, patla: 0, kabja: 0 });
    useEffect(() => {
        fetchDispatchedLorriesList(`/api/v1/bsil/patta/orders/lorry/dispatched`);
        fetchDispatchedOrdersCategoryTotal();
    }, []);

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    const fetchDispatchedOrdersCategoryTotal = () => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        return api.Get(
            `/api/v1/bsil/patta/orders/categoryTotal/dispatched`,
            {},
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                setCategoryDispatchedTotal({
                    mota: utils.ConvertKgToMt(res.data["mota"]),
                    patla: utils.ConvertKgToMt(res.data["patla"]),
                    kabja: utils.ConvertKgToMt(res.data["kabja"]),
                    gsl: utils.ConvertKgToMt(res.data["gsl_curr_month_dispatched"]),
                });
            },
            (error) => {
                console.log("Error in fetching Total Dispatched List", error);
                setPageLoadErrorMessage(utils.GetErrorMessage(error));
                setIsLoading(false);
                setHasLoadingError(true);
            }
        );
    };

    const fetchDispatchedLorriesList = (URL) => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        return api.Get(
            URL,
            {},
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                let orderList = dispatchedLorriesList.concat(res.data.results);
                setDispatchedLorriesList(orderList);
                setNextListLink(res.data.next);
                setTotalListCount(res.data.count);
                setIsLoading(false);
                setHasLoadingError(false);
            },
            (error) => {
                console.log("Error in fetching Dispatched Lorries List", error);
                setPageLoadErrorMessage(utils.GetErrorMessage(error));
                setIsLoading(false);
                setHasLoadingError(true);
            }
        );
    };

    const displayDispatchedLorries = (lorry, index) => {
        return (
            <tr key={index} style={{ cursor: "pointer" }} onClick={() => window.open(`/bsil/patta/order/lorry/${lorry.id}/view`, "_blank")}>
                <td>{index + 1}</td>
                <td>{utils.FormatDisplayDate(lorry.date)}</td>
                <td>{utils.ToTitleCase(lorry.party.name) + ", " + utils.ToTitleCase(lorry.party.station)}</td>
                <td>{utils.DisplayDecimal2Places(lorry.lorry_qty)}</td>
            </tr>
        );
    };

    const loadMoreEntries = () => {
        let nextListURL = new URL(nextListLink);
        fetchDispatchedLorriesList(nextListURL.pathname + nextListURL.search);
    };

    var component = utils.PageLoadComponents(hasLoadingError ? "Error in fetching dispatched lorries -> " + pageLoadErrorMessage : "", isLoading);
    if (component == null) {
        component = (
            <Can
                role={userDetails[constants.ROLE]}
                perform="bsil:patta:orders:lorry:dispatched:view"
                no={() => <UnAuthorizedComponent />}
                yes={() => (
                    <Container>
                        <h2 className="appNameHeaderTitle">
                            DISPATCHED LORRIES
                            <br />
                        </h2>
                        <Can
                            role={userDetails[constants.ROLE]}
                            perform="bsil:patta:orders:lorry:dispatched:dispatchedTotal:view"
                            yes={() => (
                                <Row>
                                    <Col>
                                        <Table bordered striped hover size="sm" variant="dark">
                                            <thead>
                                                <tr>
                                                    <th colSpan={4}>Total Dispatched {utils.GetCurrentMonthYear()}.</th>
                                                </tr>
                                                <tr>
                                                    <th>Patla (MT)</th>
                                                    <th>Mota (MT)</th>
                                                    <th>Kabja (MT)</th>
                                                    <th>GSL (MT)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{categoryDispatchedTotal["patla"]}</td>
                                                    <td>{categoryDispatchedTotal["mota"]}</td>
                                                    <td>{categoryDispatchedTotal["kabja"]}</td>
                                                    <td>{categoryDispatchedTotal["gsl"]}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            )}
                        />
                        {dispatchedLorriesList.length == 0 ? (
                            <Alert variant="success"> No Dispatched Lorries</Alert>
                        ) : (
                            <Fragment>
                                <Row>
                                    <Col>
                                        <Table responsive bordered hover size="sm" variant="link">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Date</th>
                                                    <th>Party Name</th>
                                                    <th>Dispatched Qty (kg)</th>
                                                </tr>
                                            </thead>
                                            <tbody>{dispatchedLorriesList.map(displayDispatchedLorries)}</tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={4}>
                                        Showing {dispatchedLorriesList.length} of {totalListCount}
                                        <br />
                                        {nextListLink ? (
                                            <Button variant="warning" onClick={loadMoreEntries}>
                                                Load More
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </Col>
                                    <Col sm={4}></Col>
                                </Row>
                            </Fragment>
                        )}
                    </Container>
                )}
            />
        );
    }
    return (
        <Fragment>
            <div className="container align-self-center mt-5">
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={10}>{component}</Col>
                    <Col sm={1}></Col>
                </Row>
            </div>
        </Fragment>
    );
}
