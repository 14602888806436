import AppConstants from './constants';

// Function accepts dropdown list from backend and converts it to format 
// for react-select [{label:<NAME>, value:<KEY>}]
export default function FetchPattaSizesVariantDropdown() {
    var pattaSizesVariantSelectDropdown = [];
    Object.keys(AppConstants.PATTA_SIZES_VARIANTS).forEach(function (key) {
        pattaSizesVariantSelectDropdown.push({ label: key, value: key })
    });
    return pattaSizesVariantSelectDropdown
}