import _ from "lodash";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Form, Container } from "react-bootstrap";

import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import Can from "../../../../../components/can";
import yup from "../../../../../components/yup";
import NewUtensilProduction from "./newUtensilProduction";
import constants from "../../../../../constants/constants";
import UnAuthorizedComponent from "../../../../../components/403";
import { ProductionUtnSpecFieldClass } from "../ProductionUtnSpecFieldClass";
import { SubmitButton, SubmitMessage } from "../../../../../components/submitComponents";
import { FormTextInput } from "../../../../../components/inputFieldComponents";
import { SubmitDayProduction } from "./submitDayProduction";

export default function NewDayProduction() {
	//this key is to reset SelectDropdown on form submit
	const [kalwatFieldArrayRowID, setKalwatFieldArrayRowID] = useState(4);
	const [polishFieldArrayRowID, setPolishFieldArrayRowID] = useState(104);

	const [formResetKey, setFormResetKey] = useState("");
	const [submitAlertVariant, setSubmitAlertVariant] = useState("success");
	const [submitAlertMessage, setSubmitAlertMessage] = useState("");

	const [isPageLoading, setIsPageLoading] = useState(true);
	const [loadingErrorDetails, setLoadingErrorDetails] = useState([]);
	const [utensilVariantDropdown, setUtensilVariantDropdown] = useState({});

	//fetching the list of utensil variant dropdown
	useEffect(() => {
		const utnList = utils.FetchDropdownList(
			"/api/v1/gsl/utensil/inventory/utensil/dropdown",
			userDetails[constants.JWT_TOKEN_KEY],
			{},
			{},
			setUtensilVariantDropdown,
			setLoadingErrorDetails
		);
		Promise.all([utnList]).then(() => {
			setIsPageLoading(false);
		});
	}, []);

	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	// this function is called by child component to increment field array row ID state
	const getAndIncrementFieldArrayRowID = (type) => {
		switch (type) {
			case "kalwatUtnSpecQty":
				setKalwatFieldArrayRowID(kalwatFieldArrayRowID + 1);
				return kalwatFieldArrayRowID + 1;

			case "polishUtnSpecQty":
				setPolishFieldArrayRowID(polishFieldArrayRowID + 1);
				return polishFieldArrayRowID + 1;
		}
	};

	const validationSchema = yup
		.object()
		.nullable()
		.required()
		.shape({
			date: yup.string().required("Date is Required."),
			kalwatUtnSpecQty: yup.array().of(
				yup
					.object()
					.required()
					.shape({
						name: yup.string().nullable().required("Utensil Name is required."),
						size: yup.string().nullable().required("Utensil Size is required."),
						brand: yup.string().nullable().required("Utensil Brand is required."),
						qty: yup
							.number()
							.typeError("Quantity must be a number")
							.required("Quantity is required.")
							.positive("Quantity must be greater than zero."),
					})
			),
			polishUtnSpecQty: yup.array().of(
				yup
					.object()
					.required()
					.shape({
						name: yup.string().nullable().required("Utensil Name is required."),
						size: yup.string().nullable().required("Utensil Size is required."),
						brand: yup.string().nullable().required("Utensil Brand is required."),
						qty: yup
							.number()
							.typeError("Quantity must be a number")
							.required("Quantity is required.")
							.positive("Quantity must be greater than zero."),
					})
			),
		});

	const submitForm = (values, { setSubmitting, resetForm }) => {
		SubmitDayProduction(
			values,
			validationSchema,
			userDetails[constants.JWT_TOKEN_KEY],
			utensilVariantDropdown,
			setSubmitting,
			setSubmitAlertVariant,
			setSubmitAlertMessage,
			resetForm,
			setFormResetKey
		);
	};

	const onKeyDown = (keyEvent) => {
		if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
			keyEvent.preventDefault();
		}
	};

	const [hasLoadingError, pageLoadErrorMessage] = utils.checkPageLoadErrorArray(loadingErrorDetails);

	var component = utils.PageLoadComponents(
		hasLoadingError ? "Error in fetching utensil variant list -> " + pageLoadErrorMessage : "",
		isPageLoading
	);
	if (component == null) {
		component = (
			<Can
				role={userDetails[constants.ROLE]}
				perform="gsl:utensil:inventory:production:new"
				no={() => <UnAuthorizedComponent />}
				yes={() => (
					<Formik
						validationSchema={validationSchema}
						initialValues={{
							date: utils.GetTodayDate(),
							kalwatUtnSpecQty: [
								new ProductionUtnSpecFieldClass(0),
								new ProductionUtnSpecFieldClass(1),
								new ProductionUtnSpecFieldClass(2),
								new ProductionUtnSpecFieldClass(3),
							],

							polishUtnSpecQty: [
								new ProductionUtnSpecFieldClass(100),
								new ProductionUtnSpecFieldClass(101),
								new ProductionUtnSpecFieldClass(102),
								new ProductionUtnSpecFieldClass(103),
							],
						}}
						onSubmit={submitForm}
					>
						{({ handleSubmit, handleChange, setFieldValue, setFieldTouched, values, touched, isSubmitting, errors }) => (
							<Form
								noValidate
								//this key is to reset SelectDropdown on form submit
								key={formResetKey}
								onSubmit={handleSubmit}
								onKeyDown={onKeyDown}
								onChange={(e) => {
									handleChange(e);
									setSubmitAlertMessage("");
								}}
							>
								<h2 className="appNameHeaderTitle">
									GSL UTENSILS : ADD DAY PRODUCTION
									<br />
									<br />
								</h2>
								<Form.Group as={Row} controlId="date">
									<Col sm={4}></Col>
									<Col sm={4}>
										<Row>
											<Form.Label column sm={3} className="inputFormLabel">
												Date&nbsp;:
											</Form.Label>
											<Col sm={7}>
												<FormTextInput
													type="date"
													name="date"
													placeholder="Enter Date..."
													values={values}
													handleChange={handleChange}
													touched={touched}
													errors={errors}
												/>
											</Col>
										</Row>
									</Col>
									<Col sm={4}></Col>
								</Form.Group>
								<NewUtensilProduction
									inputName="kalwatUtnSpecQty"
									utensilType="kalwat"
									values={values}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
									handleChange={handleChange}
									touched={touched}
									errors={errors}
									utensilVariantDropdown={utensilVariantDropdown}
									tableVariant="warning"
									addButtonVariant="warning"
									getAndIncrementFieldArrayRowID={getAndIncrementFieldArrayRowID}
								/>
								<br />
								<br />
								<br />
								<NewUtensilProduction
									inputName="polishUtnSpecQty"
									utensilType="polish"
									values={values}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
									handleChange={handleChange}
									touched={touched}
									errors={errors}
									utensilVariantDropdown={utensilVariantDropdown}
									tableVariant="success"
									addButtonVariant="success"
									getAndIncrementFieldArrayRowID={getAndIncrementFieldArrayRowID}
								/>

								<Container>
									<Row>
										<Col>
											<SubmitButton isSubmitting={isSubmitting} />
											{errors &&
												(_.isString(errors["kalwatUtnSpecQty"]) || _.isString(errors["polishUtnSpecQty"])) &&
												touched &&
												(_.isArray(touched["kalwatUtnSpecQty"]) || _.isArray(touched["polishUtnSpecQty"])) && (
													<div className="field-error">
														<br />
														{errors["kalwatUtnSpecQty"]}
														{errors["polishUtnSpecQty"]}
													</div>
												)}
										</Col>
									</Row>
									<Row>
										<Col>&nbsp;</Col>
									</Row>
									<Row>
										<Col>
											<SubmitMessage submitMessage={submitAlertMessage} submitVariant={submitAlertVariant} />
										</Col>
									</Row>
								</Container>
							</Form>
						)}
					</Formik>
				)}
			/>
		);
	}
	return (
		<Fragment>
			<div className="container-fluid align-self-center mt-5">
				<Row>
					<Col sm={1}></Col>
					<Col sm={10}>{component}</Col>
					<Col sm={1}></Col>
				</Row>
			</div>
			<br />
			<br />
			<br />
			<br />
		</Fragment>
	);
}
