const rules = {
    admin: {
        static: [
            //party:navbar:view , orders:navbar:view, lorry:navbar:view - used for dashboard navbar
            "bsil:patta:orders:party:navbar:view",
            "bsil:patta:orders:order:navbar:view",
            "bsil:patta:orders:lorry:navbar:view",
            "bsil:patta:orders:party:new",
            "bsil:patta:orders:order:new",
            "bsil:patta:orders:order:pending:view",
            "bsil:patta:orders:order:pending:categoryTotal:view",
            "bsil:patta:orders:lorry:new",
            "bsil:patta:orders:lorry:dispatched:view",
            "bsil:patta:orders:lorry:dispatched:dispatchedTotal:view",
        ],
    },
    director: {
        static: [
            "bsil:patta:orders:party:navbar:view",
            "bsil:patta:orders:order:navbar:view",
            "bsil:patta:orders:lorry:navbar:view",
            "bsil:patta:orders:party:new",
            "bsil:patta:orders:order:new",
            "bsil:patta:orders:order:pending:view",
            "bsil:patta:orders:order:pending:categoryTotal:view",
            "bsil:patta:orders:lorry:dispatched:view",
            "bsil:patta:orders:lorry:dispatched:dispatchedTotal:view",
        ],
    },
    performance_reviewer: {
        static: [
            "bsil:patta:orders:order:navbar:view",
            "bsil:patta:orders:lorry:navbar:view",
            "bsil:patta:orders:order:pending:view",
            "bsil:patta:orders:order:pending:categoryTotal:view",
            "bsil:patta:orders:lorry:dispatched:view",
            "bsil:patta:orders:lorry:dispatched:dispatchedTotal:view",
        ],
    },
    bsil_viewer: {
        static: [
            "bsil:patta:orders:party:navbar:view",
            "bsil:patta:orders:order:navbar:view",
            "bsil:patta:orders:lorry:navbar:view",
            "bsil:patta:orders:order:pending:view",
            "bsil:patta:orders:order:pending:categoryTotal:view",
            "bsil:patta:orders:lorry:dispatched:view",
            "bsil:patta:orders:lorry:dispatched:dispatchedTotal:view",
        ],
    },
    off_orders: {
        static: [
            "bsil:patta:orders:party:navbar:view",
            "bsil:patta:orders:order:navbar:view",
            "bsil:patta:orders:lorry:navbar:view",
            "bsil:patta:orders:party:new",
            "bsil:patta:orders:order:new",
            "bsil:patta:orders:order:pending:view",
            "bsil:patta:orders:lorry:dispatched:view",
        ],
    },
    bsil_staff: {
        static: ["bsil:patta:orders:lorry:navbar:view", "bsil:patta:orders:lorry:new", "bsil:patta:orders:lorry:dispatched:view"],
    },
};

export default rules;
