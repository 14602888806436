import { Formik } from "formik";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Form, Alert, Table, Button } from "react-bootstrap";

import * as inventoryUtils from "../utils";
import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import Can from "../../../../../components/can";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";
import { SelectDropdown } from "../../../../../components/selectDropdown";
import { SubmitMessage } from "../../../../../components/submitComponents";
import { FormCheckbox } from "../../../../../components/inputFieldComponents";

function DisplayDispatchedOrders(dispatchedOrders, checkedRows, setCheckedRows) {
	let component = (
		<Table bordered size="sm" variant="link">
			<thead>
				<tr
					className="stockNameTitle"
					style={{
						color: "#5e001f",
						background: "#00e1d9",
					}}
				></tr>
				<tr>
					<th>
						{
							<FormCheckbox
								name="select-all"
								label=""
								initialValue={false}
								onChangeCallback={(val) => {
									const newRows = { ...checkedRows };
									// looping over all the rows check boxes
									// and only manually clicking that checkbox which needs to be changed
									var list = document.getElementsByClassName("row-check-box");
									for (var item of list) {
										newRows[item.name] = val;
										if (checkedRows[item.name] != val) {
											item.click();
										}
									}
									setCheckedRows(newRows);
								}}
							/>
						}
					</th>
					<th>Order No.</th>
					<th>Order Date</th>
					<th>Dispatch Date</th>
					<th>Party</th>
					<th>Th</th>
					<th>Order Qty (kg)</th>
					<th>Dispatch Qty (kg)</th>
				</tr>
			</thead>
			<tbody>
				{dispatchedOrders.map((order, index) => {
					return (
						<tr key={index}>
							<td>
								{
									<FormCheckbox
										className="row-check-box"
										name={order["id"]}
										label=""
										initialValue={false}
										onChangeCallback={(val, name) => {
											const newRows = { ...checkedRows };
											newRows[name] = val;
											setCheckedRows(newRows);
										}}
									/>
								}
							</td>
							<td>{order["serial"]}</td>
							<td>{utils.FormatDisplayDate(order["ord_date"])}</td>
							<td>{utils.FormatDisplayDate(order["dsp_date"])}</td>
							<td>
								{utils.ToTitleCase(
									`${order["party"]["name"]}, ${order["party"]["place"]}` + (order["remark"] == "" ? `` : ` (${order["remark"]})`)
								)}
							</td>
							<td>{order["party"]["through"]}</td>
							<td>{utils.DisplayDecimal2Places(order["actual_qty"])}</td>
							<td>{utils.DisplayDecimal2Places(order["dsp_qty"])}</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
	return component;
}

function fetchDispatchedOrdersList(
	url,
	partyID,
	userToken,
	dispatchedOrdersList,
	setDispatchedOrdersList,
	setCheckedRows,
	setSubmitAlertMessage,
	setNextListLink,
	setTotalListCount,
	setIsLoading
) {
	setIsLoading(true);
	console.log(`fetching dispatched utensils orders for party : ${partyID}`);
	// When button submits form and form is in the process of submitting, submit button is disabled
	let getURL = url;
	if (url.length == 0) {
		// if it is a new request, formulate URL path
		getURL = `/api/v1/gsl/utensil/orders/order/dispatched`;
	}

	let params = partyID == null || partyID == "" || partyID == "-" ? {} : { party: partyID };

	return api.Get(
		getURL,
		params,
		userToken,
		(res) => {
			console.log("received dispatched order data : ", res);
			if (res.data == null) {
				throw new Error("Dispatched Order List not found.");
			}
			let ordersList = [];
			if (url.length == 0) {
				// if it is a new request, reset with new data points
				ordersList = res.data.results;
			} else {
				// if user has clicked on load more, concatenate the results
				ordersList = dispatchedOrdersList.concat(res.data.results);
			}
			setDispatchedOrdersList(ordersList);
			// this is used for checkbox logic
			let rowID = {};
			ordersList.forEach(function (ord) {
				rowID[ord["id"]] = false;
			});
			setNextListLink(res.data.next);
			setTotalListCount(res.data.count);
			setCheckedRows(rowID);
			setSubmitAlertMessage("");
			setIsLoading(false);
		},
		(error) => {
			console.log("Error in fetching dispatched utensil order list", error);
			setSubmitAlertMessage(utils.GetErrorMessage(error));
			setIsLoading(false);
		}
	);
}

function FetchTotalCurrentMonthDispatchedQty(userToken, setTotalDispatchedQty, setLoadingErrorDetails) {
	// When button submits form and form is in the process of submitting, submit button is disabled
	return api.Get(
		"/api/v1/gsl/utensil/orders/order/current-month/dispatched",
		{},
		userToken,
		(res) => {
			console.log("Fetched Total Current Month Dispatched Qty : ", res);
			"data" in res && setTotalDispatchedQty(res.data);
		},
		(error) => {
			console.log("Error in fetching Total Pending Qty : ", error);
			setLoadingErrorDetails((err) => [
				...err,
				{
					hadLoadingError: true,
					errorMessage: utils.GetErrorMessage(error),
				},
			]);
		}
	);
}

export default function ViewDispatchedOrders(props) {
	// state for party and without size is required because in their onchangecallback
	// react re-render occurs which does not give time to set values[""].
	// Hence we manually set state variable
	const [partyNameInput, setPartyNameInput] = useState("-");
	const [checkedRows, setCheckedRows] = useState({});

	const [nextListLink, setNextListLink] = useState("");
	const [totalListCount, setTotalListCount] = useState(0);

	const [isPageLoading, setIsPageLoading] = useState(true);
	const [isDispatchedOrderLoading, setIsDispatchedOrderLoading] = useState(true);
	const [loadingErrorDetails, setLoadingErrorDetails] = useState([]);
	const [submitAlertMessage, setSubmitAlertMessage] = useState("");

	const [utnPartyDropdown, setUtnPartyDropdown] = useState([]);
	const [dispatchedOrdersList, setDispatchedOrdersList] = useState([]);
	const [totalDispatchedQty, setTotalDispatchedQty] = useState({});

	const loadMoreEntries = () => {
		let nextListURL = new URL(nextListLink);
		fetchDispatchedOrdersList(
			nextListURL.pathname + nextListURL.search,
			partyNameInput,
			userDetails[constants.JWT_TOKEN_KEY],
			dispatchedOrdersList,
			setDispatchedOrdersList,
			setCheckedRows,
			setSubmitAlertMessage,
			setNextListLink,
			setTotalListCount,
			setIsDispatchedOrderLoading
		);
	};

	useEffect(() => {
		const partyList = utils.FetchDropdownList(
			"/api/v1/gsl/utensil/orders/party/dropdown",
			userDetails[constants.JWT_TOKEN_KEY],
			{},
			[],
			setUtnPartyDropdown,
			setLoadingErrorDetails
		);
		const dispatchedOrderList = fetchDispatchedOrdersList(
			"",
			"",
			userDetails[constants.JWT_TOKEN_KEY],
			dispatchedOrdersList,
			setDispatchedOrdersList,
			setCheckedRows,
			setSubmitAlertMessage,
			setNextListLink,
			setTotalListCount,
			setIsDispatchedOrderLoading
		);

		const totalDispatchedQtyData = FetchTotalCurrentMonthDispatchedQty(
			userDetails[constants.JWT_TOKEN_KEY],
			setTotalDispatchedQty,
			setLoadingErrorDetails
		);

		Promise.all([partyList, dispatchedOrderList, totalDispatchedQtyData]).then(() => {
			setIsPageLoading(false);
		});
	}, []);

	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	const fetchOrders = (party) => {
		fetchDispatchedOrdersList(
			"",
			party,
			userDetails[constants.JWT_TOKEN_KEY],
			dispatchedOrdersList,
			setDispatchedOrdersList,
			setCheckedRows,
			setSubmitAlertMessage,
			setNextListLink,
			setTotalListCount,
			setIsDispatchedOrderLoading
		);
	};

	const [hasLoadingError, pageLoadErrorMessage] = utils.checkPageLoadErrorArray(loadingErrorDetails);

	var component = utils.PageLoadComponents(
		hasLoadingError ? "Error in fetching utensil group list -> " + pageLoadErrorMessage : "",
		isDispatchedOrderLoading || isPageLoading
	);
	if (component == null) {
		component = (
			<Can
				role={userDetails[constants.ROLE]}
				perform="gsl:utensil:orders:lorry:dispatched:view"
				no={() => <UnAuthorizedComponent />}
				yes={() => (
					<Container>
						<h2 className="appNameHeaderTitle">
							VIEW DISPATCHED ORDERS
							<br />
							<br />
						</h2>
						<Formik
							initialValues={{
								party: "",
							}}
						>
							{({ setFieldValue, handleChange, setFieldTouched, touched, errors }) => (
								<Form
									noValidate
									onChange={(e) => {
										handleChange(e);
										setSubmitAlertMessage("");
									}}
								>
									<Form.Group as={Row} controlId="partyName">
										<Col sm={3}></Col>
										<Col sm={5}>
											<Row>
												<Col sm={3}>
													<Form.Label className="inputFormLabel">Search&nbsp;Party&nbsp;:</Form.Label>
												</Col>
												<Col sm={9}>
													<SelectDropdown
														name="party"
														value={partyNameInput}
														onChange={setFieldValue}
														onBlur={setFieldTouched}
														placeholder="Party Name..."
														options={inventoryUtils.FetchPartyNameDropdown(utnPartyDropdown, true)}
														onChangeCallback={(party) => {
															setPartyNameInput(party);
															fetchOrders(party);
														}}
														error={errors["party"]}
														touched={touched["party"]}
													/>
												</Col>
											</Row>
										</Col>
									</Form.Group>
								</Form>
							)}
						</Formik>
						<Can
							role={userDetails[constants.ROLE]}
							perform="gsl:utensil:orders:lorry:total-qty:view"
							yes={() => (
								<Row>
									<Col sm={8}></Col>
									<Col
										sm={4}
										style={{
											fontWeight: "bold",
											fontSize: "18px",
											color: "#cc0000",
										}}
									>
										{new Date().toLocaleString("default", { month: "short" })} Dispatched Qty :&nbsp;
										<span
											style={{
												fontWeight: "normal",
											}}
										>
											{totalDispatchedQty.total_current_month_dispatched_qty} MT
										</span>
									</Col>
								</Row>
							)}
						/>
						<br />
						{dispatchedOrdersList.length > 0 ? (
							DisplayDispatchedOrders(dispatchedOrdersList, checkedRows, setCheckedRows)
						) : (
							<Alert variant="success">No Dispatched Orders Found.</Alert>
						)}
						<Row>
							<Col sm={4}></Col>
							<Col sm={4}>
								<Can
									role={userDetails[constants.ROLE]}
									perform="gsl:utensil:orders:lorry:individual:view"
									yes={() => (
										<Button
											variant="outline-success"
											size="lg"
											block
											onClick={() => {
												let data = [];
												Object.keys(checkedRows).forEach(function (ord_id) {
													if (checkedRows[ord_id]) {
														data.push(ord_id);
													}
												});
												console.log("data : ", data);
												if (data.length > 50) {
													setSubmitAlertMessage("You can view details of maximum 50 orders.");
												} else if (data.length == 0) {
													setSubmitAlertMessage("Please select at least one order to view details.");
												} else {
													let URL = "/gsl/utensil/orders/order/view?ids=" + encodeURIComponent(data.toString());
													props.history.push(URL);
												}
											}}
										>
											View Details
										</Button>
									)}
								/>
							</Col>
							<Col sm={4}></Col>
						</Row>
						<br></br>
						<Row>
							<Col>
								<SubmitMessage submitMessage={submitAlertMessage} submitVariant="danger" />
							</Col>
						</Row>
						<Row>
							<Col sm={4}></Col>
							<Col sm={4}>
								Showing {dispatchedOrdersList.length} of {totalListCount}
								<br />
								{nextListLink ? (
									<Button variant="warning" onClick={loadMoreEntries}>
										Load More
									</Button>
								) : (
									""
								)}
							</Col>
							<Col sm={4}></Col>
						</Row>
					</Container>
				)}
			/>
		);
	}
	return (
		<Fragment>
			<div className="container-fluid align-self-center mt-5">
				<Row>
					<Col sm={1}></Col>
					<Col sm={10}>{component}</Col>
					<Col sm={1}></Col>
				</Row>
			</div>
		</Fragment>
	);
}

ViewDispatchedOrders.propTypes = {
	history: PropTypes.object,
};
