import { createSlice } from "@reduxjs/toolkit";

import * as api from "../../../../../utils/api";

export const bsilPattaPartySlice = createSlice({
    name: "bsilPattaParty",
    initialState: {
        //first time user loads page the dropdown will be fetched
        isLoading: true,
        hasErrors: false,
        // parties is a list of objects `{name: "<name>, <station>", id: "<prefix>"}`
        parties: [],
    },
    reducers: {
        getPartyListsSuccess: (state, { payload }) => {
            state.parties = payload
            state.isLoading = false
            state.hasErrors = false
        },
        getPartyListFailure: (state) => {
            state.isLoading = false
            state.hasErrors = true
        },
    },
});

// This thunk function is called in bsil/patta to fetch list of patta party dropdown
// This is saved into redux state so that all requests can us this state variable from beginning itself
export function fetchBSILPattaPartyList() {
    return async (dispatch) => {
        var token = localStorage.getItem('token')
        return api.Get(
            `/api/v1/bsil/patta/party/dropdown-list`,
            {},
            token,
            (res) => {
                var pattaPartyDropdownList = [];
                res.data.forEach(function (element) {
                    pattaPartyDropdownList.push({ name: `${element.name}, ${element.station}`, id: element.id })
                });
                dispatch(getPartyListsSuccess(pattaPartyDropdownList))
            },
            (error) => {
                dispatch(getPartyListFailure(error))
            })
    }
}

export const {
    getPartyListsSuccess,
    getPartyListFailure,
} = bsilPattaPartySlice.actions;

export const BSILPattaPartiesSelector = (state) => state.bsilPattaParty;

export default bsilPattaPartySlice.reducer;