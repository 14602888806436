const rules = {
    admin: {
        static: [
            "gsl:utensil:inventory:stock:navbar:view",
            "gsl:utensil:inventory:production:navbar:view",

            "gsl:utensil:inventory:production:new",
            "gsl:utensil:inventory:production:cumulative:view",
            "gsl:utensil:inventory:stock:utensil:view",
        ],
    },
    director: {
        static: [
            "gsl:utensil:inventory:stock:navbar:view",
            "gsl:utensil:inventory:production:navbar:view",

            "gsl:utensil:inventory:production:cumulative:view",
            "gsl:utensil:inventory:stock:utensil:view",
        ],
    },
    gsl_staff: {
        static: [
            "gsl:utensil:inventory:stock:navbar:view",
            "gsl:utensil:inventory:production:navbar:view",

            "gsl:utensil:inventory:production:new",
            "gsl:utensil:inventory:production:cumulative:view",
            "gsl:utensil:inventory:stock:utensil:view",
        ],
    },
    gsl_dispatch_supr: {
        static: ["gsl:utensil:inventory:stock:navbar:view", "gsl:utensil:inventory:stock:utensil:view"],
    },
};

export default rules;