import React, { Fragment } from "react";
import { Button } from "react-bootstrap";

import * as api from "../../../../../utils/api";
``;
import * as utils from "../../../../../utils/utils";
import * as inventoryUtils from "../utils";

import "../../../../../public/custom.css";

export function SubmitNewDispatchLorry(
	type,
	values,
	orderID,
	validationSchema,
	userAuthToken,
	utensilVariantDropdown,
	setSubmitting,
	setSubmitAlertVariant,
	setSubmitAlertMessage,
	resetForm,
	setFormResetKey
) {
	console.log("starting submitting data : ", values);

	// When button submits form and form is in the process of submitting, submit button is disabled
	setSubmitting(true);
	var castValues = validationSchema.cast(values);

	let validInput = true;
	var parsedValues = {
		dsp_date: castValues["date"],
		dsp_qty: castValues["dsp_qty"],
		dsp_sizes: {},
		partial_dispatch: type == "partial" ? true : false,
	};

	const populateInventorySpec = (utensilSpec) => {
		console.log(`parsing input for : `, utensilSpec);
		try {
			let qty = parseFloat(utensilSpec.qty);
			if (isNaN(qty) || (qty <= 0.0 && qty > 100000.0)) {
				throw new Error(`Invalid Quantity '${utensilSpec.qty}'.`);
			}
			if (utensilSpec.name in utensilVariantDropdown) {
				if (utensilSpec.size in utensilVariantDropdown[utensilSpec.name]) {
					if (utensilSpec.brand in utensilVariantDropdown[utensilSpec.name][utensilSpec.size]) {
						let utnPrimaryKey = utensilVariantDropdown[utensilSpec.name][utensilSpec.size][utensilSpec.brand];

						// if (inventoryUtils.IsUtensilQtyWithinRange(utensilSpec.name, utensilSpec.size, qty)) {
						if (utnPrimaryKey in parsedValues["dsp_sizes"]) {
							parsedValues["dsp_sizes"][utnPrimaryKey] += qty;
						} else {
							parsedValues["dsp_sizes"][utnPrimaryKey] = {};
							parsedValues["dsp_sizes"][utnPrimaryKey] = qty;
						}
						// } else {
						// 	throw new Error(
						// 		`Quantity of ${utensilSpec.name} with size ${
						// 			utensilSpec.size
						// 		} should be less than ${inventoryUtils.GetMaximumAllowedQty(utensilSpec.name, utensilSpec.size)}.`
						// 	);
						// }
					} else {
						throw new Error(`Brand ${utensilSpec.brand} does not exist in Utensil ${utensilSpec.name}.`);
					}
				} else {
					throw new Error(`Size ${utensilSpec.size} does not exist in Utensil ${utensilSpec.name}.`);
				}
			} else {
				throw new Error(`Utensil ${utensilSpec.name} does not exist.`);
			}
		} catch (err) {
			console.log("Error in parsing individual row input data : ", err.message);
			setSubmitAlertVariant("danger");
			setSubmitAlertMessage(err.message);
			setSubmitting(false);
			return false;
		}
		return true;
	};

	castValues["spec"].forEach(function (utensilSpec) {
		if (!populateInventorySpec(utensilSpec)) {
			validInput = false;
		}
	});

	// if any utensil spec is invalid return
	if (!validInput) {
		return;
	}

	// converting parsed values to submitted - dict to array
	var submittedValues = {
		dsp_date: parsedValues["dsp_date"],
		dsp_qty: parsedValues["dsp_qty"],
		dsp_sizes: [],
		partial_dispatch: parsedValues["partial_dispatch"],
	};

	Object.keys(parsedValues["dsp_sizes"]).forEach(function (primaryKey) {
		submittedValues["dsp_sizes"].push({
			utn_id: primaryKey,
			qty: parsedValues["dsp_sizes"][primaryKey],
		});
	});

	console.log("submitting dispatched utensil sizes : ", submittedValues);
	api.Patch(
		`/api/v1/gsl/utensil/orders/order/dispatch/${orderID}`,
		submittedValues,
		userAuthToken,
		() => {
			setSubmitAlertVariant("success");
			setSubmitAlertMessage(
				<Fragment>
					<strong>Successfully Dispatched Order.</strong>
					<br></br>
					<br></br>
					<Button variant="warning" href="/gsl/utensil/orders/lorry/dispatched/view">
						View Dispatched Orders
					</Button>
				</Fragment>
			);
			setSubmitting(false);
			resetForm();
			setFormResetKey(Date.now());
		},
		(error) => {
			console.log("Error in processing Dispatch Order", error);
			setSubmitAlertVariant("danger");
			setSubmitAlertMessage("Error in Dispatching Order Sizes -> " + utils.GetErrorMessage(error));
			setSubmitting(false);
		}
	);
}
