import React from "react";
import { Redirect } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";

import * as utils from "../utils/utils";

export default function LogOutButton() {
    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }
    return (
        <Nav className="ml-auto">
            <NavDropdown
                title={
                    <span className="navbarLinkSpan">
                        <i className="fa fa-user-circle-o fa-10x" aria-hidden="true">
                            &nbsp;&nbsp;
                        </i>
                        {userDetails.username}
                    </span>
                }
                id="basic-nav-dropdown"
            >
                <NavDropdown.Item className="navbarLinkSpan" href="/logout">
                    Log Out
                </NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );
}
