import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

function SecureRoute({ component: Component, ...rest }) {
    let isUserLoggedIn = !(localStorage.getItem("token") == null);
    console.log("isUserLoggedIn : ", isUserLoggedIn);
    return <Route {...rest} render={(props) => (isUserLoggedIn === true ? <Component {...props} /> : <Redirect to={{ pathname: "/login" }} />)} />;
}

SecureRoute.propTypes = {
    component: PropTypes.func,
};

export default SecureRoute;
