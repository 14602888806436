import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { Col, Row, Form, Table, Button } from "react-bootstrap";

import "../../../../../public/custom.css";
import * as inventoryUtils from "../utils";
import { UtnSizesQtyFieldClass } from "../UtnSizesQtyFieldClass";
import { SelectDropdownFieldArray } from "../../../../../components/selectDropdown";
import { FormTextInputFieldArray } from "../../../../../components/inputFieldComponents";

export default class NewUtnOrderSizes extends React.Component {
    render() {
        let utnNameOptions = inventoryUtils.FetchUtnNameDropdown(this.props.utnDetailsDropdown);
        let utnBrandDropdown = inventoryUtils.FetchUtnBrandDropdown(this.props.utnBrandDropdown);
        return (
            <Row>
                <Col sm={12}>
                    <Table bordered striped size="sm" variant="info">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Utensil</th>
                                <th>Brand</th>
                                <th>Size</th>
                                <th>Quantity (in kg)</th>
                            </tr>
                        </thead>
                        <FieldArray
                            name="sizes"
                            render={({ remove, push }) => (
                                <tbody>
                                    {this.props.values["sizes"].length > 0 &&
                                        this.props.values["sizes"].map((utnSizesSpec, index) => {
                                            return (
                                                /* This key should not be set as index becuase then on
											removing row the last one gets removed, not the selected one.
											Setting unique ID gives non-changing ID to each row
											*/
                                                <tr key={utnSizesSpec.id}>
                                                    <th
                                                        style={{
                                                            width: "5%",
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: "20%",
                                                        }}
                                                    >
                                                        <Form.Group as={Row} controlId="utnName">
                                                            <Col sm={12}>
                                                                <SelectDropdownFieldArray
                                                                    fieldArrayName="sizes"
                                                                    value={utnSizesSpec["name"]}
                                                                    index={index}
                                                                    fieldInputName="name"
                                                                    onChange={this.props.setFieldValue}
                                                                    onBlur={this.props.setFieldTouched}
                                                                    runBeforeChange={() => {
                                                                        //on selecting different utensil name , resetting size  dropdown
                                                                        this.props.setFieldValue(
                                                                            `sizes.${index}.size`,
                                                                            null
                                                                        );
                                                                    }}
                                                                    placeholder="Name..."
                                                                    options={utnNameOptions}
                                                                    touched={this.props.touched}
                                                                    errors={this.props.errors}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: "20%",
                                                        }}
                                                    >
                                                        <Form.Group as={Row} controlId="utnBrand">
                                                            <Col sm={12}>
                                                                <SelectDropdownFieldArray
                                                                    fieldArrayName="sizes"
                                                                    value={utnSizesSpec["brand"]}
                                                                    index={index}
                                                                    fieldInputName="brand"
                                                                    onChange={this.props.setFieldValue}
                                                                    onBlur={this.props.setFieldTouched}
                                                                    placeholder="Brand..."
                                                                    options={utnBrandDropdown}
                                                                    touched={this.props.touched}
                                                                    errors={this.props.errors}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: "20%",
                                                        }}
                                                    >
                                                        <Form.Group as={Row} controlId="utnSize">
                                                            <Col sm={12}>
                                                                <SelectDropdownFieldArray
                                                                    key={`utn_size_${this.props.values["sizes"][index]["name"]}`}
                                                                    fieldArrayName="sizes"
                                                                    value={utnSizesSpec["size"]}
                                                                    index={index}
                                                                    fieldInputName="size"
                                                                    onChange={this.props.setFieldValue}
                                                                    onBlur={this.props.setFieldTouched}
                                                                    placeholder="Size..."
                                                                    options={inventoryUtils.FetchUtnSizesDropdown(
                                                                        this.props.utnDetailsDropdown,
                                                                        this.props.values["sizes"][index]["name"]
                                                                    )}
                                                                    touched={this.props.touched}
                                                                    errors={this.props.errors}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: "20%",
                                                        }}
                                                    >
                                                        <Form.Group as={Row} controlId="utnQty">
                                                            <Col sm={12}>
                                                                <FormTextInputFieldArray
                                                                    type="number"
                                                                    index={index}
                                                                    value={utnSizesSpec["qty"].toString()}
                                                                    fieldArrayName="sizes"
                                                                    fieldInputName="qty"
                                                                    handleChange={this.props.handleChange}
                                                                    onBlur={this.props.setFieldTouched}
                                                                    placeholder="Quantity..."
                                                                    touched={this.props.touched}
                                                                    errors={this.props.errors}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </th>
                                                    <th
                                                        style={{
                                                            width: "5%",
                                                        }}
                                                    >
                                                        <Button variant="outline-danger" onClick={() => remove(index)}>
                                                            <i className="fa fa-close fa-10x" aria-hidden="true"></i>
                                                        </Button>
                                                    </th>
                                                </tr>
                                            );
                                        })}
                                    <tr>
                                        <th colSpan={8}>
                                            <Button
                                                variant="warning"
                                                block
                                                onClick={() => {
                                                    push(
                                                        new UtnSizesQtyFieldClass(
                                                            this.props.getAndIncrementFieldArrayRowID()
                                                        )
                                                    );
                                                }}
                                            >
                                                Add
                                            </Button>
                                        </th>
                                    </tr>
                                </tbody>
                            )}
                        />
                    </Table>
                </Col>
            </Row>
        );
    }
}

NewUtnOrderSizes.propTypes = {
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    utnDetailsDropdown: PropTypes.object.isRequired,
    utnBrandDropdown: PropTypes.object.isRequired,
    getAndIncrementFieldArrayRowID: PropTypes.func.isRequired,
};
