import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Form, Container } from "react-bootstrap";

import * as utils from "../../../../../utils/utils";

import "../../../../../public/custom.css";
import * as inventoryUtils from "../utils";
import Can from "../../../../../components/can";
import yup from "../../../../../components/yup";
import constants from "../../../../../constants/constants";
import UnAuthorizedComponent from "../../../../../components/403";
import { SelectDropdown } from "../../../../../components/selectDropdown";

import { SubmitButton, SubmitMessage } from "../../../../../components/submitComponents";
import { FormTextInput } from "../../../../../components/inputFieldComponents";
import { SubmitNewUtensilParty } from "./submitNewUtensilParty";

export default function NewUtensilParty() {
	//this key is to reset SelectDropdown on form submit
	const [formResetKey, setFormResetKey] = useState("");
	const [submitAlertVariant, setSubmitAlertVariant] = useState("success");
	const [submitAlertMessage, setSubmitAlertMessage] = useState("");

	const [isPageLoading, setIsPageLoading] = useState(true);
	const [loadingErrorDetails, setLoadingErrorDetails] = useState([]);

	const [mktPersonDropdown, setMktPersonDropdown] = useState({});
	const [stateDistrictDropdown, setStateDistrictDropdown] = useState({});
	//fetching the list of utensil variant dropdown
	useEffect(() => {
		const mktPersonList = utils.FetchDropdownList(
			"/api/v1/gsl/utensil/orders/party/mktPersons/dropdown",
			userDetails[constants.JWT_TOKEN_KEY],
			{},
			{},
			setMktPersonDropdown,
			setLoadingErrorDetails
		);
		const stateDistList = utils.FetchDropdownList(
			"/api/v1/gsl/utensil/orders/party/state/dropdown",
			userDetails[constants.JWT_TOKEN_KEY],
			{},
			[],
			setStateDistrictDropdown,
			setLoadingErrorDetails
		);
		Promise.all([mktPersonList, stateDistList]).then(() => {
			setIsPageLoading(false);
		});
	}, []);

	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	const validationSchema = yup
		.object()
		.nullable()
		.required()
		.shape({
			name: yup.string().nullable().required("Party Name is required."),

			place: yup.string().required("Place is Required."),

			state: yup.string().required("State is Required."),

			district: yup.string().required("District is Required."),

			through: yup.string().required("Through is Required."),

			contact_person: yup.string().required("Contact Person is Required."),

			contact_number: yup
				.string()
				.required("Phone No. is Required.")
				.test("isValidListOfPhoneNo", "Invalid Phone Numbers entered", function (value) {
					if (value == null || value == "") {
						return;
					}
					let numbers = value.split(",");
					let isValidNumbers = true;
					console.log("checking phone numbers : ", numbers);
					numbers.forEach(function (val) {
						// regex check if valid number
						// https://stackoverflow.com/a/22061081/5730790

						// only check for number validitiy if not found invalid till now

						isValidNumbers = isValidNumbers ? /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/.test(val.trim()) : isValidNumbers;
					});
					return isValidNumbers;
				}),
		});

	const submitForm = (values, { setSubmitting, resetForm }) => {
		console.log("values : ", values);
		SubmitNewUtensilParty(
			values,
			validationSchema,
			userDetails[constants.JWT_TOKEN_KEY],
			setSubmitting,
			setSubmitAlertVariant,
			setSubmitAlertMessage,
			resetForm,
			setFormResetKey
		);
	};

	const [hasLoadingError, pageLoadErrorMessage] = utils.checkPageLoadErrorArray(loadingErrorDetails);

	var component = utils.PageLoadComponents(hasLoadingError ? "Error in fetching dropdown list -> " + pageLoadErrorMessage : "", isPageLoading);
	if (component == null) {
		console.log("if condition : ", loadingErrorDetails);
		component = (
			<Can
				role={userDetails[constants.ROLE]}
				perform="gsl:utensil:orders:party:new"
				no={() => <UnAuthorizedComponent />}
				yes={() => (
					<Formik
						enableReinitialize
						validationSchema={validationSchema}
						initialValues={{
							name: "",
							place: "",
							state: "Uttar Pradesh",
							district: "",
							through: "",
							contact_person: "",
							contact_number: "",
						}}
						onSubmit={submitForm}
					>
						{({ handleSubmit, handleChange, setFieldValue, setFieldTouched, values, touched, isSubmitting, errors }) => (
							<Form
								noValidate
								//this key is to reset SelectDropdown on form submit
								key={formResetKey}
								onSubmit={handleSubmit}
								onChange={(e) => {
									handleChange(e);
									setSubmitAlertMessage("");
								}}
							>
								<h2 className="appNameHeaderTitle">
									ADD NEW PARTY
									<br />
									<br />
								</h2>

								<Form.Group as={Row} controlId="name">
									<Form.Label column sm={2} className="inputFormLabel">
										Party Name&nbsp;:
									</Form.Label>
									<Col sm={10}>
										<FormTextInput
											type="text"
											name="name"
											values={values}
											handleChange={handleChange}
											touched={touched}
											errors={errors}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="place">
									<Form.Label column sm={2} className="inputFormLabel">
										Place&nbsp;:
									</Form.Label>
									<Col sm={10}>
										<FormTextInput
											type="text"
											name="place"
											values={values}
											handleChange={handleChange}
											touched={touched}
											errors={errors}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="state">
									<Form.Label column sm={2} className="inputFormLabel">
										State&nbsp;:
									</Form.Label>
									<Col sm={4}>
										<SelectDropdown
											name="state"
											value={values["state"]}
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											placeholder="State ..."
											options={inventoryUtils.FetchStateDropdown(stateDistrictDropdown)}
											error={errors["state"]}
											touched={touched["state"]}
										/>
									</Col>
									<Form.Label column sm={2} className="inputFormLabel">
										District&nbsp;:
									</Form.Label>
									<Col sm={4}>
										<SelectDropdown
											name="district"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											placeholder="District ..."
											options={inventoryUtils.FetchDistrictDropdown(stateDistrictDropdown, values["state"])}
											error={errors["district"]}
											touched={touched["district"]}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="through">
									<Form.Label column sm={2} className="inputFormLabel">
										Through&nbsp;:
									</Form.Label>
									<Col sm={10}>
										<SelectDropdown
											name="through"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											placeholder="Through ..."
											options={inventoryUtils.FetchMktPersonDropdown(mktPersonDropdown)}
											error={errors["through"]}
											touched={touched["through"]}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="contact_person">
									<Form.Label column sm={2} className="inputFormLabel">
										Contact&nbsp;Person:
									</Form.Label>
									<Col sm={10}>
										<FormTextInput
											type="text"
											name="contact_person"
											values={values}
											handleChange={handleChange}
											touched={touched}
											errors={errors}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="contact_number">
									<Form.Label column sm={2} className="inputFormLabel">
										Mobile No(s)&nbsp;:
									</Form.Label>
									<Col sm={10}>
										<FormTextInput
											type="text"
											name="contact_number"
											values={values}
											handleChange={handleChange}
											touched={touched}
											errors={errors}
											placeholder="number-1 , number-2 , number-3"
										/>
									</Col>
								</Form.Group>

								<Container>
									<Row>
										<Col>{submitAlertMessage.length == 0 && <SubmitButton isSubmitting={isSubmitting} />}</Col>
									</Row>
									<Row>
										<Col>&nbsp;</Col>
									</Row>
									<Row>
										<Col>
											<SubmitMessage submitMessage={submitAlertMessage} submitVariant={submitAlertVariant} />
										</Col>
									</Row>
								</Container>
							</Form>
						)}
					</Formik>
				)}
			/>
		);
	}
	return (
		<Fragment>
			<div className="container align-self-center mt-5">
				<Row>
					<Col sm={1}></Col>
					<Col sm={10}>{component}</Col>
					<Col sm={1}></Col>
				</Row>
			</div>
		</Fragment>
	);
}
