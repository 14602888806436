import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Container, Row, Col, Button, Navbar, Nav, NavDropdown } from "react-bootstrap";

import "../../../../public/custom.css";
import Can from "../../../../components/can";
import * as utils from "../../../../utils/utils";
import constants from "../../../../constants/constants";
import LogOutButton from "../../../../components/logOutButton";
import ViewGSLPattaStatus from "./sizes/viewPattaStatus";
import NewGSLPattaSizesForm from "./sizes/newPattaSizes";
import NewCutPattaSizesOrderForm from "./sizes/newCutPattaSizes";
import ViewCutSizesList from "./sizes/viewCutSizes";
import NewDispatchScheduleOrderForm from "./lorry/newDispatchSchedule";
import ViewDispatchSchedule from "./lorry/viewDispatchSchedule";
import NewLorryPattaSizesOrderForm from "./lorry/newDispatchLorrySizes";
import ViewDispatchedLorrySizes from "./lorry/viewDispatchedLorry";

export default function BSILGSLPattaSizesApp() {
	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	var NavBarList = [
		{
			/* Patta Sizes Dropdown */
			navbarPerform: "bsil:patta:gsl-patta-sizes:sizes:navbar:view",
			navDropdownTitle: "Sizes",
			navDropdown: [
				{
					perform: "bsil:patta:gsl-patta-sizes:sizes:status:view",
					href: "/bsil/patta/gsl-patta-sizes/sizes/status/view",
					dropDownTitle: "View Patta Status Report",
				},
				{
					perform: "bsil:patta:gsl-patta-sizes:sizes:cutSizes:new",
					href: "/bsil/patta/gsl-patta-sizes/sizes/cutSizes/new",
					dropDownTitle: "Add Cut Patta Sizes",
				},
				{
					perform: "bsil:patta:gsl-patta-sizes:sizes:cutSizes:view",
					href: "/bsil/patta/gsl-patta-sizes/cutSizes/view",
					dropDownTitle: "View Cut Sizes",
				},
			],
		},
		{
			/* Lorry Sizes Dropdown */
			navbarPerform: "bsil:patta:gsl-patta-sizes:lorry:navbar:view",
			navDropdownTitle: "Lorry",
			navDropdown: [
				{
					perform: "bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:new",
					href: "/bsil/patta/gsl-patta-sizes/lorry/dispatchSchedule/new",
					dropDownTitle: "Add Dispatch Schedule",
				},
				{
					perform: "bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:view",
					href: "/bsil/patta/gsl-patta-sizes/lorry/dispatchSchedule/view",
					dropDownTitle: "View Dispatch Schedule",
				},
				{
					perform: "bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:view",
					href: "/bsil/patta/gsl-patta-sizes/lorry/dispatched/view",
					dropDownTitle: "View Dispatched Lorry",
				},
			],
		},
	];

	var MenubarList = [
		{
			groupTitle: "Status",
			perform: "bsil:patta:gsl-patta-sizes:sizes:navbar:view",
			buttonList: [
				{
					perform: "bsil:patta:gsl-patta-sizes:sizes:status:view",
					buttonVariant: "light",
					fontAwesomeIcon: "info",
					buttonHref: "/bsil/patta/gsl-patta-sizes/sizes/status/view",
					description: "View Patta Status Report",
				},
			],
		},

		{
			groupTitle: "Dispatch",
			perform: "bsil:patta:gsl-patta-sizes:lorry:navbar:view",
			buttonList: [
				{
					perform: "bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:new",
					buttonVariant: "secondary",
					fontAwesomeIcon: "truck",
					buttonHref: "/bsil/patta/gsl-patta-sizes/lorry/dispatchSchedule/new",
					description: "Add Dispatch Schedule",
				},
				{
					perform: "bsil:patta:gsl-patta-sizes:lorry:dispatchSchedule:view",
					buttonVariant: "primary",
					fontAwesomeIcon: "list",
					buttonHref: "/bsil/patta/gsl-patta-sizes/lorry/dispatchSchedule/view",
					description: "View Dispatch Schedule",
				},
				{
					perform: "bsil:patta:gsl-patta-sizes:lorry:dispatchSizes:view",
					buttonVariant: "success",
					fontAwesomeIcon: "clipboard-check",
					buttonHref: "/bsil/patta/gsl-patta-sizes/lorry/dispatched/view",
					description: "View Dispatched Lorries",
				},
			],
		},

		{
			groupTitle: "Cut Sizes",
			perform: "bsil:patta:gsl-patta-sizes:sizes:navbar:view",
			buttonList: [
				{
					perform: "bsil:patta:gsl-patta-sizes:sizes:cutSizes:new",
					buttonVariant: "warning",
					fontAwesomeIcon: "plus",
					buttonHref: "/bsil/patta/gsl-patta-sizes/sizes/cutSizes/new",
					description: "Add Cut Patta Sizes",
				},
				{
					perform: "bsil:patta:gsl-patta-sizes:sizes:cutSizes:view",
					buttonVariant: "danger",
					fontAwesomeIcon: "cut",
					buttonHref: "/bsil/patta/gsl-patta-sizes/cutSizes/view",
					description: "View Cut Sizes",
				},
			],
		},
	];

	return (
		<Fragment>
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
				<Navbar.Brand href="/bsil">
					<img src="/static/bsil-logo.png" width="60" height="60" className="d-inline-block align-top" alt="BSIL" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mr-auto">
						<Nav.Link href="/bsil/patta/gsl-patta-sizes" className="navbarLinkTitle app-navbar-links">
							GSL Patta Sizes
						</Nav.Link>
						{NavBarList.map((node, index) => (
							<Can
								key={index}
								role={userDetails[constants.ROLE]}
								perform={node.navbarPerform}
								yes={() => (
									<NavDropdown className="navbarLinkSpan" title={node.navDropdownTitle} id="basic-nav-dropdown">
										{node.navDropdown.map((navdropdown, dropDownIndex) => (
											<Can
												key={dropDownIndex}
												role={userDetails[constants.ROLE]}
												perform={navdropdown.perform}
												yes={() => (
													<NavDropdown.Item className="navbarLinkSpan" href={navdropdown.href}>
														{navdropdown.dropDownTitle}
													</NavDropdown.Item>
												)}
											/>
										))}
									</NavDropdown>
								)}
							/>
						))}
					</Nav>
					<LogOutButton />
				</Navbar.Collapse>
			</Navbar>
			<BrowserRouter>
				<Switch>
					<Route path="/bsil/patta/gsl-patta-sizes/sizes/pendingSizes/new" component={NewGSLPattaSizesForm} />
					<Route path="/bsil/patta/gsl-patta-sizes/sizes/status/view" component={ViewGSLPattaStatus} />
					<Route path="/bsil/patta/gsl-patta-sizes/sizes/cutSizes/new" component={NewCutPattaSizesOrderForm} />
					<Route path="/bsil/patta/gsl-patta-sizes/cutSizes/view" component={ViewCutSizesList} />
					<Route path="/bsil/patta/gsl-patta-sizes/lorry/dispatchSchedule/new" component={NewDispatchScheduleOrderForm} />
					<Route path="/bsil/patta/gsl-patta-sizes/lorry/dispatchSchedule/view" component={ViewDispatchSchedule} />
					<Route path="/bsil/patta/gsl-patta-sizes/lorry/dispatched/view" component={ViewDispatchedLorrySizes} />
					<Route path="/bsil/patta/gsl-patta-sizes/lorry/dispatchedSizes/new" component={NewLorryPattaSizesOrderForm} />
					<Route exact path="/bsil/patta/gsl-patta-sizes">
						<div className="appHeader">
							<h2 className="appHeaderTitle">GSL PATTA SIZES</h2>
						</div>

						<div className="container align-self-center mt-5">
							<Row>
								<Col sm={3}></Col>
								<Col sm={6}>
									<Container>
										{MenubarList.map((node, index) => (
											<Can
												key={index}
												role={userDetails[constants.ROLE]}
												perform={node.perform}
												yes={() => (
													<Row className="row-m-t">
														<Col>
															<h4 className="menuBarSubTitle">
																{node.groupTitle}
																<br></br>
															</h4>
															<Row className="row-m-t">
																{node.buttonList.map((button, ind) => (
																	<Can
																		key={index + "-" + ind}
																		role={userDetails[constants.ROLE]}
																		perform={button.perform}
																		yes={() => (
																			<Col>
																				<Button
																					size="lg"
																					variant={button.buttonVariant}
																					href={button.buttonHref}
																				>
																					<FontAwesomeIcon icon={button.fontAwesomeIcon} size={"2x"} />
																				</Button>
																				{button.description.trim() !== "" ? (
																					<strong>
																						<br></br> {button.description}
																					</strong>
																				) : (
																					<span></span>
																				)}
																			</Col>
																		)}
																	/>
																))}
															</Row>
														</Col>
													</Row>
												)}
											/>
										))}
									</Container>
								</Col>
								<Col sm={3}></Col>
							</Row>
						</div>
					</Route>
				</Switch>
			</BrowserRouter>
		</Fragment>
	);
}
