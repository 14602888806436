import homeRules from "../pages/homeRules";
import bsilAppsRules from "../pages/bsil/bsilAppsRules";
import bsilAppsPattaRules from "../pages/bsil/patta/orders/bsilPattaRules";
import bsilAppsGslPattaSizesRules from "../pages/bsil/patta/gsl-patta-sizes/gslPattaSizesRules";

import gslUtensilAppsRules from "../pages/gsl/utensil/gslUtensilAppsRules";
import gslUtensilInventoryRules from "../pages/gsl/utensil/inventory/gslUtensilInventoryRules";
import gslUtensilOrdersRules from "../pages/gsl/utensil/orders/gslUtensilOrdersRules";

const combinedRules = [
    homeRules,
    bsilAppsRules,
    bsilAppsPattaRules,
    bsilAppsGslPattaSizesRules,

    gslUtensilAppsRules,
    gslUtensilInventoryRules,
    gslUtensilOrdersRules,
];

const AppRBACRules = {};

// This combines all the rules from all files for checking
combinedRules.forEach(function (appRule) {
    for (var roleName in appRule) {
        // creating the first instance of role in AppRBACRules
        if (!(roleName in AppRBACRules)) {
            AppRBACRules[roleName] = {
                static: [],
                dynamic: [],
            };
        }
        if ("static" in appRule[roleName]) {
            AppRBACRules[roleName]["static"] = AppRBACRules[roleName]["static"].concat(appRule[roleName]["static"]);
        }
        if ("dynamic" in appRule[roleName]) {
            AppRBACRules[roleName]["dynamic"] = AppRBACRules[roleName]["dynamic"].concat(appRule[roleName]["dynamic"]);
        }
    }
});

//function checks if given action is allowed in AppRBACRules[<role-name>]
const check = (rules, role, action, data) => {
    const permissions = rules[role];
    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true;
    }

    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
            // dynamic rule not provided for action
            return false;
        }

        return permissionCondition(data);
    }
    return false;
};

const Can = (props) => (check(AppRBACRules, props.role, props.perform, props.data) ? props.yes() : props.no());

Can.defaultProps = {
    yes: () => null,
    no: () => null,
};

export default Can;
