import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { Col, Row, Form, Table, Button } from "react-bootstrap";

import "../../../../../public/custom.css";
import * as inventoryUtils from "../utils";
import { ProductionUtnSpecFieldClass } from "../ProductionUtnSpecFieldClass";
import { SelectDropdownFieldArray } from "../../../../../components/selectDropdown";
import { FormTextInputFieldArray } from "../../../../../components/inputFieldComponents";

// Component to modularize Kalwat/Polish from new day Production
export default class NewUtensilProduction extends React.Component {
	render() {
		return (
			<Table bordered striped size="sm" variant={this.props.tableVariant}>
				<thead>
					<tr>
						<th colSpan={8}>
							<h2>{this.props.utensilType.toUpperCase()} PRODUCTION</h2>
						</th>
					</tr>
					<tr>
						<th></th>
						<th>Utensil</th>
						<th>Size</th>
						{/* <th>Brand</th> */}
						<th>Unit</th>
						<th>Quantity</th>
						<th></th>
					</tr>
				</thead>
				<FieldArray
					name={this.props.inputName}
					render={({ remove, push }) => {
						return (
							<tbody>
								{this.props.values[this.props.inputName].length > 0 &&
									this.props.values[this.props.inputName].map((utensilSpec, index) => {
										return (
											/* This key should not be set as index becuase then on
                                    removing row the last one gets removed, not the selected one.
                                    Setting unique ID gives non-changing ID to each row
                                    */
											<tr key={utensilSpec.ID}>
												<th
													style={{
														width: "5%",
													}}
												>
													{index + 1}
												</th>
												<th
													style={{
														width: "20%",
													}}
												>
													<Form.Group as={Row} controlId="utensilName">
														<Col sm={12}>
															<SelectDropdownFieldArray
																fieldArrayName={this.props.inputName}
																index={index}
																fieldInputName="name"
																onChange={this.props.setFieldValue}
																onBlur={this.props.setFieldTouched}
																runBeforeChange={() => {
																	//on selecting different utensil name , resetting size and brand dropdown
																	this.props.setFieldValue(`${this.props.inputName}.${index}.size`, null);
																	// this.props.setFieldValue(`${this.props.inputName}.${index}.brand`, null);
																}}
																placeholder="Name..."
																options={inventoryUtils.FetchUtensilNameDropdown(this.props.utensilVariantDropdown)}
																touched={this.props.touched}
																errors={this.props.errors}
															/>
														</Col>
													</Form.Group>
												</th>
												<th
													style={{
														width: "20%",
													}}
												>
													<Form.Group as={Row} controlId="utensilSize">
														<Col sm={12}>
															<SelectDropdownFieldArray
																key={`utensil_sizes_${this.props.values[this.props.inputName][index]["name"]}`}
																fieldArrayName={this.props.inputName}
																index={index}
																fieldInputName="size"
																onChange={this.props.setFieldValue}
																onBlur={this.props.setFieldTouched}
																runBeforeChange={() => {
																	//on selecting different utensil size , resetting brand dropdown
																	// this.props.setFieldValue(`${this.props.inputName}.${index}.brand`, null);
																}}
																placeholder="Size..."
																//fetch size based on utensil name input
																options={inventoryUtils.FetchUtensilSizesDropdown(
																	this.props.utensilType,
																	this.props.utensilVariantDropdown,
																	this.props.values[this.props.inputName][index]["name"]
																)}
																touched={this.props.touched}
																errors={this.props.errors}
															/>
														</Col>
													</Form.Group>
												</th>
												{/* <th
                                                    style={{
                                                        width: "20%",
                                                    }}
                                                >
                                                    <Form.Group as={Row} controlId="utensilBrand">
                                                        <Col sm={12}>
                                                            <SelectDropdownFieldArray
                                                                key={`utensil_brand_${this.props.values[this.props.inputName][index]["name"]}_${
                                                                    this.props.values[this.props.inputName][index]["size"]
                                                                }`}
                                                                fieldArrayName={this.props.inputName}
                                                                index={index}
                                                                fieldInputName="brand"
                                                                onChange={this.props.setFieldValue}
                                                                onBlur={this.props.setFieldTouched}
                                                                placeholder="Brand..."
                                                                options={inventoryUtils.FetchUtensilBrandDropdown(
                                                                    this.props.utensilVariantDropdown,
                                                                    this.props.values[this.props.inputName][index]["name"],
                                                                    this.props.values[this.props.inputName][index]["size"]
                                                                )}
                                                                touched={this.props.touched}
                                                                errors={this.props.errors}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </th> */}
												<th
													style={{
														width: "10%",
													}}
												>
													{inventoryUtils.FetchUtensilUnitOfMeasurement(
														this.props.utensilVariantDropdown,
														this.props.values[this.props.inputName][index]["name"]
													)}
												</th>
												<th
													style={{
														width: "20%",
													}}
												>
													<Form.Group as={Row} controlId="orderQty">
														<Col sm={12}>
															<FormTextInputFieldArray
																type="number"
																index={index}
																fieldArrayName={this.props.inputName}
																fieldInputName="qty"
																handleChange={this.props.handleChange}
																placeholder="Quantity..."
																touched={this.props.touched}
																errors={this.props.errors}
															/>
														</Col>
													</Form.Group>
												</th>
												<th
													style={{
														width: "5%",
													}}
												>
													<Button variant="outline-danger" onClick={() => remove(index)}>
														<i className="fa fa-close fa-10x" aria-hidden="true"></i>
													</Button>
												</th>
											</tr>
										);
									})}
								<tr>
									<th colSpan={8}>
										<Button
											variant={this.props.addButtonVariant}
											block
											onClick={() => {
												push(
													new ProductionUtnSpecFieldClass(this.props.getAndIncrementFieldArrayRowID(this.props.inputName))
												);
											}}
										>
											Add
										</Button>
									</th>
								</tr>
							</tbody>
						);
					}}
				/>
			</Table>
		);
	}
}

NewUtensilProduction.propTypes = {
	inputName: PropTypes.string.isRequired,
	utensilType: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	touched: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	utensilVariantDropdown: PropTypes.object.isRequired,
	tableVariant: PropTypes.string.isRequired,
	addButtonVariant: PropTypes.string.isRequired,
	getAndIncrementFieldArrayRowID: PropTypes.func.isRequired,
};
