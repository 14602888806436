import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Table, Alert } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import AppConstants from "../constants";
import Can from "../../../../../components/can";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";

export default function ViewGSLPattaStatus() {
    //this key is to reset SelectDropdown on form submit
    const [isLoading, setIsLoading] = useState(true);
    const [hasLoadingError, setHasLoadingError] = useState(false);
    /*
        pattaStatusList = [{
            "patta_spec": "R5 80 17",
            "pending_qty": 500.0,
            "in_mill_qty": 0.0
        }]
    */
    const [pattaStatusList, setPattaStatusList] = useState([]);
    const [pageLoadErrorMessage, setPageLoadErrorMessage] = useState("");
    const [pattaStatusTotal, setPattaStatusTotal] = useState({ pending_qty: 0, in_mill_qty: 0 });
    useEffect(() => {
        fetchPattaStatusList();
        fetchPattaStatusTotal();
    }, []);

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    const fetchPattaStatusTotal = () => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        return api.Get(
            `/api/v1/bsil/patta/gsl-utn-patta-sizes/status/total`,
            {},
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                console.log("fetchPattaStatusTotal : ", res);
                setPattaStatusTotal({
                    pending_qty: utils.ConvertKgToMt(res.data["pending_qty"]),
                    in_mill_qty: utils.ConvertKgToMt(res.data["in_mill_qty"]),
                });
            },
            (error) => {
                console.log("Error in fetching Total Category List", error);
                setPageLoadErrorMessage(utils.GetErrorMessage(error));
                setIsLoading(false);
                setHasLoadingError(true);
            }
        );
    };

    const fetchPattaStatusList = () => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        return api.Get(
            `/api/v1/bsil/patta/gsl-utn-patta-sizes/status`,
            {},
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                console.log("fetchPattaStatusList : ", res);
                setPattaStatusList(res.data);
                setIsLoading(false);
                setHasLoadingError(false);
            },
            (error) => {
                console.log("Error in fetching Patta Status List", error);
                setPageLoadErrorMessage(utils.GetErrorMessage(error));
                setIsLoading(false);
                setHasLoadingError(true);
            }
        );
    };

    const getPattaStatusSingleGradeTable = (grade, specRows) => {
        if (specRows.length == 0) {
            specRows.push(
                <tr key={grade}>
                    <td colSpan={7}>No Patta Size Available</td>
                </tr>
            );
        }
        return (
            <Col sm={6}>
                <h4 style={{ textDecoration: "underline" }}>{grade}</h4>
                <Table responsive bordered hover size="sm" variant="link">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Width</th>
                            <th>Point</th>
                            {/* <th style={{
                                "border": "1.75px solid black"
                            }} >Pending (in kg)</th> */}
                            <th
                                style={{
                                    border: "1.75px solid black",
                                }}
                            >
                                In Mill (in kg)
                            </th>
                            <th>Length</th>
                            <th>Section</th>
                        </tr>
                    </thead>
                    <tbody>{specRows}</tbody>
                </Table>
            </Col>
        );
    };

    // currentPattaStatusList is of the form with unique patta_spec
    // [
    //    {patta_spec: "R8 60 15", pending_qty: 500.0, in_mill_qty: 3000},
    //    {patta_spec: "R8 60 17", pending_qty: 100, in_mill_qty: 100},
    //    ...
    // ]
    const displayPattaStatus = (currentPattaStatusList) => {
        let gradeWiseSizes = {
            R8: {
                index: 0,
                specs: [],
            },
            R7: {
                index: 0,
                specs: [],
            },
            R6: {
                index: 0,
                specs: [],
            },
            R5: {
                index: 0,
                specs: [],
            },
        };
        console.log("currentPattaStatusList : ", currentPattaStatusList);
        console.log("AppConstants.PATTA_SIZES_VARIANTS : ", AppConstants.PATTA_SIZES_VARIANTS);

        // extracting <tr> of different grades
        currentPattaStatusList.forEach(function (pattaObj, listIndex) {
            console.log("pattaObj.patta_spec : ", pattaObj.patta_spec);
            let grade = AppConstants.PATTA_SIZES_VARIANTS[pattaObj.patta_spec][AppConstants.GRADE_INDEX];
            let index = gradeWiseSizes[grade]["index"];

            let isLastRow = false;

            if (listIndex + 1 < currentPattaStatusList.length) {
                let nextGrade = AppConstants.PATTA_SIZES_VARIANTS[currentPattaStatusList[listIndex + 1].patta_spec][AppConstants.GRADE_INDEX];
                // Since backend gives patta sizes sorted by grade
                // if grade changes that means we have reached last non-zero size of that particular grade
                if (grade != nextGrade) {
                    isLastRow = true;
                }
            }
            // show botton border for last row
            if (listIndex == currentPattaStatusList.length - 1) {
                isLastRow = true;
            }

            gradeWiseSizes[grade]["specs"].push(
                <tr
                    key={
                        <>
                            {grade} &ndash; {index}
                        </>
                    }
                >
                    <td>{index + 1}</td>
                    <td>{AppConstants.PATTA_SIZES_VARIANTS[pattaObj.patta_spec][AppConstants.WIDTH_INDEX]}</td>
                    <td>{AppConstants.PATTA_SIZES_VARIANTS[pattaObj.patta_spec][AppConstants.POINT_INDEX]}</td>
                    {/* <td
                        style={
                            isLastRow == true
                                ? {
                                      borderLeft: "1.75px solid black",
                                      borderBottom: "1.75px solid black",
                                  }
                                : {
                                      borderLeft: "1.75px solid black",
                                  }
                        }
                    >
                        {utils.DisplayDecimal2Places(pattaObj.pending_qty)}
                    </td> */}
                    <td
                        style={
                            isLastRow == true
                                ? {
                                      borderRight: "1.75px solid black",
                                      borderBottom: "1.75px solid black",
                                  }
                                : {
                                      borderRight: "1.75px solid black",
                                  }
                        }
                    >
                        {utils.DisplayDecimal2Places(pattaObj?.in_mill_qty)}
                    </td>
                    <td>{AppConstants.PATTA_SIZES_VARIANTS[pattaObj.patta_spec][AppConstants.LENGTH_INDEX]}</td>
                    <td>{AppConstants.PATTA_SIZES_VARIANTS[pattaObj.patta_spec][AppConstants.SECTION_INDEX]}</td>
                </tr>
            );

            gradeWiseSizes[grade]["index"] += 1;
        });

        console.log("gradeWiseSizes : ", gradeWiseSizes);

        return (
            <Fragment>
                <Row>
                    {getPattaStatusSingleGradeTable("R8", gradeWiseSizes["R8"]["specs"])}
                    {getPattaStatusSingleGradeTable("R7", gradeWiseSizes["R7"]["specs"])}
                </Row>
                <Row>
                    {getPattaStatusSingleGradeTable("R6", gradeWiseSizes["R6"]["specs"])}
                    {getPattaStatusSingleGradeTable("R5", gradeWiseSizes["R5"]["specs"])}
                </Row>
            </Fragment>
        );
    };

    var component = utils.PageLoadComponents(hasLoadingError ? "Error in fetching patta status report -> " + pageLoadErrorMessage : "", isLoading);
    if (component == null) {
        component =
            pattaStatusList.length == 0 ? (
                <Alert variant="success"> No patta status</Alert>
            ) : (
                <Can
                    role={userDetails[constants.ROLE]}
                    perform="bsil:patta:gsl-patta-sizes:sizes:status:view"
                    no={() => <UnAuthorizedComponent />}
                    yes={() => (
                        <Container>
                            <h2 className="appNameHeaderTitle">
                                GSL PATTA STATUS
                                <br />
                            </h2>
                            <Can
                                role={userDetails[constants.ROLE]}
                                perform="bsil:patta:gsl-patta-sizes:sizes:statusTotal:view"
                                yes={() => (
                                    <Row>
                                        <Col>
                                            <Table bordered striped hover size="sm" variant="dark">
                                                <thead>
                                                    <tr>
                                                        <th>GSL Pending (MT)</th>
                                                        <th>GSL In Mill (MT)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{pattaStatusTotal["pending_qty"]}</td>
                                                        <td>{pattaStatusTotal["in_mill_qty"]}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                )}
                            />
                            {displayPattaStatus(pattaStatusList)}
                        </Container>
                    )}
                />
            );
    }
    return (
        <Fragment>
            <div className="container align-self-center mt-5">
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={10}>{component}</Col>
                    <Col sm={1}></Col>
                </Row>
            </div>
        </Fragment>
    );
}
