import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Form, Container, Button } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import Can from "../../../../../components/can";
import yup from "../../../../../components/yup";
import { FetchPattaPartyDropdown } from "../utils";
import { BodySpinner } from "../../../../../components/spinner";
import { SelectDropdown } from "../../../../../components/selectDropdown";
import UnAuthorizedComponent from "../../../../../components/403";
import { SubmitButton, SubmitMessage } from "../../../../../components/submitComponents";
import { FormTextInput } from "../../../../../components/inputFieldComponents";
import constants from "../../../../../constants/constants";

import { BSILPattaPartiesSelector } from "../party/partySlice";

export default function NewPattaOrderForm() {
    //this key is to reset SelectDropdown on form submit
    const [formResetKey, setFormResetKey] = useState("");
    const [newOrderPotentialCodeIsLoading, setNewOrderPotentialCodeIsLoading] = useState(false);
    const [newOrderPotentialCode, setNewOrderCode] = useState("");
    const [submitAlertVariant, setSubmitAlertVariant] = useState("success");
    const [submitAlertMessage, setSubmitAlertMessage] = useState("");
    const pattaPartySelector = useSelector(BSILPattaPartiesSelector);

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    const schema = yup.object({
        date: yup.string().required("Order Date is Required."),
        party: yup.string().required("Party Name is required."),
        qty: yup.number().typeError("Quantity must be a number").positive("Quantity must be greater than zero").required("Quantity is required."),
        remark: yup.string().trim().lowercase(),
    });

    const submitForm = (values, { setSubmitting, resetForm }) => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        setSubmitting(true);
        var castValues = schema.cast(values);
        var transformedValues = { ...castValues };
        api.Post(
            `/api/v1/bsil/patta/orders`,
            transformedValues,
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                setSubmitAlertVariant("success");
                setSubmitAlertMessage(
                    <Fragment>
                        <strong>
                            Successfully Added New Order with ID &apos;{res.data["party"]}-{res.data["code"]}&apos;.
                        </strong>
                        <br></br>
                        <br></br>
                        <Button variant="warning" href="/bsil/patta/orders/order/pending/view">
                            View Pending Orders
                        </Button>
                    </Fragment>
                );

                setSubmitting(false);
                resetForm();
                setFormResetKey(Date.now());
                setNewOrderCode("");
            },
            (error) => {
                console.log("Error in processing Add New Order request ", error);
                setSubmitAlertVariant("danger");
                setSubmitAlertMessage("Error in Adding New Order -> " + utils.GetErrorMessage(error));
                setSubmitting(false);
            }
        );
    };

    const fetchNewOrderCode = (partyCode) => {
        setNewOrderPotentialCodeIsLoading(true);
        return api.Get(
            `/api/v1/bsil/patta/orders/${partyCode}/new-order-code`,
            {},
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                setNewOrderCode(partyCode + "-" + res.data.code);
                setNewOrderPotentialCodeIsLoading(false);
            },
            (error) => {
                console.log("Error in fetching New Order Code ", error);
                setSubmitAlertVariant("danger");
                setSubmitAlertMessage("");
                setNewOrderCode("Error in fetching New Order Code -> " + utils.GetErrorMessage(error));
                setNewOrderPotentialCodeIsLoading(false);
            }
        );
    };

    var component = utils.PageLoadComponents(pattaPartySelector["hasErrors"] ? "Error in loading Patta Party List." : "", pattaPartySelector["isLoading"]);
    if (component == null) {
        component = (
            <Can
                role={userDetails[constants.ROLE]}
                perform="bsil:patta:orders:order:new"
                no={() => <UnAuthorizedComponent />}
                yes={() => (
                    <Formik
                        validationSchema={schema}
                        initialValues={{
                            date: utils.GetTodayDate(),
                            party: "",
                            qty: "",
                            remark: "",
                        }}
                        onSubmit={submitForm}
                    >
                        {({ handleSubmit, handleChange, setFieldValue, setFieldTouched, values, touched, isSubmitting, errors }) => (
                            <Form
                                noValidate
                                //this key is to reset SelectDropdown on form submit
                                key={formResetKey}
                                onSubmit={handleSubmit}
                                onChange={(e) => {
                                    handleChange(e);
                                    setSubmitAlertMessage("");
                                }}
                            >
                                <h2 className="appNameHeaderTitle">
                                    ADD NEW ORDER
                                    <br />
                                    <br />
                                </h2>
                                <Form.Group as={Row} controlId="orderDate">
                                    <Form.Label column sm={3}>
                                        Date :
                                    </Form.Label>
                                    <Col sm={9}>
                                        <FormTextInput
                                            type="date"
                                            name="date"
                                            placeholder="Enter Date ..."
                                            values={values}
                                            handleChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="orderParty">
                                    <Form.Label column sm={3}>
                                        Party Name :
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Row>
                                            <Col sm={10}>
                                                <SelectDropdown
                                                    name="party"
                                                    onChange={setFieldValue}
                                                    onChangeCallback={fetchNewOrderCode}
                                                    onBlur={setFieldTouched}
                                                    placeholder="Enter Party Name ..."
                                                    options={FetchPattaPartyDropdown(pattaPartySelector["parties"])}
                                                    error={errors["party"]}
                                                    touched={touched["party"]}
                                                />
                                            </Col>
                                            <Col sm={1}>
                                                <Button size="sm" variant="primary" href="/bsil/patta/orders/party/new">
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </Button>
                                            </Col>
                                            <Col sm={1}></Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={3}>
                                        New Order Code :
                                    </Form.Label>
                                    <Col sm={9}>
                                        <div>{newOrderPotentialCodeIsLoading ? <BodySpinner /> : newOrderPotentialCode}</div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="orderQty">
                                    <Form.Label column sm={3}>
                                        Quantity (in kg) :
                                    </Form.Label>
                                    <Col sm={9}>
                                        <FormTextInput
                                            type="number"
                                            name="qty"
                                            placeholder="Enter Quantity ..."
                                            values={values}
                                            handleChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="orderRemark">
                                    <Form.Label column sm={3}>
                                        Remark :
                                    </Form.Label>
                                    <Col sm={9}>
                                        <FormTextInput
                                            type="text"
                                            name="remark"
                                            placeholder="Enter Remark ..."
                                            values={values}
                                            handleChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </Col>
                                </Form.Group>
                                <Container>
                                    <Row>
                                        <Col>
                                            <SubmitButton isSubmitting={isSubmitting} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>&nbsp;</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SubmitMessage submitMessage={submitAlertMessage} submitVariant={submitAlertVariant} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        )}
                    </Formik>
                )}
            />
        );
    }
    return (
        <Fragment>
            <div className="container align-self-center mt-5">
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={8}>{component}</Col>
                    <Col sm={2}></Col>
                </Row>
            </div>
        </Fragment>
    );
}
