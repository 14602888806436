import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";

// import "../../../public/gslUtensilApps.css";
import Can from "../../../components/can";
import * as utils from "../../../utils/utils";
import constants from "../../../constants/constants";
import LogOutButton from "../../../components/logOutButton";

export default function GSLUtensilApps() {
	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	var NavBarList = [
		{
			navbarPerform: "gsl:utensil:inventory:app:view",
			navbarHref: "/gsl/utensil/inventory",
			navbarTitle: "Utensil Production",
		},
		{
			navbarPerform: "gsl:utensil:order:app:view",
			navbarHref: "/gsl/utensil/orders",
			navbarTitle: "Utensils Orders",
		},
	];
	var ButtonsList = [
		{
			perform: "gsl:utensil:order:app:view",
			buttonVariant: "primary",
			buttonHref: "/gsl/utensil/orders",
			buttonMessage: <Fragment>Utensil&nbsp;Orders</Fragment>,
		},
		{
			perform: "gsl:utensil:inventory:app:view",
			buttonVariant: "secondary",
			buttonHref: "/gsl/utensil/inventory",
			buttonMessage: <Fragment>Utensil&nbsp;Production</Fragment>,
		},
	];

	return (
		<Fragment>
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
				<Navbar.Brand href="/">
					<img src="/static/gsl-logo.png" width="60" height="60" className="d-inline-block align-top" alt="GSL" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mr-auto">
						{NavBarList.map((node, index) => (
							<Can
								key={index}
								role={userDetails[constants.ROLE]}
								perform={node.navbarPerform}
								yes={() => (
									<Nav.Link className="navbarLinkSpan" href={node.navbarHref}>
										{node.navbarTitle}
									</Nav.Link>
								)}
							/>
						))}
					</Nav>
					<LogOutButton />
				</Navbar.Collapse>
			</Navbar>

			<Container className="gslUtensilApps">
				<div className="container align-self-center mt-5">
					<Row>
						<Col sm={3}></Col>
						<Col sm={6}>
							{ButtonsList.map((node, index) => (
								<Can
									key={index}
									role={userDetails[constants.ROLE]}
									perform={node.perform}
									yes={() => (
										<Container>
											<Row className="row-m-t">
												<Col>
													<AwesomeButton
														type={node.buttonVariant}
														href={node.buttonHref}
														style={{
															width: "100%",
															height: "400%",
															fontSize: "200%",
															fontFamily: "Comic Sans MS",
															"--button-primary-color": "#a9d3e9",
															"--button-primary-color-dark": "#58a9d4",
															"--button-primary-color-light": "#2e84b2",
															"--button-primary-color-hover": "#95c9e4",
															"--button-primary-border": "none",
															"--button-secondary-color": "#fafafa",
															"--button-secondary-color-dark": "#67cbc3",
															"--button-secondary-color-light": "#349890",
															"--button-secondary-color-hover": "#ecf9f8",
															"--button-secondary-border": "2px solid #b3e5e1",
														}}
													>
														{node.buttonMessage}
													</AwesomeButton>
												</Col>
											</Row>
											<br />
											<br />
											<br />
											<br />
											<br />
											<br />
										</Container>
									)}
								/>
							))}
						</Col>
						<Col sm={3}></Col>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
}
