const rules = {
    admin: {
        static: [
            "gsl:utensil:orders:party:navbar:view",
            "gsl:utensil:orders:order:navbar:view",
            "gsl:utensil:orders:lorry:navbar:view",

            "gsl:utensil:orders:party:new",
            "gsl:utensil:orders:party:view",

            "gsl:utensil:orders:order:new",
            "gsl:utensil:orders:order:pending:view",
            "gsl:utensil:orders:order:total-qty:view",
            "gsl:utensil:orders:order:individual:view",
            "gsl:utensil:orders:order:patta-requirement:view",

            "gsl:utensil:orders:lorry:new",
            "gsl:utensil:orders:lorry:dispatched:view",
            "gsl:utensil:orders:lorry:total-qty:view",
            "gsl:utensil:orders:lorry:individual:view",
            "gsl:utensil:orders:dispatch:cumulative:view",
        ],
    },
    director: {
        static: [
            "gsl:utensil:orders:party:navbar:view",
            "gsl:utensil:orders:order:navbar:view",
            "gsl:utensil:orders:lorry:navbar:view",

            "gsl:utensil:orders:party:view",

            "gsl:utensil:orders:order:pending:view",
            "gsl:utensil:orders:order:total-qty:view",
            "gsl:utensil:orders:order:individual:view",
            "gsl:utensil:orders:order:patta-requirement:view",

            "gsl:utensil:orders:lorry:dispatched:view",
            "gsl:utensil:orders:lorry:total-qty:view",
            "gsl:utensil:orders:lorry:individual:view",
            "gsl:utensil:orders:dispatch:cumulative:view",
        ],
    },
    off_orders: {
        static: [
            "gsl:utensil:orders:party:navbar:view",
            "gsl:utensil:orders:order:navbar:view",
            "gsl:utensil:orders:lorry:navbar:view",

            "gsl:utensil:orders:party:new",
            "gsl:utensil:orders:party:view",

            "gsl:utensil:orders:order:new",
            "gsl:utensil:orders:order:pending:view",
            "gsl:utensil:orders:order:individual:view",

            "gsl:utensil:orders:lorry:dispatched:view",
            "gsl:utensil:orders:lorry:individual:view",
        ],
    },
    gsl_staff: {
        static: [
            "gsl:utensil:orders:order:navbar:view",
            "gsl:utensil:orders:lorry:navbar:view",

            "gsl:utensil:orders:party:view",

            "gsl:utensil:orders:order:pending:view",
            "gsl:utensil:orders:order:total-qty:view",
            "gsl:utensil:orders:order:individual:view",
            "gsl:utensil:orders:order:patta-requirement:view",

            "gsl:utensil:orders:lorry:new",
            "gsl:utensil:orders:lorry:dispatched:view",
            "gsl:utensil:orders:lorry:total-qty:view",
            "gsl:utensil:orders:lorry:individual:view",
        ],
    },
    gsl_sales_viewer: {
        static: [
            "gsl:utensil:orders:party:navbar:view",
            "gsl:utensil:orders:order:navbar:view",
            "gsl:utensil:orders:lorry:navbar:view",
            "gsl:utensil:orders:party:view",
            "gsl:utensil:orders:order:pending:view",
            "gsl:utensil:orders:order:individual:view",
            "gsl:utensil:orders:lorry:dispatched:view",
            "gsl:utensil:orders:lorry:individual:view",
        ],
    },

    gsl_dispatch_supr: {
        static: [
            "gsl:utensil:orders:order:navbar:view",
            "gsl:utensil:orders:lorry:navbar:view",

            "gsl:utensil:orders:order:pending:view",
            "gsl:utensil:orders:order:individual:view",
            "gsl:utensil:orders:order:patta-requirement:view",

            "gsl:utensil:orders:lorry:dispatched:view",
            "gsl:utensil:orders:lorry:individual:view",
        ],
    },
};

export default rules;