import { Formik } from 'formik';
import { Redirect } from "react-router-dom";
import React, { Fragment, useState } from "react";
import { Col, Row, Form, Container, Button } from 'react-bootstrap';

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import AppConstants from '../constants';
import Can from '../../../../../components/can';
import yup from '../../../../../components/yup';
import constants from '../../../../../constants/constants';
import UnAuthorizedComponent from '../../../../../components/403';
import { SelectDropdown } from '../../../../../components/selectDropdown';
import { SubmitButton, SubmitMessage } from "../../../../../components/submitComponents";
import { FormTextInput } from "../../../../../components/inputFieldComponents";

var STATE_LIST_OPTIONS = [];
constants.INDIAN_STATES_LIST.forEach(function (element) {
    STATE_LIST_OPTIONS.push({ label: element, value: element })
});

export default function NewPattaPartyForm() {
    //this key is to reset SelectDropdown on form submit
    const [formResetKey, setFormResetKey] = useState("");
    const [submitAlertVariant, setSubmitAlertVariant] = useState("success");
    const [submitAlertMessage, setSubmitAlertMessage] = useState("");

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to='/login' />
    }

    const schema = yup.object({
        name: yup.string()
            .trim()
            .lowercase()
            .required("Party Name is Required."),
        station: yup.string()
            .trim()
            .lowercase()
            .required("Station is required."),
        state: yup.string()
            .trim()
            .lowercase()
            .required("State is required."),
        id: yup.string()
            .trim()
            .uppercase()
            .required("Party Code is required."),
        req: yup.string()
            .trim()
            .lowercase()
            .required("Patta Category is required."),
    })

    const submitForm = (values, { setSubmitting, resetForm }) => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        setSubmitting(true);
        var castValues = schema.cast(values)
        var transformedValues = { ...castValues }
        api.Post(
            `/api/v1/bsil/patta/party/`,
            transformedValues,
            userDetails[constants.JWT_TOKEN_KEY],
            (res) => {
                setSubmitAlertVariant("success");
                setSubmitAlertMessage(
                    <Fragment>
                        <strong>
                            Successfully Added New Party &apos;{res.data["name"]}&apos;.
                        </strong>
                        <br></br><br></br>
                        <Button variant="warning" href="/bsil/patta/orders/order/new">
                            Add New Order
                        </Button>
                    </Fragment>
                );

                setSubmitting(false);
                resetForm();
                setFormResetKey(Date.now());
            },
            (error) => {
                console.log("Error in processing Add New Party request ", error)
                setSubmitAlertVariant("danger");
                setSubmitAlertMessage("Error in Adding New Party -> " + utils.GetErrorMessage(error));
                setSubmitting(false);
            })
    }

    return (
        <Fragment>
            <div className="container align-self-center mt-5">
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={8}>
                        <Can
                            role={userDetails[constants.ROLE]}
                            perform="bsil:patta:orders:party:new"
                            no={() => (
                                <UnAuthorizedComponent />
                            )}
                            yes={() => (
                                <Formik
                                    validationSchema={schema}
                                    initialValues={{
                                        name: '',
                                        station: '',
                                        state: '',
                                        id: '',
                                        req: '',
                                    }}
                                    onSubmit={submitForm}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        setFieldValue,
                                        setFieldTouched,
                                        values,
                                        touched,
                                        isSubmitting,
                                        errors,
                                    }) => (
                                        <Form noValidate
                                            //this key is to reset SelectDropdown on form submit
                                            key={formResetKey}
                                            onSubmit={handleSubmit}
                                            onChange={e => { handleChange(e); setSubmitAlertMessage(""); }}
                                        >
                                            <h2 className="appNameHeaderTitle">ADD NEW PARTY<br /><br /></h2>
                                            <Form.Group as={Row} controlId="newPartyName" >
                                                <Form.Label column sm={2}>Party Name:</Form.Label>
                                                <Col sm={10}>
                                                    <FormTextInput
                                                        type="text"
                                                        name="name"
                                                        placeholder="Enter Party Name ..."
                                                        values={values}
                                                        handleChange={handleChange}
                                                        touched={touched}
                                                        errors={errors}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column sm={2}>Station:</Form.Label>
                                                <Col sm={3}>
                                                    <FormTextInput
                                                        type="text"
                                                        name="station"
                                                        placeholder="Enter Station ..."
                                                        values={values}
                                                        handleChange={handleChange}
                                                        touched={touched}
                                                        errors={errors}
                                                    />
                                                </Col>
                                                <Form.Label column sm={2}>State:</Form.Label>
                                                <Col sm={5}>
                                                    <SelectDropdown
                                                        name="state"
                                                        value={values["state"]["value"]}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        placeholder="Enter State ..."
                                                        options={STATE_LIST_OPTIONS}
                                                        error={errors["state"]}
                                                        touched={touched["state"]}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Form.Label column sm={2}>Category:</Form.Label>
                                                <Col sm={3}>
                                                    <SelectDropdown
                                                        name="req"
                                                        value={values["req"]["value"]}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        placeholder="Enter Category..."
                                                        options={AppConstants.BSIL_PATTA_PRODUCTS}
                                                        error={errors["req"]}
                                                        touched={touched["req"]}
                                                    />
                                                </Col>
                                                <Form.Label column sm={2}>Party Code:</Form.Label>
                                                <Col sm={5}>
                                                    <FormTextInput
                                                        type="text"
                                                        name="id"
                                                        placeholder="Enter Party Code ..."
                                                        values={values}
                                                        handleChange={handleChange}
                                                        touched={touched}
                                                        errors={errors}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <SubmitButton
                                                            isSubmitting={isSubmitting}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row><Col>&nbsp;</Col></Row>
                                                <Row>
                                                    <Col>
                                                        <SubmitMessage
                                                            submitMessage={submitAlertMessage}
                                                            submitVariant={submitAlertVariant}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Form>
                                    )
                                    }
                                </Formik>
                            )}
                        />
                    </Col>
                    <Col sm={2}></Col>
                </Row>
            </div>
        </Fragment>
    )
}