import axios from "axios";

const AXIOS_TIMEOUT = 10000;

export function Patch(url, values, token, onSuccess, onError) {
    let headers = {};
    if (token) {
        headers = {
            Authorization: "JWT ".concat(token),
            "Content-Type": "application/json",
        };
    }
    return axios
        .patch(url, values, {
            headers: headers,
            timeout: AXIOS_TIMEOUT,
        })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            console.log(`Got Error for POST on URL :${url} , Err : `, error.response);
            onError(error.response == null ? error : error.response);
        });
}

export function Post(url, values, token, onSuccess, onError) {
    let headers = {};
    if (token) {
        headers = {
            Authorization: "JWT ".concat(token),
            "Content-Type": "application/json",
        };
    }
    return axios
        .post(url, values, {
            headers: headers,
            timeout: AXIOS_TIMEOUT,
        })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            console.log(`Got Error for POST on URL :${url} , Err : `, error.response);
            onError(error.response == null ? error : error.response);
        });
}

export function Put(url, values, token, onSuccess, onError) {
    let headers = {};
    if (token) {
        headers = {
            Authorization: "JWT ".concat(token),
            "Content-Type": "application/json",
        };
    }
    return axios
        .put(url, values, {
            headers: headers,
            timeout: AXIOS_TIMEOUT,
        })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            console.log(`Got Error for POST on URL :${url} , Err : `, error.response);
            onError(error.response == null ? error : error.response);
        });
}

export function Get(url, params, token, onSuccess, onError) {
    let config = {};
    if (token) {
        config = {
            headers: {
                Authorization: "JWT ".concat(token),
                "Content-Type": "application/json",
            },
            params: params,
        };
    }
    return axios
        .get(url, config, {
            timeout: AXIOS_TIMEOUT,
        })
        .then((response) => {
            onSuccess(response);
        })
        .catch((error) => {
            console.log(`Got Error for GET on URL :${url} , Err : `, error);
            onError(error.response == null ? error : error.response);
        });
}
