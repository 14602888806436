import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import React, { Fragment } from "react";

// FormTextInputFieldArray is for a text input box when
// using fieldarray we give option of add new row and delete existing row
export function FormTextInputFieldArray(props) {
    return (
        <Fragment>
            <Form.Control
                size="sm"
                type={props.type}
                value={props.value}
                name={`${props.fieldArrayName}.${props.index}.${props.fieldInputName}`}
                onChange={props.handleChange}
                placeholder={props.placeholder}
            />
            {props.touched &&
                props.touched[props.fieldArrayName] &&
                props.touched[props.fieldArrayName][props.index] &&
                props.touched[props.fieldArrayName][props.index][props.fieldInputName] &&
                props.errors &&
                props.errors[props.fieldArrayName] &&
                props.errors[props.fieldArrayName][props.index] &&
                props.errors[props.fieldArrayName][props.index][props.fieldInputName] && (
                    <div className="field-error">{props.errors[props.fieldArrayName][props.index][props.fieldInputName]}</div>
                )}
        </Fragment>
    );
}

FormTextInputFieldArray.propTypes = {
    type: PropTypes.string.isRequired,
    fieldArrayName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.string,
    fieldInputName: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export function FormTextInput(props) {
    return (
        <Fragment>
            <Form.Control
                size="sm"
                type={props.type}
                name={props.name}
                value={props.values[props.name]}
                onChange={props.handleChange}
                placeholder={props.placeholder}
                isValid={props.touched[props.name] && !props.errors[props.name]}
                isInvalid={props.touched[props.name] && !!props.errors[props.name]}
            />
            <Form.Control.Feedback type="invalid">{props.errors[props.name]}</Form.Control.Feedback>
        </Fragment>
    );
}

FormTextInput.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export class FormCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.initialValue,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.setState({ value: !this.state.value });
        if (this.props.onChange) {
            this.props.onChange(this.props.name, !this.state.value);
        }
        if (this.props.onChangeCallback) {
            this.props.onChangeCallback(!this.state.value, this.props.name);
        }
    }
    render() {
        return (
            <Form.Group controlId={this.props.name}>
                <Form.Label className="inputFormLabel">{this.props.label}</Form.Label>
                <input
                    ref={this.props.innerRef}
                    className={this.props.className}
                    defaultChecked={this.state.value}
                    name={this.props.name}
                    onChange={this.handleChange}
                    type="checkbox"
                />
            </Form.Group>
        );
    }
}

export const FormCheckboxRef = React.forwardRef((props, ref) => <FormCheckbox innerRef={ref} {...props} />);

FormCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    initialValue: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onChangeCallback: PropTypes.func,
    innerRef: PropTypes.object,
};
