const rules = {
    //rules to view app buttons and navbar items for BSIL apps
    admin: {
        static: ["gsl:utensil:inventory:app:view", "gsl:utensil:order:app:view"],
    },
    director: {
        static: ["gsl:utensil:inventory:app:view", "gsl:utensil:order:app:view"],
    },
    off_orders: {
        static: ["gsl:utensil:order:app:view"],
    },
    gsl_staff: {
        static: ["gsl:utensil:inventory:app:view", "gsl:utensil:order:app:view"],
    },
    gsl_sales_viewer: {
        static: ["gsl:utensil:order:app:view"],
    },
    gsl_dispatch_supr: {
        static: ["gsl:utensil:inventory:app:view", "gsl:utensil:order:app:view"],
    },
};

export default rules;