import React from "react";
import jwt_decode from "jwt-decode";
import { Alert } from "react-bootstrap";

import * as api from "./api";
import { BodySpinner } from "../components/spinner";
import constants from "../constants/constants";

export function GetErrorMessage(errorObj) {
	if (errorObj == null) {
		return "Error loading page !!";
	}
	if (typeof errorObj === "string" || errorObj instanceof String) {
		return errorObj;
	}
	if (typeof errorObj.message === "string" || errorObj.message instanceof String) {
		return errorObj.message;
	}
	if (typeof errorObj.data === "string" || errorObj.data instanceof String) {
		return errorObj.data;
	}
	let errorMessage = "";
	for (var key in errorObj.data) {
		errorMessage = errorMessage + key + " : " + errorObj.data[key];
	}
	return errorMessage;
}

export function PageLoadComponents(loadErrorMessage, isLoading) {
	// Since state initialization takes some time, if isLoading is undefined, show spinner
	if (isLoading == null || isLoading == true) {
		return <BodySpinner />;
	} else if (loadErrorMessage.length > 0) {
		return <Alert variant="danger">{loadErrorMessage}</Alert>;
	}

	return null;
}

// this function checks if loading error array has error=true val
export function checkPageLoadErrorArray(loadingErrorDetails) {
	// iterating and checking if any dropdown function returned error
	let loadingError = false;
	let pageLoadErrorMessage = "";
	loadingErrorDetails.forEach(function (obj) {
		if (!loadingError && obj["hadLoadingError"]) {
			// only run if condition once on any single error
			// so that error=true does not override error=false
			loadingError = true;
			pageLoadErrorMessage = obj["errorMessage"];
		}
	});
	return [loadingError, pageLoadErrorMessage];
}

export function FetchDropdownList(URL, userToken, getParam, defaultVal, setFunction, setLoadingErrorDetails) {
	// When button submits form and form is in the process of submitting, submit button is disabled
	return api.Get(
		URL,
		getParam,
		userToken,
		(res) => {
			if (!("data" in res) || res.data == null) {
				throw new Error("Could not retrieve dropdown details.");
			} else {
				setFunction(res.data);
			}
			if (!(Object.prototype.toString.call(res.data) === "[object Object]" || Object.prototype.toString.call(res.data) === "[object Array]")) {
				throw new Error("Retrieved dropdown is neither list not dictionary.");
			}
			console.log(`Fetched Dropdown list '${URL}' : `, res.data);
		},
		(error) => {
			console.log("Error in fetching Dropdown List", error);
			setFunction(defaultVal);
			setLoadingErrorDetails((err) => [
				...err,
				{
					hadLoadingError: true,
					errorMessage: GetErrorMessage(error),
				},
			]);
		}
	);
}

export function DisplayDecimal2Places(val) {
	if (val == null || val == undefined) {
		return "";
	}
	return Math.round(val * 100) / 100;
}

export function ConvertKgToMt(weightInKG) {
	let weightInMT = weightInKG / 1000.0;
	return Math.round(weightInMT * 100) / 100;
}

// Convert "2010-01-18" to "18/01/2010"
export function FormatDisplayDate(input) {
	if (input == null) {
		return "";
	}
	let dArr = input.split("-");
	return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex out: "18/01/10"
}

export function GetCurrentMonthYear() {
	let d = new Date();
	return constants.MONTH_NAMES[d.getMonth()] + " " + d.getFullYear();
}

export function LogOutUser() {
	localStorage.removeItem(constants.JWT_TOKEN_KEY);
	localStorage.removeItem(constants.USERNAME);
	localStorage.removeItem(constants.ROLE);
}

function assertJWTAlive(decoded) {
	const now = Date.now().valueOf() / 1000;
	if (typeof decoded.exp !== "undefined" && decoded.exp < now) {
		throw new Error(`token expired: ${JSON.stringify(decoded)}`);
	}
	if (typeof decoded.nbf !== "undefined" && decoded.nbf > now) {
		throw new Error(`token not yet valid: ${JSON.stringify(decoded)}`);
	}
}

// return date in yyyy-mm-dd in string form
export function GetTodayDate() {
	return ""; //returning empty date for now
	// return new Date().toJSON().slice(0, 10);
}

// Check if user is logged in. Return user details from localstorage.
export function GetUserDetails() {
	let token = localStorage.getItem(constants.JWT_TOKEN_KEY);
	let username = localStorage.getItem(constants.USERNAME);
	let role = localStorage.getItem(constants.ROLE);

	//verifying if the token is valid
	try {
		assertJWTAlive(jwt_decode(token));
	} catch (err) {
		return null;
	}

	if (token && username && role) {
		return {
			token: token,
			username: username,
			role: role,
		};
	}

	//User details missing in localstorage. Log out user
	localStorage.removeItem(constants.JWT_TOKEN_KEY);
	localStorage.removeItem(constants.USERNAME);
	localStorage.removeItem(constants.ROLE);
	return null;
}

export function ToTitleCase(str) {
	if (str == null || str == undefined) {
		return "";
	}
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

// Function to convert list of values into react-select list of {key:"<val>", value:"<val>"}
export function FetchReactSelectDropdownFromList(list) {
	var res = [];
	list.forEach(function (val) {
		res.push({ label: val, value: val });
	});
	return res;
}
