import { createSlice } from "@reduxjs/toolkit";

export const loginFormSlice = createSlice({
    name: "user",
    initialState: {
        username: '',
        token: '',
        groups: [],
    },
    reducers: {
        loginUser: (state, { payload }) => {
            state.username = payload.username;
            state.token = payload.token
            state.role = payload.role
        },
    },
});

export const {
    loginUser,
} = loginFormSlice.actions;

export const LoggedinUserSelector = (state) => state.user;

export default loginFormSlice.reducer;