const rules = {
    admin: {
        static: ["bsil:apps:view", "gsl-utn:apps:view"],
    },
    director: {
        static: ["bsil:apps:view", "gsl-utn:apps:view"],
    },
    bsil_viewer: {
        static: ["bsil:apps:view"],
    },
    performance_reviewer: {
        static: ["bsil:apps:view"],
    },
    off_orders: {
        static: ["bsil:apps:view", "gsl-utn:apps:view"],
    },
    bsil_staff: {
        static: ["bsil:apps:view"],
    },
    gsl_staff: {
        static: ["bsil:apps:view", "gsl-utn:apps:view"],
    },
    gsl_sales_viewer: {
        static: ["gsl-utn:apps:view"],
    },
    gsl_dispatch_supr: {
        static: ["gsl-utn:apps:view"],
    },
};

export default rules;