import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Form, Container, Table } from "react-bootstrap";

import * as utils from "../../../../../utils/utils";
import * as api from "../../../../../utils/api";

import "../../../../../public/custom.css";
import * as inventoryUtils from "../utils";
import Can from "../../../../../components/can";
import constants from "../../../../../constants/constants";
import UnAuthorizedComponent from "../../../../../components/403";
import { SelectDropdown } from "../../../../../components/selectDropdown";

function FetchPartyDetails(userToken, party, setPartyDetails, setIsPageLoading, setLoadingErrorDetails) {
    // When button submits form and form is in the process of submitting, submit button is disabled
    return api.Get(
        `/api/v1/gsl/utensil/orders/party/${party}/`,
        {},
        userToken,
        (res) => {
            console.log("Fetched Party Details : ", res);
            "data" in res && setPartyDetails(res.data);
            setIsPageLoading(false);
        },
        (error) => {
            console.log("Error in fetching party Details : ", error);
            setIsPageLoading(false);
            setLoadingErrorDetails((err) => [
                ...err,
                {
                    hadLoadingError: true,
                    errorMessage: utils.GetErrorMessage(error),
                },
            ]);
        }
    );
}

export default function ViewUtensilPartyDetails() {
    //this key is to reset SelectDropdown on form submit
    const [partyNameInput, setPartyNameInput] = useState("-");
    const [partyDetails, setPartyDetails] = useState({});
    const [utnPartyDropdown, setUtnPartyDropdown] = useState([]);

    const [isPageLoading, setIsPageLoading] = useState(true);
    const [loadingErrorDetails, setLoadingErrorDetails] = useState([]);

    //fetching the list of utensil variant dropdown
    useEffect(() => {
        const partyList = utils.FetchDropdownList(
            "/api/v1/gsl/utensil/orders/party/dropdown",
            userDetails[constants.JWT_TOKEN_KEY],
            {},
            [],
            setUtnPartyDropdown,
            setLoadingErrorDetails
        );
        Promise.all([partyList]).then(() => {
            setIsPageLoading(false);
        });
    }, []);

    var userDetails = utils.GetUserDetails();
    if (!userDetails) {
        return <Redirect to="/login" />;
    }

    const fetchParty = (party) => {
        setIsPageLoading(true);
        FetchPartyDetails(userDetails[constants.JWT_TOKEN_KEY], party, setPartyDetails, setIsPageLoading, setLoadingErrorDetails);
    };

    const [hasLoadingError, pageLoadErrorMessage] = utils.checkPageLoadErrorArray(loadingErrorDetails);

    var component = utils.PageLoadComponents(hasLoadingError ? "Error in fetching dropdown list -> " + pageLoadErrorMessage : "", isPageLoading);
    if (component == null) {
        console.log("if condition : ", loadingErrorDetails);
        component = (
            <Can
                role={userDetails[constants.ROLE]}
                perform="gsl:utensil:orders:party:view"
                no={() => <UnAuthorizedComponent />}
                yes={() => (
                    <Container>
                        <h2 className="appNameHeaderTitle">
                            VIEW PARTY DETAILS
                            <br />
                            <br />
                        </h2>

                        <Formik
                            initialValues={{
                                party: "",
                            }}
                        >
                            {({ setFieldValue, handleChange, setFieldTouched, touched, errors }) => (
                                <Form
                                    noValidate
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                >
                                    <Form.Group as={Row} controlId="partyName">
                                        <Col sm={2}></Col>
                                        <Col sm={8}>
                                            <Row>
                                                <Col sm={4}>
                                                    <Form.Label className="inputFormLabel">Search Party :</Form.Label>
                                                </Col>
                                                <Col sm={8}>
                                                    <SelectDropdown
                                                        name="party"
                                                        value={partyNameInput}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        placeholder="Party Name..."
                                                        options={inventoryUtils.FetchPartyNameDropdown(utnPartyDropdown, false)}
                                                        onChangeCallback={(party) => {
                                                            setPartyNameInput(party);
                                                            fetchParty(party);
                                                        }}
                                                        error={errors["party"]}
                                                        touched={touched["party"]}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                        <Table bordered size="sm" variant="link">
                            <tbody>
                                <tr>
                                    <th
                                        style={{
                                            width: "10%",
                                        }}
                                    >
                                        Name
                                    </th>
                                    <th
                                        style={{
                                            width: "20%",
                                            fontWeight: "normal",
                                        }}
                                    >
                                        {utils.ToTitleCase(partyDetails?.name)}
                                    </th>
                                </tr>

                                <tr>
                                    <th>Place</th>
                                    <th
                                        style={{
                                            fontWeight: "normal",
                                        }}
                                    >
                                        {utils.ToTitleCase(partyDetails?.place)}
                                    </th>
                                </tr>
                                <tr>
                                    <th>State</th>
                                    <th
                                        style={{
                                            fontWeight: "normal",
                                        }}
                                    >
                                        {partyDetails?.state}
                                    </th>
                                </tr>
                                <tr>
                                    <th>District</th>
                                    <th
                                        style={{
                                            fontWeight: "normal",
                                        }}
                                    >
                                        {partyDetails?.district}
                                    </th>
                                </tr>
                                <tr>
                                    <th>Through</th>
                                    <th
                                        style={{
                                            fontWeight: "normal",
                                        }}
                                    >
                                        {partyDetails?.through_display}
                                    </th>
                                </tr>
                                <tr>
                                    <th>Contact Person</th>
                                    <th
                                        style={{
                                            fontWeight: "normal",
                                        }}
                                    >
                                        {utils.ToTitleCase(partyDetails?.contact_person)}
                                    </th>
                                </tr>
                                <tr>
                                    <th>Phone No.</th>
                                    <th
                                        style={{
                                            fontWeight: "normal",
                                        }}
                                    >
                                        {partyDetails?.contact_number}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                    </Container>
                )}
            />
        );
    }
    return (
        <Fragment>
            <div className="container align-self-center mt-5">
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={10}>{component}</Col>
                    <Col sm={1}></Col>
                </Row>
            </div>
        </Fragment>
    );
}
