import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import React, { useState, Fragment } from "react";
import { Form, Row, Col, Container } from 'react-bootstrap'

import '../../public/custom.css';
import yup from '../../components/yup';
import * as api from "../../utils/api";
import * as utils from "../../utils/utils";
import constants from '../../constants/constants';
import { FormTextInput } from "../../components/inputFieldComponents";
import { SubmitButton, SubmitMessage } from "../../components/submitComponents";

import {
    loginUser,
} from "./userSlice";

export default function LoginForm() {
    const [submitAlertVariant, setSubmitAlertVariant] = useState("success");
    const [submitAlertMessage, setSubmitAlertMessage] = useState("");
    const dispatch = useDispatch()

    const schema = yup.object({
        username: yup.string()
            .trim()
            .lowercase()
            .required("Email is Required.")
            .email("Please enter valid Email ID."),
        password: yup.string()
            .trim()
            .required("Password is Required."),
    })

    const submitForm = (values, { setSubmitting, resetForm }) => {
        // When button submits form and form is in the process of submitting, submit button is disabled
        setSubmitting(true);
        var castValues = schema.cast(values)
        var transformedValues = { ...castValues }
        api.Post(
            `/token-auth/`,
            transformedValues,
            null,
            (res) => {
                //only single group per user allowed
                if (res.data.user["groups"].length != 1) {
                    throw new Error("Group Contains more than one role");
                }
                if (!res.data.user["groups"][0]["name"]) {
                    throw new Error("User role is missing");
                }

                let reducerData = {
                    username: res.data.user["username"],
                    token: res.data["token"],
                    role: res.data.user["groups"][0]["name"]
                }
                localStorage.setItem(constants.JWT_TOKEN_KEY, reducerData["token"]);
                localStorage.setItem(constants.USERNAME, reducerData["username"]);
                localStorage.setItem(constants.ROLE, reducerData["role"]);
                dispatch(loginUser(reducerData));
                setSubmitAlertVariant("success");
                setSubmitAlertMessage(`Successfully Logged In.`);
                setSubmitting(false);
                resetForm();
                console.log("Logging In User : ", reducerData);
                window.location.replace("/");
            },
            (error) => {
                console.log("Error in processing login form request ", error.message);
                setSubmitAlertVariant("danger");
                setSubmitAlertMessage(utils.GetErrorMessage(error.message));
                setSubmitting(false);
            })
    }
    return (
        <Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-xl-9 mx-auto">
                        <div className="card card-signin flex-row my-5">
                            <div className="card-img-left d-none d-md-flex"></div>
                            <div className="card-body">
                                <h5 className="card-title text-center">Sign In</h5>
                                <Formik
                                    validationSchema={schema}
                                    initialValues={{
                                        username: '',
                                        password: '',
                                    }}
                                    onSubmit={submitForm}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        touched,
                                        isSubmitting,
                                        errors,
                                    }) => (
                                        <Form noValidate
                                            onSubmit={handleSubmit}
                                            onChange={e => { handleChange(e); setSubmitAlertMessage(""); }}
                                        >
                                            <Form.Group as={Row} controlId="loginFormUsername" >
                                                <Form.Label column sm={4}>Email:</Form.Label>
                                                <Col sm={8}>
                                                    <FormTextInput
                                                        type="text"
                                                        name="username"
                                                        placeholder="Enter Login Id ..."
                                                        values={values}
                                                        handleChange={handleChange}
                                                        touched={touched}
                                                        errors={errors}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="loginFormPassword" >
                                                <Form.Label column sm={4}>Password:</Form.Label>
                                                <Col sm={8}>
                                                    <FormTextInput
                                                        type="password"
                                                        name="password"
                                                        placeholder="Enter Password ..."
                                                        values={values}
                                                        handleChange={handleChange}
                                                        touched={touched}
                                                        errors={errors}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <SubmitButton
                                                            isSubmitting={isSubmitting}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row><Col>&nbsp;</Col></Row>
                                                <Row>
                                                    <Col>
                                                        <SubmitMessage
                                                            submitMessage={submitAlertMessage}
                                                            submitVariant={submitAlertVariant}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Form>
                                    )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}