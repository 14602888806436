import * as yup from 'yup';

yup.addMethod(yup.string, 'isElementOf', function (list, key, message) {
    return this.test("checkElementOf", message, function (value) {
        if (value) {
            value = value.trim().toLowerCase()
            var x = list.find(elem => {
                return elem[key] === value;
            })
            return x !== undefined;
        }
        return false
    });
});

yup.addMethod(yup.string, 'isNotElementOf', function (list, key, message) {
    return this.test("checkElementNotOf", message, function (value) {
        if (value) {
            const castValue = value.trim().toLowerCase()
            var x = list.find(elem => {
                return elem[key] === castValue;
            })
            return x === undefined;
        }
        return true
    });
});

export default yup;