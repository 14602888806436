import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Form, Alert, Table } from "react-bootstrap";

import * as api from "../../../../../utils/api";
import * as utils from "../../../../../utils/utils";

import * as inventoryUtils from "../utils";
import "../../../../../public/custom.css";
import Can from "../../../../../components/can";
import UnAuthorizedComponent from "../../../../../components/403";
import constants from "../../../../../constants/constants";
import { SubmitMessage } from "../../../../../components/submitComponents";
import { SelectDropdown } from "../../../../../components/selectDropdown";

function DisplayCurrentStock(currentStock) {
	let utnData = {};
	let utnName = [];
	for (var i in currentStock) {
		let obj = currentStock[i];
		if (obj.utensil_display in utnData) {
			utnData[obj.utensil_display].push(obj);
		} else {
			utnName.push(obj.utensil_display);
			utnData[obj.utensil_display] = [obj];
		}
	}

	var rows = [];

	// function creates individual tables for different utensils
	const displayIndividualEntityStock = (utnName, utnMap) => {
		console.log("creating table for ", utnName);
		let kalwat_pcs_total = 0,
			kalwat_kg_total = 0,
			polish_pcs_total = 0,
			polish_kg_total = 0;
		return (
			<Table responsive bordered size="sm" variant="link">
				<thead>
					<tr
						className="stockNameTitle"
						style={{
							color: "#5e001f",
							background: "#00e1d9",
						}}
					>
						<th colSpan={6}>{utnName.toUpperCase()}</th>
					</tr>
					<tr>
						<th>#</th>
						<th>Size</th>
						<th colSpan={2}>Kalwat</th>
						<th colSpan={2}>Polish</th>
					</tr>
					<tr>
						<th></th>
						<th></th>
						<th>pcs</th>
						<th>kg</th>
						<th>pcs</th>
						<th>kg</th>
					</tr>
				</thead>
				<tbody>
					{utnMap[utnName].map((obj, index) => {
						kalwat_pcs_total += obj.kalwat[0];
						kalwat_kg_total += obj.kalwat[1];
						polish_pcs_total += obj.polish[0];
						polish_kg_total += obj.polish[1];
						return (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>{obj.size}</td>
								{/* <td>{obj.brand.toUpperCase()}</td> */}
								{/* <td>{obj.uom}</td> */}
								<td>{utils.DisplayDecimal2Places(obj.kalwat[0])}</td>
								<td>{utils.DisplayDecimal2Places(obj.kalwat[1])}</td>
								<td>{utils.DisplayDecimal2Places(obj.polish[0])}</td>
								<td>{utils.DisplayDecimal2Places(obj.polish[1])}</td>
							</tr>
						);
					})}
					<tr>
						<th></th>
						<th>Total</th>
						<th>{utils.DisplayDecimal2Places(kalwat_pcs_total)} pcs</th>
						<th>{utils.DisplayDecimal2Places(utils.ConvertKgToMt(kalwat_kg_total))} MT</th>
						<th>{utils.DisplayDecimal2Places(polish_pcs_total)} pcs</th>
						<th>{utils.DisplayDecimal2Places(utils.ConvertKgToMt(polish_kg_total))} MT</th>
					</tr>
				</tbody>
			</Table>
		);
	};

	for (let i = 0; i < utnName.length; i += 2) {
		rows.push(
			<Row>
				<Col sm={6}>{displayIndividualEntityStock(utnName[i], utnData)}</Col>
				<Col sm={6}>
					{/* Not displaying second column if number of elements is odd */}
					{i + 1 < utnName.length ? displayIndividualEntityStock(utnName[i + 1], utnData) : ""}
				</Col>
			</Row>
		);
	}
	return <Container>{rows}</Container>;
}

function FetchCurrentStockList(group, userToken, setCurrentStockList, setIsUtnStockLoading, setSubmitAlertVariant, setSubmitAlertMessage) {
	setIsUtnStockLoading(true);
	console.log("fetching current stock data for group : ", group);
	// When button submits form and form is in the process of submitting, submit button is disabled
	return api.Get(
		`/api/v1/gsl/utensil/inventory/utensil/stock/${group}`,
		{},
		userToken,
		(res) => {
			console.log(`received current stock for group ${group} : `, res);
			if ("data" in res) {
				setCurrentStockList(res.data);
				setIsUtnStockLoading(false);
				setSubmitAlertMessage("");
			} else {
				throw new Error("Received Invalid Data from server");
			}
		},
		(error) => {
			console.log("Error in fetching Utensil Current Stock List", error);
			setSubmitAlertVariant("danger");
			setCurrentStockList([]);
			setSubmitAlertMessage(utils.GetErrorMessage(error));
			setIsUtnStockLoading(false);
		}
	);
}

export default function ViewCurrentUtensilStock() {
	//this key is to reset SelectDropdown on form submit

	const [submitAlertVariant, setSubmitAlertVariant] = useState("success");
	const [submitAlertMessage, setSubmitAlertMessage] = useState("");
	const [currentStockList, setCurrentStockList] = useState([]);

	const [selectedGroup, setSelectedGroup] = useState("pawali");
	const [isUtnStockLoading, setIsUtnStockLoading] = useState(true);
	const [isPageLoading, setIsPageLoading] = useState(true);
	const [loadingErrorDetails, setLoadingErrorDetails] = useState([]);
	const [utnGroupVariantDropdown, setUtnGroupVariantDropdown] = useState({});

	useEffect(() => {
		let curStock = FetchCurrentStockList(
			"pawali",
			userDetails[constants.JWT_TOKEN_KEY],
			setCurrentStockList,
			setIsUtnStockLoading,
			setSubmitAlertVariant,
			setSubmitAlertMessage
		);
		let groupList = utils.FetchDropdownList(
			"/api/v1/gsl/utensil/inventory/utensil/groups/dropdown",
			userDetails[constants.JWT_TOKEN_KEY],
			{},
			{},
			setUtnGroupVariantDropdown,
			setLoadingErrorDetails
		);
		Promise.all([curStock, groupList]).then(() => {
			setIsPageLoading(false);
		});
	}, []);

	var userDetails = utils.GetUserDetails();
	if (!userDetails) {
		return <Redirect to="/login" />;
	}

	const fetchUtnStock = (selectedOption) => {
		console.log(`fetching stock for `, selectedOption);
		setSelectedGroup(selectedOption);
		FetchCurrentStockList(
			selectedOption,
			userDetails[constants.JWT_TOKEN_KEY],
			setCurrentStockList,
			setIsUtnStockLoading,
			setSubmitAlertVariant,
			setSubmitAlertMessage
		);
	};

	const [hasLoadingError, pageLoadErrorMessage] = utils.checkPageLoadErrorArray(loadingErrorDetails);

	var component = utils.PageLoadComponents(
		hasLoadingError ? "Error in fetching utensil group list -> " + pageLoadErrorMessage : "",
		isPageLoading || isUtnStockLoading
	);
	if (component == null) {
		component = (
			<Can
				role={userDetails[constants.ROLE]}
				perform="gsl:utensil:inventory:stock:utensil:view"
				no={() => <UnAuthorizedComponent />}
				yes={() => (
					<Container>
						<h2 className="appNameHeaderTitle">
							VIEW UTENSIL STOCK
							<br />
							<br />
						</h2>
						<Formik
							initialValues={{
								group: "",
							}}
						>
							{({ setFieldValue, setFieldTouched, touched, errors }) => (
								<Form>
									<Form.Group as={Row} controlId="utnStock">
										<Col sm={3}></Col>
										<Col sm={6}>
											<Row>
												<Col sm={3}>
													<Form.Label className="inputFormLabel">Group&nbsp;:</Form.Label>
												</Col>
												<Col sm={9}>
													<SelectDropdown
														name="group"
														value={selectedGroup}
														onChange={setFieldValue}
														onBlur={setFieldTouched}
														onChangeCallback={fetchUtnStock}
														placeholder="Enter Group ..."
														options={inventoryUtils.FetchUtensilGroupDropdown(utnGroupVariantDropdown)}
														error={errors["group"]}
														touched={touched["group"]}
													/>
												</Col>
											</Row>
										</Col>
										<Col sm={3}></Col>
									</Form.Group>
									<Container>
										<Row>
											<Col>
												<SubmitMessage submitMessage={submitAlertMessage} submitVariant={submitAlertVariant} />
											</Col>
										</Row>
									</Container>
								</Form>
							)}
						</Formik>
						{currentStockList.length > 0 ? (
							DisplayCurrentStock(currentStockList)
						) : (
							<Alert variant="success">No Utensils found in Group</Alert>
						)}
					</Container>
				)}
			/>
		);
	}
	return (
		<Fragment>
			<div className="container align-self-center mt-5">
				<Row>
					<Col sm={1}></Col>
					<Col sm={10}>{component}</Col>
					<Col sm={1}></Col>
				</Row>
			</div>
			<br />
			<br />
			<br />
			<br />
		</Fragment>
	);
}
